// import { HornyNutakuSDK } from 'hornynutakusdk';
import { useEffect, useState } from 'react';
import { Connections } from 'src/components/Connections/Connections';
import { CharacterCarousel } from 'src/components/Discover/CharacterCarousel/CharacterCarousel';
import { HeaderContainer } from 'src/components/Layout/Header/Header';
import { UserNameRequest } from 'src/components/Layout/UserNameRequest/UserNameRequest';
import { Top } from 'src/components/Top/Top';
import { useModalContext } from 'src/context/Modal.Context';
// import { useNutakuContext } from 'src/context/Nutaku.Context';
import { Invite } from 'src/components/Invite/Invite';
import { useUserContext } from 'src/context/User.context';
import { PageControls } from './PageControls/PageControls';
import { Proposal, useProposalType } from './Proposal';
import './index.css';

export enum TabToShow {
  Discover = 'discover',
  Connections = 'connections',
  Top = 'top',
  Invite = 'invite',
}

export type MainProps = {
  tabToShow: TabToShow;
};

export function Main({ tabToShow = TabToShow.Discover }: MainProps) {
  const [selectedTab, setSelectedTab] = useState<TabToShow>(() => {
    return tabToShow;
  });
  const { user } = useUserContext();
  const { modals } = useModalContext();

  const proposalToShow = useProposalType();

  const [showProposal, setShowProposal] = useState(false);

  useEffect(() => {
    setShowProposal(proposalToShow !== undefined);
  }, [proposalToShow]);

  const heightExtract = showProposal ? 140 : 80;

  const onProposalClose = () => {
    setShowProposal(false);
  };

  console.log('proposalToShow: ', proposalToShow);

  // const { addModal } = useContext(ModalContext);

  // useEffect(() => {
  //   addModal({
  //     children: <UpdateAppOverlay />,
  //     isNoHeader: true,
  //   });
  // }, []);

  return (
    <div className="position-relative m-auto h-100">
      {user && !user!.Nickname && <UserNameRequest />}
      <HeaderContainer absolute={true} />
      <div className="main_container h-100">
        <div
          className="d-flex"
          style={{ height: `calc(100% - ${heightExtract}px)` }}
        >
          <div
            className={`${selectedTab === TabToShow.Discover ? 'd-block h-100 w-100' : 'opacity-0 h-100 w-0 pe-none position-absolute  '}`}
          >
            <CharacterCarousel
              hidden={selectedTab !== TabToShow.Discover || !!modals.length}
              active={selectedTab === TabToShow.Discover}
            />
          </div>
          {selectedTab === TabToShow.Connections && (
            <div
              className={`${selectedTab === TabToShow.Connections ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Connections active={selectedTab === TabToShow.Connections} />
            </div>
          )}
          {selectedTab === TabToShow.Top && (
            <div
              className={`${selectedTab === TabToShow.Top ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Top active={selectedTab === TabToShow.Top} />
            </div>
          )}
          {selectedTab === TabToShow.Invite && (
            <div
              className={`${selectedTab === TabToShow.Invite ? 'd-block h-100 w-100' : 'd-none'}`}
            >
              <Invite active={selectedTab === TabToShow.Invite} />
            </div>
          )}
        </div>
        {showProposal && proposalToShow && (
          <Proposal proposalType={proposalToShow} onClose={onProposalClose} />
        )}
        <PageControls
          selectedTab={selectedTab}
          onTabSelect={(tabToShow) => setSelectedTab(tabToShow)}
        />
      </div>
    </div>
  );
}
