import { registerPlugin } from '@capacitor/core';

import type { HornyNutakuSDKPlugin } from './definitions';

const HornyNutakuSDK = registerPlugin<HornyNutakuSDKPlugin>('HornyNutakuSDK', {
  web: () => import('./web').then(m => new m.HornyNutakuSDKWeb()),
});

export * from './definitions';
export { HornyNutakuSDK };
