import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationPersonality,
  CharacterGenerationStage,
  CharacterGenerationStory,
  ExampleMessage,
} from 'src/services/API/CharacterAPI';
import { OnChoiceCallback } from '../CharacterGeneration';
import { IntroBlock } from '../Shared/IntroBlock';
import { ValueSwitcher } from '../Shared/ValueSwitcher/ValueSwitcher';
import { checkState } from '../Shared/helpers';
import { CreateMessages } from './CreateMessages/CreateMessages';
import { CreateStory } from './CreateStory/CreateStory';
const randomDescription =
  'Mw-100 text-center mx-auto horny-bg-gradient_red p-5';
const fillWhoAreYou = 'Please fill in your role in "Who are you block"';
export type ConfigureStoryProps = {
  onChoice: OnChoiceCallback;
  personality: CharacterGenerationPersonality;
  onBack: () => any;
  onForward?: () => any;
  fetched: CharacterGenerationStory | null;
};

export function ConfigureStory({
  personality,
  onChoice,
  onBack,
  onForward,
  fetched,
}: ConfigureStoryProps) {
  const { t } = useTranslation();
  const [userRolesFetching, setUserRolesFetching] = useState(false);
  const [placesFetching, setPlacesFetching] = useState(false);

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [userRole, setUserRole] = useState(fetched?.UserRole || '');
  const [places, setPlaces] = useState<string[]>([]);
  const [place, setPlace] = useState<string>(fetched?.Place.Title || '');
  const [story, setStory] = useState<string>(fetched?.Story || '');
  const [messages, setMessages] = useState<ExampleMessage[] | null>(
    fetched?.Messages || null
  );
  const [updating, setUpdating] = useState(false);
  const fetchedRolesRef = useRef(false);
  const [isFilled, setIsFilled] = useState(false);
  const isFetching = useRef(false);
  const { user } = useUserContext();

  useEffect(() => {
    setIsFilled(!!userRole && !!place && !!story && !!messages);
  }, [userRole, place, story, messages]);
  const [error, setError] = useState('');
  const [isValueSwitcherToggledRole, setIsValueSwitcherToggledRole] =
    useState(false);
  const [isValueSwitcherToggledPlace, setIsValueSwitcherToggledPlace] =
    useState(false);

  // useEffect(() => {
  //   if (!fetchedRolesRef.current) {
  //     fetchedRolesRef.current = true;
  //     randomiseRoles();
  //   }
  // });
  // const checkState = (): boolean => {
  //   const errors = {
  //     [appContent['generation-personality-enter-name']]: !name,
  //     'Enter kinks': kinks.length < 3,
  //     'Enter occupation': !occupation.length,
  //     'Enter personality': !personalityType,
  //   };
  //   return Object.entries(errors).every(([key, val]) => {
  //       if (val === true){
  //         setTimeout(() => setError(key), 40);
  //         return false;
  //       }
  //       return true;
  //   })

  // }

  const updateDraft = () => {
    // if (!messages || !story || !userRole || !place) {
    //   return;
    // }
    if (updating) {
      return;
    }
    if (
      !checkState(
        {
          "You haven't selected your Role": !userRole,
          "You haven't selected the Place": !place,
          "You don't have a story yet.": !story,
          "You don't have example messages.": !messages,
        },
        (e) => setError(e)
      )
    ) {
      return;
    }
    setUpdating(true);

    const stage = CharacterGenerationStage.Story;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = {
      UserRole: userRole,
      Place: { Title: place, Prompt: null },
      Story: story,
      Messages: messages!,
    };
    onChoice(stage, data);
  };

  const randomiseRoles = () => {
    if (userRolesFetching) {
      return;
    }
    setUserRolesFetching(true);
    CharacterAPI.generateUserRoles({
      Occupation: personality.Occupation,
      RolesCount: 3,
      Gender: 'Female',
      Personality: personality.Personality,
    }).then(({ data }) => {
      setUserRole('');
      setUserRoles(data.Roles.slice(0, 3));
      setUserRolesFetching(false);
    });
  };

  const randomisePlaces = () => {
    if (placesFetching || !userRole) {
      return;
    }
    setPlacesFetching(true);

    CharacterAPI.generatePlaces({
      Occupation: personality.Occupation,
      PlacesCount: 3,
      UserRole: userRole,
    }).then(({ data }) => {
      setPlace('');
      setPlaces(data.Places.slice(0, 3));
      setPlacesFetching(false);
    });
  };

  const randomiseStory = () =>
    CharacterAPI.generateStory({
      BotOccupation: personality.Occupation,
      UserRole: userRole,
      Place: place,
      BotName: personality.Name,
      BotPresonality: personality.Personality,
      BotType: personality.Type,
    }).then(({ data }) => data.Story);
  // .then(({ data }) => {
  //   return replaceUserName(data.Story, user!);
  // });

  const randomiseMessages = () =>
    CharacterAPI.generateMessages({
      BotOccupation: personality.Occupation,
      Place: place,
      BotName: personality.Name,
      BotPresonality: personality.Personality,
      Story: story,
      BotType: personality.Type,
      BotKinks: `[${personality.Kinks.join(',')}]`,
    }).then(({ data }) => {
      const mergedMessages: ExampleMessage[] = [];
      data.ExampleMessages.forEach((messageBlock) => {
        messageBlock.forEach((message) => {
          mergedMessages.push(message);
        });
      });
      return mergedMessages;
    });
  return (
    <div className="w-100 d-flex flex-column gap-2  h-100 text-start">
      {updating && <FullSizeLoader />}

      <div className="overflow-y-auto d-flex flex-column gap-5 pt-20 horny-border-bottom pb-3 horny-disable-scrollbar">
        <IntroBlock
          onButtonClick={console.log}
          title={t('Story')}
          description={t('generation story description')}
          note={
            <div>
              <span className="fw-bold opacity-75 text-decoration-underline mr-5">
                {t('generation note')}
              </span>
              <span className=" opacity-75">
                {t('generation story description note')}
              </span>
            </div>
          }
        />
        <div className="d-flex flex-column gap-5 text-start">
          <ValueSwitcher
            onSelectValue={(selected) => {
              setUserRole(selected[0]);
            }}
            onManualChange={(isManual) =>
              setIsValueSwitcherToggledRole(isManual)
            }
            selectText={t('your role')}
            tags={[
              ...userRoles.map((role) => ({
                Title: role,
                Icon: '',
                ParamId: '',
              })),
              ...(fetched?.UserRole
                ? [
                    {
                      Title: fetched.UserRole,
                      Icon: '',
                      ParamId: '',
                    },
                  ]
                : []),
            ]}
            title={t('generation role')}
            description={`${t('generation role description')} ${t('Occupation youve chosen for the character earlier')}: ${t(personality.Occupation as any)}.`}
            onRegenerate={randomiseRoles}
            onRenerateTitle={t('role')}
            // className={!userRoles.length ? 'opacity-75 pe-none' : ''}
            preSelected={
              userRole
                ? [userRole]
                : fetched?.UserRole
                  ? [fetched.UserRole]
                  : undefined
            }
          />

          {!isValueSwitcherToggledRole && (
            <div className="text-center">
              <HornyButton
                bg={HornyButtonBG.Orange}
                height={HornyButtonHeight.Medium}
                onClick={() => randomiseRoles!()}
                className="mx-auto"
                shadow="horny-shadow-red-with-orange"
              >
                <div>
                  <Icon
                    src={userRolesFetching ? IconSrc.Reload : IconSrc.Magic}
                    size={IconSize.Small}
                    className={`${userRolesFetching ? 'horny-spin' : ''}`}
                  />
                </div>
                <div>
                  {userRolesFetching
                    ? t('Generating...')
                    : t('Generate Your Role')}
                </div>
                <div></div>
              </HornyButton>
            </div>
          )}

          <ValueSwitcher
            onSelectValue={(selected) => setPlace(selected[0])}
            onManualChange={(isManual) =>
              setIsValueSwitcherToggledPlace(isManual)
            }
            selectText={t('the place')}
            tags={[
              ...places.map((place) => ({
                Title: place,
                Icon: '',
                ParamId: '',
              })),
              ...(fetched?.Place
                ? [
                    {
                      Title: fetched.Place.Title,
                      Icon: '',
                      ParamId: '',
                    },
                  ]
                : []),
            ]}
            title={t('The Place Where the Story Begins')}
            description={t(
              'Select the setting for your first encounter with your AI girlfriend.'
            )}
            className={userRole ? '' : 'opacity-50 pe-none'}
            onRegenerate={randomisePlaces}
            preSelected={
              place
                ? [place]
                : fetched?.Place
                  ? [fetched.Place.Title]
                  : undefined
            }
            isShowManual={false}
          />
          {!isValueSwitcherToggledPlace && (
            <div
              className={
                userRole ? 'text-center' : 'text-center opacity-50 pe-none'
              }
            >
              <HornyButton
                bg={HornyButtonBG.Orange}
                height={HornyButtonHeight.Medium}
                onClick={() => randomisePlaces!()}
                className="mx-auto"
                shadow="horny-shadow-red-with-orange"
              >
                <div>
                  <Icon
                    src={placesFetching ? IconSrc.Reload : IconSrc.Magic}
                    size={IconSize.Small}
                    className={`${placesFetching ? 'horny-spin' : ''}`}
                  />
                </div>
                <div>
                  {!userRole
                    ? t('generation place locked')
                    : placesFetching
                      ? t('Generating...')
                      : t('Generate Places')}
                </div>
                <div></div>
              </HornyButton>
            </div>
          )}

          <CreateStory
            description={t('generation story story description')}
            title={t('Story')}
            onSelect={(story) => setStory(story)}
            disabled={!userRole || !place}
            generateStory={randomiseStory}
            fetchedStory={fetched?.Story}
          />
          <CreateMessages
            name={personality.Name}
            onSelect={(messagesContent) => setMessages(messagesContent)}
            disabled={!userRoles || !place || !story}
            description={randomDescription}
            generateMessages={randomiseMessages}
            fetchedMessages={fetched?.Messages}
          />
        </div>
      </div>
      {
        <div
          className="  m-auto  d-flex flex-column gap-4 align-items-end pb-4"
          style={{ bottom: 0 }}
        >
          {!!error ? (
            <div
              className={`text-center w-100 ${error ? 'scale-up-ver-bottom' : ''}`}
            >
              {t(error as any)}
            </div>
          ) : (
            <div className="opacity-0">{t('error')}</div>
          )}

          <HornyButton
            height={HornyButtonHeight.Tall}
            {...(isFetching.current ? { disabled: true } : {})}
            onClick={() => !isFetching.current && updateDraft()}
            icon={IconSrc.Next}
            text={`${isFetching.current ? t('Preparing...') : t('Choose appearance type')}`}
          ></HornyButton>
          <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
            <u onClick={onBack}>{t('Back')}</u>
          </div>
        </div>
      }
    </div>
  );
}

export const generatePlaceholderBlocks = (maxAmount: number) => {
  const blocks: ReactElement[] = [];
  let block: ReactElement = <div></div>;
  let blockLength = 0;
  while (blockLength <= maxAmount) {
    const width = Math.floor(Math.random() * (50 - 5 + 1) + 5);
    if (blockLength + width >= maxAmount) {
      break;
    }
    block = (
      <div
        className="d-inline-block mb-2 me-2 rounded-2"
        style={{ height: 16, width, backgroundColor: '#555555' }}
      ></div>
    );
    blocks.push(block);
    blockLength += width;
  }
  return blocks;
};
