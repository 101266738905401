import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useModalContext } from 'src/context/Modal.Context';
import { MediaType } from 'src/services/API/MediaAPI';
import useKeepScrollPosition from './useKeepScrollPosition';
import useMedia from './useMedia';

import { motion } from 'framer-motion';
import { ChatMediaPreview } from 'src/components/Chat/ChatMedia/ChatMediaPreview';
import { getAxiosInstance } from 'src/services/axios/axios';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { MediaBlockSwitcher } from '../Shared/MediaBlockSwitcher/MediaBlockSwitcher';
import { MediaFilter } from '../Shared/MediaFilter/MediaFilter';
import { NoMedia } from '../Shared/NoMedia';
import './../index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

export enum MediaBlock {
  Dialogue = 'dialogue',
  Community = 'community',
}
export type MediaProps = {
  selectedMediaBlock: MediaBlock;
  botId: string;
  onSelectMediaBlock: (block: MediaBlock) => any;
};

export enum MediaStatus {
  Init = 'init',
  Idle = 'idle',
  Loading = 'loading',
}
export function DialogueMedia({
  botId,
  selectedMediaBlock,
  onSelectMediaBlock,
}: MediaProps) {
  const { media, setLastMediumRef, total, status, resetMedia } = useMedia({
    botId,
    selectedMediaBlock,
  });
  // const [init, setIinit] = useState(true);

  const { containerRef } = useKeepScrollPosition([media]);
  const { addModal } = useModalContext();
  const [selectedMediaType, setSelectedMediaType] = useState<MediaType | null>(
    null
  );

  const [init, setInit] = useState(false);
  const [refresh, setRefresh] = useState(true);
  // useEffect(() => {
  //   if (refresh && media){
  //     setRefresh(false);
  //     resetMedia();
  //   }
  // },[]);
  const deleteMediaListenerRef = useRef(false);
  const [filteredOutMedia, setFilteredOutMedia] = useState<string[]>([]);
  useEffect(() => {
    if (deleteMediaListenerRef.current) {
      return;
    }
    deleteMediaListenerRef.current = true;
    getAxiosInstance().interceptors.response.use(
      (res) => {
        if (res.config.url === 'removemedia' && res.status === 200) {
          const mediaId = JSON.parse(res.config.data).MediaId;
          setFilteredOutMedia([
            ...filteredOutMedia.filter((m) => m !== mediaId),
            mediaId,
          ]);
        }
        return res;
      },
      (err) => {
        throw err;
      }
    );
    getAxiosInstance().interceptors.response.use(
      (res) => {
        if (res.config.url === 'addmedia' && res.status === 200) {
          const mediaId = JSON.parse(res.config.data).MediaId;
          setFilteredOutMedia([
            ...filteredOutMedia.filter((m) => m !== mediaId),
          ]);
        }
        return res;
      },
      (err) => {
        throw err;
      }
    );
  });

  useEffect(() => {
    if (!init && containerRef.current && media) {
      //  console.log(containerRef.current);
      setTimeout(() => {
        (containerRef.current as unknown as HTMLElement).scrollTo(0, 0);
      }, 20);
      setInit(true);
    }
  }, [init, media]);

  if (!media) {
    return (
      <div className="media_container px-2">
        <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
          <MediaFilter
            selectedType={selectedMediaType}
            onSelect={(mediaType) => setSelectedMediaType(mediaType)}
          />
          <MediaBlockSwitcher
            selectedBlock={selectedMediaBlock}
            onSelect={(block) => {
              // resetMedia();
              onSelectMediaBlock(block);
            }}
          />
        </div>
        <div
          ref={(ref) => setLastMediumRef(ref as SetStateAction<any>)}
          className="m-auto text-center h-100 d-flex justify-content-center align-items-center"
        >
          <FontAwesomeIcon icon={faSpinnerThird} size="xl" spin />
        </div>
      </div>
    );
  }
  // if (media.length === 0){
  //   return <NoMedia/>
  // }
  return (
    <div className="media_container px-2">
      <div className="d-flex gap-2 mb-2 justify-content-between media_controls">
        <MediaFilter
          selectedType={selectedMediaType}
          onSelect={(mediaType) => setSelectedMediaType(mediaType)}
        />
        <MediaBlockSwitcher
          selectedBlock={selectedMediaBlock}
          onSelect={(block) => {
            // resetMedia();
            onSelectMediaBlock(block);
          }}
        />
      </div>
      {media.length === 0 && <NoMedia />}
      <motion.div
        className="w-100 m-auto media"
        ref={containerRef}
        animate="in"
        initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
      >
        {media
          .filter(({ MediaType }) => {
            if (selectedMediaType === null) {
              return true;
            }
            return selectedMediaType === MediaType;
          })
          .filter((m) => !filteredOutMedia.includes(m.MediaId))
          .map((m, i) => {
            return (
              <div
                key={m.MediaId}
                style={{ height: 0, paddingBottom: '100%' }}
                className="position-relative overflow-hidden horny-border-gray rounded-2 border"
                ref={(ref) =>
                  status !== MediaStatus.Loading &&
                  i > media.length - 4 &&
                  total > media.length
                    ? setLastMediumRef(ref as SetStateAction<any>)
                    : null
                }
              >
                <ChatMediaPreview {...m} />
              </div>
            );
          })}
      </motion.div>
      {status === MediaStatus.Loading && (
        <div className="m-auto text-center  d-flex justify-content-center">
          <FontAwesomeIcon icon={faSpinnerThird} size="xl" spin />
        </div>
      )}
    </div>
  );
}
