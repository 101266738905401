import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { cn } from 'src/shared/utils';

export type LegalDocsProps = {
  align?: 'left' | 'center';
};

export default function LegalDocs({ align = 'center' }: LegalDocsProps) {
  const { legalDocs } = useResourcesContext();
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'tw-flex tw-flex-wrap tw-gap-3',
        align === 'center' && 'tw-justify-center',
        align === 'left' && 'tw-justify-start'
      )}
    >
      {legalDocs?.map((doc) => (
        <div className="tw-flex tw-flex-row tw-text-xs tw-items-center tw-gap-1">
          <FontAwesomeIcon icon={faLink} />
          <a
            href={doc.url}
            target="_blank"
            rel="noreferrer"
            key={doc.title}
            className="tw-underline tw-underline-offset-2 tw-font-bold"
          >
            {t(doc.title)}
          </a>
        </div>
      ))}
    </div>
  );
}
