import {
  faSpinnerThird,
  faStars,
  faUp,
  faWarning,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { NutakuAPI } from 'src/services/API/NutakuAPI';
import { Platform, SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { cn } from 'src/shared/utils';
import { ArkSwitch } from '../Shared/Ark/ArkSwitch';
import {
  Icon,
  IconSize,
  IconSrc,
  SubscriptionIcons,
} from '../Shared/Icon/Icon';
import { Timer } from '../Shared/Timer/Timer';
import { Subscription } from './Subscription';
import { Upgrade } from './Upgrades/Upgrade';
import './index.css';

export type NutakuPrice = {
  price: number;
  price_year: number;
};

type NutakuPricesQueryType = UseQueryResult<
  AxiosResponse<{ price: number; price_year: number }>,
  Error
> & {
  meta?: {
    level: string;
  };
};

export enum ShowSubscriptionsReason {
  Messages = 'messages',
  Energy = 'energy',
  CommunityMedia = 'communityMedia',
  Premium = 'premuim',
  Upgrade = 'upgrade',
  PrivateBot = 'PrivateBot'
}
export type SubscriptionsProps = {
  showReason?: ShowSubscriptionsReason;
  source?: string;
  defaultTab?: 'subscription' | 'upgrade';
};

const subscriptionsEnergy = {
  [SubscriptionLevel.Friend]: 400,
  [SubscriptionLevel.Boyfriend]: 400,
  [SubscriptionLevel.Daddy]: 1200,
  [SubscriptionLevel.Boss]: Infinity,
  [SubscriptionLevel.Master]: Infinity,
};

/* @todo: Разделить на 2 компонента, если будет много различий Nutaku и Web */
export const subscriptionsOrder = [
  SubscriptionLevel.Friend,
  SubscriptionLevel.Boyfriend,
  SubscriptionLevel.Daddy,
  SubscriptionLevel.Boss,
  SubscriptionLevel.Master,
];
export function Subscriptions({
  showReason,
  source,
  defaultTab = 'subscription',
}: SubscriptionsProps) {
  const { t } = useTranslation();
  const nutakCotext = useNutakuContext();
  const [isYearly, setIsYearly] = useState(true);

  const isNutaku = Boolean(nutakCotext?.isNutaku);
  const { user } = useUserContext();

  const { prices } = useResourcesContext();
  const { capture } = useAnalyticsContext();
  const pathName = useMemo(
    () => window.location.pathname + window.location.search,
    []
  );

  const [showTimer] = useState(
    () =>
      !!showReason &&
      showReason !== ShowSubscriptionsReason.CommunityMedia &&
      showReason !== ShowSubscriptionsReason.Upgrade &&
      showReason !== ShowSubscriptionsReason.Premium &&
      showReason !== ShowSubscriptionsReason.PrivateBot
  );

  const timerText = {
    [ShowSubscriptionsReason.Messages]: (
      <span>
        {t('No free messages left reason', {
          messages: !user?.MergedSubscription.Level
            ? prices?.NoSubscription.Messages
            : user?.MergedSubscription.Level === SubscriptionLevel.Friend
              ? prices?.Subscriptions.Friend.Messages
              : '∞',
        })}{' '}
      </span>
    ),
    [ShowSubscriptionsReason.Energy]: (
      <div className="tw-flex tw-items-center">
        {t('Too few energy')}.&nbsp;
        <Icon src={IconSrc.Energy} size={IconSize.XSmall} />
        {user?.Subscription.Level
          ? subscriptionsEnergy[user?.Subscription.Level]
          : prices?.NoSubscription.Energy}{' '}
        &nbsp; {t('in')}
      </div>
    ),
    [ShowSubscriptionsReason.CommunityMedia]: (
      <div className="tw-flex tw-items-center">
        {t('Subscribe to see community media')}
      </div>
    ),
    [ShowSubscriptionsReason.Premium]: (
      <div className="tw-flex tw-items-center">
        {t('Premium / Upgrade required')}
      </div>
    ),
    [ShowSubscriptionsReason.Upgrade]: (
      <div className="tw-flex tw-items-center">
        {t('Premium / Upgrade required')}
      </div>
    ),
    [ShowSubscriptionsReason.PrivateBot]: (
      <div className="tw-flex tw-items-center">
      {t('Premium of at least Boyfriend level required to make character private')}
    </div>
    )
  };

  // Что это такое
  // это формирование массива из enum, чтобы автоматически собирался
  const subscriptionLevelsToRender: SubscriptionLevel[] = user?.Subscription
    .Level
    ? []
    : (Object.keys(prices!.Subscriptions) as SubscriptionLevel[]);
  if (user?.Subscription.Level !== null) {
    Object.keys(prices!.Subscriptions).forEach((key) => {
      if (
        subscriptionsOrder.indexOf(key as SubscriptionLevel) >=
        subscriptionsOrder.indexOf(
          user?.Subscription.Level as SubscriptionLevel
        )
      ) {
        subscriptionLevelsToRender.push(key as SubscriptionLevel);
      }
    });
  }

  const subscriptionPricesQueries = useQueries({
    queries: [
      ...subscriptionLevelsToRender.map((level) => ({
        queryKey: ['subscription', level],
        queryFn: () => NutakuAPI.getNutakuPrice(level),
        enabled: isNutaku,
      })),
    ],
  });
  useEffect(() => {
    subscriptionPricesQueries.forEach((sub) => sub.refetch());
  }, [user?.Subscription.Level, user?.Upgrades.length]);

  const upgradesQuery = useQuery({
    queryKey: ['upgrades'],
    queryFn: () => NutakuAPI.getUpgrades(),
    enabled: isNutaku,
  });

  useEffect(() => {
    upgradesQuery.refetch();
  }, [user?.Upgrades.length]);

  const [tab, setTab] = useState(defaultTab === 'subscription' ? false : true);

  return (
    <motion.div
      className="tw-w-full tw-h-full tw-flex tw-flex-col tw-overflow-hidden"
      animate="in"
      initial="out"
      exit="out"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
    >
      <div className="tw-flex tw-flex-col tw-gap-4 tw-pt-20 tw-pb-6 tw-border-b tw-border-b-white tw-border-opacity-25 tw-shadow">
        <div className="tw-flex tw-flex-row tw-gap-3 tw-items-center tw-justify-center">
          <Icon src={IconSrc.Premium} size={IconSize.XL} />
          <div className="tw-font-bold tw-text-3xl">{t('Premium')}</div>
        </div>
        {true && (
          <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
            <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-justify-center tw-px-3 tw-py-2 tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-shadow tw-w-min">
              <motion.div
                className={cn(
                  'tw-flex tw-flex-row tw-gap-2 tw-items-center',
                  !tab
                    ? 'tw-pointer-events-none'
                    : 'tw-opacity-75 tw-cursor-pointer'
                )}
                onClick={() => {
                  setTab(false);
                }}
                whileTap={{ scale: 0.9 }}
              >
                <FontAwesomeIcon icon={faStars} />
                <div
                  className={cn(
                    'tw-font-bold',
                    !tab && 'tw-underline tw-underline-offset-2'
                  )}
                >
                  {t('Premium')}
                </div>
              </motion.div>
              <motion.div
                className={cn(
                  'tw-flex tw-flex-row tw-gap-2 tw-items-center',
                  tab
                    ? 'tw-pointer-events-none'
                    : 'tw-cursor-pointer tw-opacity-75'
                )}
                onClick={() => {
                  setTab(true);
                }}
                whileTap={{ scale: 0.9 }}
              >
                <FontAwesomeIcon icon={faUp} />
                <div
                  className={cn(
                    'tw-font-bold',
                    tab && 'tw-underline tw-underline-offset-2'
                  )}
                >
                  {t('Upgrades')}
                </div>
              </motion.div>
            </div>
          </div>
        )}
        {showReason && (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-text-sm">
            <FontAwesomeIcon icon={faWarning} />
            <div className="tw-font-bold">{timerText[showReason]}</div>
            <div className="tw-opacity-75">
              {showTimer && <Timer onTimeReach={console.log} />}
            </div>
          </div>
        )}
      </div>
      {true &&
        (upgradesQuery.isLoading ||
          subscriptionPricesQueries.some((query) => query.isLoading)) && (
          <div className="tw-w-full tw-h-full tw-flex tw-flex-col  tw-items-center tw-justify-center tw-text-7xl">
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
        )}
      {((true &&
        upgradesQuery.isSuccess &&
        subscriptionPricesQueries.every((query) => query.isSuccess)) ||
        !isNutaku) && (
        <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-pb-20 tw-px-3 tw-scrollbar-hide tw-overflow-y-auto tw-pt-4">
          {user?.InviteSubscription.Level !== null && (
            <div className="tw-flex tw-flex-col tw-gap-1 tw-opacity-75">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-justify-center">
                <div>{t('You currently have active Invite Subscription')}:</div>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <Icon
                    src={
                      SubscriptionIcons[
                        user?.InviteSubscription
                          .Level as keyof typeof SubscriptionIcons
                      ]
                    }
                    size={IconSize.Small}
                  />
                  <div className="tw-font-bold">
                    {t(user?.InviteSubscription.Level as any)}
                  </div>
                </div>
              </div>
              <div className="tw-text-center">
                {t('Invite Subscription ends')}{' '}
                <span className="tw-font-bold">
                  {String(user?.InviteSubscription.Ends).split('T')[0]}
                </span>
              </div>
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-justify-center">
                <FontAwesomeIcon icon={faWarning} />
                <div>{t('Buying regular subscription will override it.')}</div>
              </div>
            </div>
          )}
          <AnimatePresence mode="wait">
            {tab ? (
              <motion.div
                animate="in"
                initial="out"
                exit="out"
                variants={AnimParamInOutOpacityYNeg100}
                transition={TransTweenAnticipate03}
                key="upgrades"
              >
                <div className="tw-flex tw-flex-col tw-gap-4">
                  {upgradesQuery.data?.data.map((upgrade) => {
                    return (
                      <Upgrade
                        isYearly={isYearly}
                        upgrade={upgrade}
                        key={upgrade.upgrade_id}
                        upgrades={upgradesQuery.data?.data}
                      />
                    );
                  })}
                </div>
              </motion.div>
            ) : (
              <motion.div
                animate="in"
                initial="out"
                exit="out"
                variants={AnimParamInOutOpacityYNeg100}
                transition={TransTweenAnticipate03}
                key="subscriptions"
                className="tw-flex tw-flex-col tw-gap-4"
              >
                {subscriptionLevelsToRender
                  .filter((key) =>
                    isNutaku
                      ? prices!.Subscriptions[
                          key as SubscriptionLevel
                        ].Platform?.includes(Platform.Nutaku)
                      : prices!.Subscriptions[
                          key as SubscriptionLevel
                        ].Platform?.includes(Platform.Web)
                  )
                  .reverse()
    
                  .map((key) => {
                    return (
                      <Subscription
                        key={key}
                        {...{
                          level: key as SubscriptionLevel,
                          isYearly: isYearly,
                          subscriptionData:
                            prices!.Subscriptions[key as SubscriptionLevel],
                          selectedLevel: user!.Subscription.Level === key,
                          selectedProvider: user!.Subscription.Provider,
                          nutakuPrice:
                            subscriptionPricesQueries[
                              subscriptionLevelsToRender.indexOf(key)
                            ]?.data?.data!,
                          upgrades: upgradesQuery.data?.data.map(
                            (u) => u.title
                          )!,
                        }}
                      />
                    );
                  })}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
      {  (
        <div className="tw-absolute tw-w-full justify-content-center tw-z-50 tw-flex tw-bottom-8">
          <div className="tw-shadow tw-flex tw-flex-row tw-gap-3 tw-bg-horny-gray-900 tw-border tw-border-horny-gray-600 tw-rounded-md tw-px-6 tw-py-3">
            <ArkSwitch
              defaultChecked={isYearly}
              onChange={(e) => {
                setIsYearly(e);
                console.log('Changed to', e);
              }}
              labelPosition="left"
              label={t('Yearly')}
              size="sm"
            />
          </div>
        </div>
      )}
    </motion.div>
  );
}
