import { motion } from 'framer-motion';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';

export type LanguageProps = {
  icon: IconSrc;
  language: string;
};

export const Languages: { [code: string]: LanguageProps } = {
  en: {
    icon: IconSrc.en,
    language: 'English',
  },
  ru: {
    icon: IconSrc.ru,
    language: 'Русский',
  },
  de: {
    icon: IconSrc.de,
    language: 'Deutsch',
  },
  fr: {
    icon: IconSrc.fr,
    language: 'Français',
  },
  it: {
    icon: IconSrc.it,
    language: 'Italiano',
  },
  es: {
    icon: IconSrc.es,
    language: 'Español',
  },
  pt: {
    icon: IconSrc.pt,
    language: 'Português',
  },
  pl: {
    icon: IconSrc.pl,
    language: 'Polski',
  },
  tr: {
    icon: IconSrc.tr,
    language: 'Türkçe',
  },
  ja: {
    icon: IconSrc.ja,
    language: '日本語',
  },
  zh: {
    icon: IconSrc.zh,
    language: '汉语',
  },
  ko: {
    icon: IconSrc.ko,
    language: '한국어',
  },
};

export type LanguageButtonProps = LanguageProps & {
  code: string;
  currentLanguage: string;
};

const HandleLanguageChange = (code: string) => {
  i18next.changeLanguage(code);
  window.location.reload();
};

export function LanguageButton({
  language,
  icon,
  code,
  currentLanguage,
}: LanguageButtonProps) {
  return (
    <motion.div
      className="w-50 p-2 cursor-pointer"
      whileTap={{ scale: 0.8 }}
      onClick={() => HandleLanguageChange(code)}
    >
      <div
        className="d-flex flex-row gap-3 horny-bg-dark_half px-8 py-3 horny-border_100 align-items-center"
        style={{
          border:
            code === currentLanguage
              ? '1px solid rgba(255, 255, 255, 0.5)'
              : 'none',
        }}
      >
        <Icon src={icon} />
        <div>{language}</div>
        {code === currentLanguage && (
          <div className="align-items-center d-flex">
            <Icon src={IconSrc.check} size={IconSize.XSmall} />
          </div>
        )}
        {/* <div>{code}</div> */}
      </div>
    </motion.div>
  );
}

export function LanguagePopup() {
  const { t } = useTranslation();
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div className="px-6 py-11 d-flex flex-column gap-4 horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
          <div className="fw-bold fs-2">{t('Choose App Language')}</div>
          <div className="d-flex flex-wrap">
            {Object.keys(Languages).map((code) => {
              const languageData = Languages[code];
              return (
                <LanguageButton
                  code={code}
                  currentLanguage={i18next.resolvedLanguage!}
                  {...languageData}
                />
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
