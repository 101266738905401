import { useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export type NewInputProps = {
  onValueChange: (value: string) => void;
  defaultText?: string;
};

// const { t } = useTranslation();

export function NewInput({
  onValueChange,
  defaultText = 'Enter text here...',
}: NewInputProps) {
  const [requestTextContent, setRequestTextContent] = useState(() => {
    return '';
  });

  const [rowsCount, setRowsCount] = useState(1);
  const handleInputRef = useRef<HTMLTextAreaElement | null>(null);
  const recalculateRows = (textareaElement: HTMLTextAreaElement) => {
    // const style = window.getComputedStyle(textareaElement);
    // const lineHeight = parseInt(style.getPropertyValue('line-height'));
    // const paddingTop = parseInt(style.getPropertyValue('padding-top'));
    // const paddingBottom = parseInt(style.getPropertyValue('padding-bottom'));
    // // textareaElement.rows = 1
    // const totalHeight =
    //   textareaElement.scrollHeight - paddingTop - paddingBottom;
    // const numberOfLines = totalHeight / lineHeight;
    // if (numberOfLines > 5) {
    //   return;
    // }
    // setRowsCount(numberOfLines);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setRequestTextContent(value);

    onValueChange(value);

    if (handleInputRef.current !== null) {
      recalculateRows(handleInputRef.current);
    }
  };

  return (
    <InputGroup className="d-flex w-100 rounded-2 overflow-hidden position-relative z-0 justify-content-center rounded-2 horny-new-input-outer">
      <Form.Control
        ref={handleInputRef}
        onChange={handleOnChange}
        as="textarea"
        placeholder={defaultText}
        rows={rowsCount}
        value={requestTextContent}
        style={{
          backgroundColor: 'transparent',
          width: 'calc(100% - 60px)',
          resize: 'none',
        }}
        aria-describedby="basic-addon1"
        // className="horny-new-input border-0 text-left p-6 horny-text_medium"
        className="horny-text_white horny-new-input border-0 horny-text_medium text-left p-6"
      ></Form.Control>
    </InputGroup>
  );
}
