import { useRef, useState } from 'react';
import {
  AnalitycsCaptureParams,
  AnalyticsContextProps,
} from './AnalyticsContext';
import { PricesAPIModel } from 'src/services/API/ResourcesAPI';
// import { UserAPIModel } from "src/services/API/UserAPI";
import { useResourcesContext } from '../ResourcesContext';

export type AnalyticEventAtrributes = {
  [dataAttributeValue: string]: AnalitycsCaptureParams[];
};

export function registerAnalyticsListeners(
  analyticsContext: Omit<AnalyticsContextProps, 'setPurchaseFrom'>,
  prdices: PricesAPIModel
) {
  const clickListenersAreSet = useRef(false);
  const responseInterceptorsAreSet = useRef(false);
  const { prices } = useResourcesContext();
  const [lastSpent, setLastSpent] = useState('');

  const captureEventByFoundAttrubute = (
    dataAttribute: string,
    extraAttributes: Record<string, string>
  ) => {
    // console.log({targetEl});
    const configForDataAttribute = analyticEventsByAttributes[dataAttribute];
    if (configForDataAttribute) {
      configForDataAttribute.forEach((eventData) => {
        analyticsContext.capture({
          ...eventData,
          data: {
            ...eventData.data,
            ...extraAttributes,
          },
        });
      });
    }
  };

  // useEffect(() => {
  //   // if (!clickListenersAreSet.current) {
  //   //   clickListenersAreSet.current = true;
  //   //   window.addEventListener('click', (e) => {
  //   //     // e.stopImmediatePropagation();

  //   //     console.log(e.target);
  //   //     e.stopPropagation();
  //   //     const target = e.target as HTMLElement;
  //   //     const elementData = findDataAnalyticsAttributeRecursively(target);
  //   //     console.log({elementData});
  //   //     if (elementData) {
  //   //       const extraAttributes = getExtraAttributes(elementData.element);

  //   //       captureEventByFoundAttrubute(
  //   //         elementData.dataAttribute,
  //   //         extraAttributes
  //   //       );
  //   //     }
  //   //    // return true;
  //   //   });
  //   // }
  // }, []);

  const getExtraAttributes = (element: HTMLElement): Record<string, any> => {
    const attributeNames = element.getAttributeNames();
    const extraAttributes: Record<string, any> = {};
    attributeNames
      .filter((attributeName) => attributeName !== 'data-anal')
      .filter((attributeName) => attributeName.startsWith('data-anal'))
      .forEach((attributeName) => {
        const attributeValue = element.getAttribute(attributeName);
        if (!attributeValue) {
          return;
        }
        const slicedAttributeName = attributeName.replace('data-anal-', '');
        extraAttributes[slicedAttributeName] = attributeValue;
      });

    return extraAttributes;
  };

  const findDataAnalyticsAttributeRecursively = (target: HTMLElement) => {
    let level = 0;
    let inspecting = target;
    while (level < 5) {
      console.log({ inspecting });
      const dataAttribute = inspecting.getAttribute('data-anal');
      if (dataAttribute) {
        return { dataAttribute, element: inspecting };
      }
      if (inspecting.parentElement) {
        inspecting = inspecting.parentElement;
        level += 1;
      } else {
        return null;
      }
    }

    return null;
  };

  // useEffect(() => {
  //   if (!responseInterceptorsAreSet.current) {
  //     responseInterceptorsAreSet.current = true;
  //     axiosInstance.interceptors.response.use(
  //       (res: AxiosResponse<any>) => {
  //         if (res.headers.energy) {
  //           const { url } = res.config;
  //           url && captureEventByPayedRoute(url);
  //         }
  //         return res;
  //       },
  //       (error: AxiosError) => {
  //         throw error;
  //       }
  //     );
  //   }
  // }, []);

  const captureEventByPayedRoute = (url: string) => {
    const eventSet = analyticEventsByAttributes[url];
    eventSet &&
      eventSet.forEach((eventData) => {
        // console.log('sending')
        analyticsContext.capture(eventData);
      });
  };

  // const routesEventsMatch:  AnalyticEventAtrributes = {
  //     'createcharacterdraft': [],
  //     'generatebotimage': [],
  //     'requestimage': [],
  //     'requestvideo': [],
  //     'requestaudio': [],
  //     'regenerateimage': [],
  // }

  const analyticEventsByAttributes: AnalyticEventAtrributes = {
    media_request: [
      {
        event: 'media_request',
        data: {},
      },
    ],
    createcharacterdraft: getSpentData(
      'bot_creation',
      prices!.Actions.CreateBot
    ),
    generatebotimage: getSpentData('photo', prices!.Media.Photo),
    requestimage: getSpentData('photo', prices!.Media.Photo),
    requestvideo: getSpentData('video', prices!.Media.Video),
    requestaudio: getSpentData('audio', prices!.Media.Audio),
    regenerateimage: getSpentData('photo', prices!.Media.Photo / 2),
  };
}
const getSpentData = (key: string, amt: number): AnalitycsCaptureParams[] => {
  return [
    {
      event: 'currency_spent',
      data: {
        spent_on: key,
        amount: amt,
      },
    },
  ];
};
