import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// Should be here!
import { useUserContext } from './User.context';
import {
  LegalDoc,
  MediaPromptsResponse,
  PricesAPIModel,
} from 'src/@types/common';
import { MainLoader } from 'src/components/Layout/MainLoader';
import { ResourcesAPI } from 'src/services/API/ResourcesAPI';

export type ResourceContextProps = {
  prices: PricesAPIModel | null;
  mediaPrompts: MediaPromptsResponse | null;
  legalDocs: LegalDoc[] | null;
  setPrices: (prices: PricesAPIModel) => any;
  setMediaPrompts: (mediaPrompts: MediaPromptsResponse) => any;
  setLegalDocs: (legalDocs: LegalDoc[]) => any;
};
export const ResourcesContext = createContext<ResourceContextProps>({
  prices: null,
  mediaPrompts: null,
  legalDocs: null,
  setPrices: (prices: PricesAPIModel) => {},
  setMediaPrompts: (mediaPrompts: MediaPromptsResponse) => {},
  setLegalDocs: (legalDocs: LegalDoc[]) => {},
});
export const ResourcesContextProvider = ResourcesContext.Provider;
export const useResourcesContext = () =>
  useContext<ResourceContextProps>(ResourcesContext);

export const ResourceProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const isNutakuShare = process.env.REACT_APP_DOMAIN_TYPE === 'NutakuShare';
  const [prices, setPrices] = useState<PricesAPIModel | null>(null);
  const [mediaPrompts, setMediaPrompts] = useState<MediaPromptsResponse | null>(
    null
  );
  const [legalDocs, setLegalDocs] = useState<LegalDoc[] | null>(null);
  const { user } = useUserContext();
  const isFetchingPricesRef = useRef(false);
  const isFetchingPromptsRef = useRef(false);
  // useEffect(() => {
  //   if (!prices && !isFetchingPricesRef.current) {
  //     // && !isNutakuShare
  //     isFetchingPricesRef.current = true;
  //     ResourcesAPI.getPrices().then(({ data }) => {
  //       setPrices(data);
  //       isFetchingPricesRef.current = false;
  //     });
  //   }
  // });

  // useEffect(() => {
  //   if (!mediaPrompts && user && !isFetchingPromptsRef.current) {
  //     // && !isNutakuShare
  //     isFetchingPromptsRef.current = true;
  //     ResourcesAPI.getMediaPrompts().then(({ data }) => {
  //       setMediaPrompts(data);
  //       isFetchingPromptsRef.current = false;
  //     });
  //   }
  // });

  useEffect(() => {
    ResourcesAPI.getResources().then(({ data }) => {
      setPrices(data.Prices);
      setMediaPrompts(data.MediaPrompts);
      setLegalDocs(data.LegalDocs);
    });
  }, []);

  return (
    <ResourcesContextProvider
      value={{
        prices,
        setPrices,
        mediaPrompts,
        setMediaPrompts,
        legalDocs,
        setLegalDocs,
      }}
    >
      {!prices || !user || !mediaPrompts ? <MainLoader /> : children}
      {/* isNutakuShare && children */}
    </ResourcesContextProvider>
  );
};
