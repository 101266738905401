import { Image } from 'react-bootstrap';
import './index.css';
// import { Progress } from '../../Progress/Progress';
// import Logo from 'src/assets/img/Logo.png';
import { useTranslation } from 'react-i18next';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { appConfig } from 'src/config/app';
export type MainLoaderProps = {
  progressPercent?: number;
  onLoad?: () => any;
};

export function MainLoader({ onLoad }: MainLoaderProps) {
  const { t } = useTranslation();
  return (
    <div className="MainLoader position-fixed p-0 z-20">
      <img
        className="position-absolute"
        style={{ width: 1, height: 1 }}
        src={'?=' + Date.now()}
        onError={() => onLoad && onLoad()}
        onLoad={() => onLoad && onLoad()}
      />
      <div
        className="w-100 h-100 MainLoader_container_bg d-flex align-items-stretch justify-content-center p-5"
        // style={{
        //   backgroundPosition: 'center center',
        //   background: `rgba(51,51,51) url(${appConfig.host.CDN}/img/loader/18.png) center`,
        //   backgroundRepeat: 'no-repeat',
        // }}
      >
        <div className="MainLoader_container  flex-column d-flex align-items-center justify-content-between">
          <div></div>
          <div>
            <div className="text-center">
              <Image
                src={appConfig.host.CDN + '/img/logo.png?height=360'}
                className="m-auto mb-3"
                // onLoad={() => onLoad && onLoad()}
                // onAbort={() => onLoad && onLoad()}
                // onLoadStart={() => onLoad && onLoad()}
                width={180}
              />
            </div>
            <div className="text-center horny-fs-max fw-bold">
              Temptations.ai
            </div>
            <div
              className="text-white text-center opacity-90"
              style={{ lineHeight: '15px' }}
            >
              <div className="text-center">
                {t('loading screen desciption')}
              </div>
            </div>
            <div className="py-7 text-center">
              <Icon
                src={IconSrc.Loader}
                size={IconSize.XL}
                className="horny-spin"
              />
            </div>
          </div>
          <div></div>
          {/* <Progress
            title="Loading atlasses"
            progressPercent={progressPercent >= 100 ? 100 : progressPercent}
          /> */}
        </div>
      </div>
    </div>
  );
}
