import { motion } from 'framer-motion';

export function SheenBlock() {
  return (
    <div className="tw-overflow-hidden tw-absolute tw-z-0 tw-inset-0">
      <motion.div
        className="tw-absolute tw-top-0 tw-left-[-50%] tw-right-[-50%] tw-bottom-0"
        initial={{ x: '-100%' }}
        animate={{ x: '100%' }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: 'loop',
          ease: 'easeInOut',
        }}
        style={{
          background: `
            linear-gradient(
              60deg,
              transparent 30%, 
              rgba(255,255,255,0.1) 30%, 
              rgba(255,255,255,0.2) 50%,
              transparent 50%
            ),
            linear-gradient(
              60deg,
              transparent 55%, 
              rgba(255,255,255,0.05) 55%, 
              rgba(255,255,255,0.1) 65%, 
              transparent 65%
            )`,
        }}
      />
    </div>
  );
}
