import { useEffect, useRef, useState } from 'react';
import './index.css';
import WaveSurfer from 'wavesurfer.js';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';

export type ChatMediaAudioProps = {
  src: string;
  maxWidthVW?: number;
};

export function ChatMediaAudio({ src, maxWidthVW = 60 }: ChatMediaAudioProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [init, setInit] = useState(false);
  const [waveSurferInstance, setWaveSurferInstance] =
    useState<WaveSurfer | null>(null);
  useEffect(() => {
    if (waveSurferInstance && containerRef.current) {
      // setInit(true);

      waveSurferInstance.load(
        process.env.REACT_APP_API_HOST + '/proxymedia?url=' + src
      );
      waveSurferInstance.on('ready', () => {
        waveSurferRef.current = waveSurferInstance;
        setDuration(waveSurferInstance.getDuration());
      });

      waveSurferInstance.on('play', () => setIsPlaying(true));

      waveSurferInstance.on('pause', () => setIsPlaying(false));
    }
  }, [src, waveSurferInstance, containerRef.current]);

  useEffect(() => {
    if (!waveSurferInstance && containerRef.current)
      setWaveSurferInstance(
        WaveSurfer.create({
          container: containerRef.current,
          waveColor: 'rgba(255, 255, 255, 0.5)',
          // url: src,
          autoplay: false,
          height: 60,
          width: 'auto',
          barGap: 2,
          barWidth: 2,
          dragToSeek: true,
          hideScrollbar: true,
          cursorWidth: 0,
        })
      );
    //     waveSurfer.load(src)
    //   waveSurfer.on('ready', () => {
    //     waveSurferRef.current = waveSurfer;
    //     setDuration(waveSurfer.getDuration())
    //   });

    //   waveSurfer.on('play', () =>  setIsPlaying(true));

    //   waveSurfer.on('pause', () =>  setIsPlaying(false))
  }, [waveSurferInstance, containerRef]);

  // console.log({ waveSurferRef, containerRef });

  const playerIconSource = isPlaying ? IconSrc.Pause : IconSrc.Play;
  const widthByDuration =
    20 * duration < 200 ? 20 * duration + 'px' : maxWidthVW + '%';

  return (
    <div className="d-flex justify-content-start align-items-center w-100 gap-2">
      <div onClick={() => waveSurferRef.current?.playPause()}>
        {' '}
        <Icon src={playerIconSource}></Icon>
      </div>
      <div
        className="audio_container mw-100 position-relative"
        style={{ width: widthByDuration }}
        ref={containerRef}
      />
    </div>
  );
}
