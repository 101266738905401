import { IconSrc, IconSize, Icon } from 'src/components/Shared/Icon/Icon';
import {
  MediaAPI,
  MediaAPIModel,
  MediaType as MediaTypeEnum,
} from 'src/services/API/MediaAPI';
import { useState } from 'react';
import { ChatMediaAudio } from 'src/components/Chat/ChatMedia/ChatMediaAudio/ChatMediaAudio';
import { MediaDisplayVideo } from './MediaDisplayVideo';
import { copyURLToClibpoard, shareData } from 'src/shared/helpers';
import { useUserContext } from 'src/context/User.context';
import { useModalContext } from 'src/context/Modal.Context';
import { showAuthorization } from 'src/shared/utils';
import { Link } from 'src/components/Shared/Link/Link';

export function MediaDisplayVideoAudio({
  CoverUrl,
  AddedToMedia,
  Name,
  Liked,
  CanShareWithCommunity,
  MediaId,
  SharedWithCommunity,
  Author,
  LikesCount,
  MediaType: type,
  BotId,
  CanAddToMedia,
}: MediaAPIModel) {
  const [src, setSrc] = useState(CoverUrl);
  const [shareText, setShareText] = useState('Share');
  const mediaUrlToShare = `${window.location.origin}/media?MediaId=${MediaId}`;

  const [isSharedWithCommunity, setIsSharedWithCommunity] =
    useState(SharedWithCommunity);
  const [isLiked, setIsLiked] = useState(Liked);
  const [likesCount, setLikesCount] = useState(LikesCount);
  const [addedToMedia, setAddedToMedia] = useState(AddedToMedia);
  const { user } = useUserContext();
  const { addModal } = useModalContext();

  const shareMediaUrl = () => {
    shareData({
      url: mediaUrlToShare,
      mediaUrl: CoverUrl,
      name: Name || '',
    });
    copyURLToClibpoard(mediaUrlToShare);
    setShareText('Copied!');
    setTimeout(() => {
      setShareText('Shared');
    }, 2000);
  };

  const shareWithCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.shareWithCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(true));
  };

  const removeFromCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.removeFromCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(false));
  };

  const addToMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.add({ MediaId }).then(() => setAddedToMedia(true));
  };

  const removeFromMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.remove({ MediaId }).then(() => setAddedToMedia(false));
  };

  const like = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.like({
      MediaId,
    }).then(() => {
      setIsLiked(true);
      setLikesCount(likesCount + 1);
    });
  };

  const dislike = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.dislike({
      MediaId,
    }).then(() => {
      setIsLiked(false);
      setLikesCount(likesCount - 1);
    });
  };

  const renderMediaElement = {
    [MediaTypeEnum.Video]: () => <MediaDisplayVideo src={CoverUrl} />,
    [MediaTypeEnum.Audio]: () => (
      <ChatMediaAudio src={CoverUrl} maxWidthVW={100} />
    ),
    [MediaTypeEnum.Photo]: () => <div />,
  }[type];
  return (
    <div className="h-100 mx-auto w-max-content w-100 position-relative d-grid">
      {/* <div></div> */}
      <div></div>
      <div className="position-relative align-self-center">
        {renderMediaElement()}
        <div
          className={`w-max-content me-0 ms-auto w-100  position-absolute`}
          style={{ bottom: -75 }}
        >
          <div className="d-flex gap-10 justify-content-center">
            <div className="text-center">
              <Icon
                src={isLiked ? IconSrc.AddRed : IconSrc.Add1}
                size={IconSize.XL}
                onClick={() => (!isLiked ? like() : dislike())}
              />
              <div className="horny-text_tiny mt-1 text-center">
                {likesCount}
              </div>
            </div>
            <a className="text-end d-block" href={CoverUrl} download={true}>
              <Icon src={IconSrc.DownloadUpdated} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-white">Download</div>
            </a>
            <div className="text-end" onClick={() => shareMediaUrl()}>
              <Icon src={IconSrc.share} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-white text-center">
                {shareText}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`text-start d-flex flex-column align-items-between align-self-end mb-5 pb-10`}
      >
        <div></div>
        <div className="text-start d-flex flex-column gap-6">
          <div className="d-flex justify-content-center gap-6">
            <div
              className={`d-flex gap-4 justify-content-center ${CanAddToMedia ? '' : 'pe-none opacity-75'}`}
            >
              {!addedToMedia ? (
                <div
                  className={`d-flex align-items-center gap-2`}
                  onClick={() => addToMedia()}
                >
                  <Icon src={IconSrc.Add} /> <span>Add to media</span>
                </div>
              ) : (
                <div
                  className={`d-flex align-items-center gap-2`}
                  onClick={() => removeFromMedia()}
                >
                  <Icon src={IconSrc.exit} /> <span>Remove from media</span>
                </div>
              )}
            </div>
            <div className={CanShareWithCommunity ? '' : 'pe-none opacity-75'}>
              {!isSharedWithCommunity ? (
                <div
                  className="d-flex  align-items-center gap-2 justify-content-center"
                  {...(CanShareWithCommunity
                    ? { onClick: () => shareWithCommunity() }
                    : {})}
                >
                  <Icon src={IconSrc.share} /> <span>Share with Сommunity</span>
                </div>
              ) : (
                <div
                  className="d-flex  align-items-center gap-2 justify-content-center"
                  {...(CanShareWithCommunity
                    ? { onClick: () => removeFromCommunity() }
                    : {})}
                >
                  <Icon src={IconSrc.exit} /> <span>Remove from Сommunity</span>
                </div>
              )}
            </div>
          </div>
          <div className=" justify-content-center text-center">
            Author: @{Author}{' '}
            <span onClick={console.log}>
              <Icon src={IconSrc.Info} size={IconSize.Tiny} />
            </span>
          </div>
          <div className=" justify-content-center text-center">
            <Link target="_parent" url={'/?GirlId=' + BotId}>
              See more of this character
            </Link>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
