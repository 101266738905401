import { useState } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';

export type StringSelectorProps = {
  onManualChange: (value: string) => any;
  onGenerationCall: () => Promise<any>;
  value: string;
  placeholder: string;
  isFetching: boolean;
};

export function StringSelector(props: StringSelectorProps) {
  // const inputRef = useRef<HTMLInputElement | null>(null);
  const [generating, setGenerating] = useState(false);
  const callGeneration = () => {
    if (generating) {
      return;
    }
    setGenerating(true);

    props.onGenerationCall().finally(() => {
      setGenerating(false);
    });
  };
  // console.log({
  //   props: props.isFetching,
  //   generating,
  // });
  // const [name, setName] = useState(props.name);
  return (
    <InputGroup className="horny-control horny-height_medium rounded-2 overflow-hidden horny-shadow-just-shadow-red">
      <Form.Control
        //   ref={inputRef}
        onChange={(e) => {
          props.onManualChange(e.target.value);
          // setName(e.target.value);
        }}
        value={props.value}
        placeholder={props.placeholder}
        aria-describedby="basic-addon1"
        className="horny-bg-gradient_orange_light horny-input border-0 horny-text_medium text-left ps-4 horny-text_orange"
      />
      <Button
        className="horny-bg-gradient_orange z-0"
        style={{ width: 70 }}
        onClick={() => callGeneration()}
      >
        <Icon
          src={props.isFetching || generating ? IconSrc.Reload : IconSrc.Magic}
          className={props.isFetching || generating ? 'horny-spin' : ''}
        />
      </Button>
    </InputGroup>
  );
}
