import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';

export function AuthRedirect() {
  const navigate = useNavigate();
  const { setUser, user } = useUserContext();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  useEffect(() => {
    if (!!user && user.UserId && !user.IsAnonymous) {
      // captureMyTracker({
      //   type: 'reachGoal',
      //   goal: 'mt_login',
      //   userid: user.UserId,
      // });
      // captureMyTracker({ type: 'setUserID', userid: user.UserId });

      // capture({event:'login', data: {
      //   premium_status: userResponse.Subscription.Level,
      // }});
      window.location.href = '/';
    }
  }, [!!user]);

  // useEffect(() => {
  //   console.log({ user, token });
  //   user && navigate('/');
  // }, [user, navigate]);

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <Icon src={IconSrc.Loader} size={IconSize.XL} className="horny-spin" />
    </div>
  );
}
