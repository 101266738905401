import { Authorization } from 'src/components/Authorization/Authorization';
import { motion } from 'framer-motion';
import { IconSrc, Icon, IconSize } from 'src/components/Shared/Icon/Icon';
import { TabToShow } from '../Main';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';

import './index.css';
import { CharacterGenerationControl } from './CharacterGenerationControl';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useTranslation } from 'react-i18next';

export type PageControlsProps = {
  selectedTab: TabToShow;
  onTabSelect: (tab: TabToShow) => any;
};
export function PageControls({ selectedTab, onTabSelect }: PageControlsProps) {
  const { t } = useTranslation();
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  const { capture } = useAnalyticsContext();
  return (
    <div
      className="main_controls text-center  position-relative z-1 w-100 border-top border-white horny-text_tiny"
      style={{ bottom: 0, maxWidth: 600, height: 80 }}
      onTouchMove={(e) => {
        // e.preventDefault();
        return false;
      }}
      onTouchStart={(e) => {
        // e.preventDefault();
        return false;
      }}
    >
      <div className="d-flex h-100 justify-content-center align-items-center">
          <motion.div
          whileTap={{ scale: 0.8 }}
          className={`d-flex flex-column align-items-center cursor-pointer ${selectedTab === TabToShow.Top ? '' : 'opacity-50'} position-relative`}
          onClick={() => {
           if (user?.IsAnonymous) {
            return addModal({
              children: <Authorization />,
              className: 'horny-bg-gradient_red',
            });
           }
            capture({
              event: 'page_open',
              data: {
                page_name: 'Top',
              },
            });
            onTabSelect(TabToShow.Top);
            window.history.pushState(null, 'Top', '/top');
          }}
          style={{ width: '60px' }}
        >
          {/* <div className="position-absolute center-absolute horny-text-shadow">
            Soon
          </div> */}
          <Icon src={IconSrc.Top} className="mb-2" size={IconSize.Smaller} />
          <div>{t('Top')}</div>
        </motion.div>
         <motion.div
          whileTap={{ scale: 0.8 }}
          className={`d-flex flex-column align-items-center  cursor-pointer ${selectedTab === TabToShow.Discover ? '' : 'opacity-50'}`}
          onClick={() => {
           
            capture({
              event: 'page_open',
              data: {
                page_name: 'Main',
              },
            });
            onTabSelect(TabToShow.Discover);
            window.history.pushState(null, 'Main', '/');
          }}
          style={{ width: '60px' }}
        >
          <Icon src={IconSrc.Magic} className="mb-2" size={IconSize.Smaller} />
          <div>{t('Discover')}</div>
        </motion.div> 
        <div className="px-4">
          <CharacterGenerationControl />
        </div>
          <motion.div
          whileTap={{ scale: 0.8 }}
          className={`d-flex flex-column align-items-center cursor-pointer ${selectedTab === TabToShow.Connections ? '' : 'opacity-50'}`}
          onClick={() => {
            if (user?.IsAnonymous) {
              return    addModal({
                children: <Authorization />,
                className: 'horny-bg-gradient_red',
              });
             }
            if (user) {
              window.history.pushState(null, 'Connections', '/connections');

              onTabSelect(TabToShow.Connections);
            } else {
              addModal({
                children: <Authorization />,
                className: 'horny-bg-gradient_red',
              });
            }
          }}
          style={{ width: '60px' }}
        >
          <div className="text-center">
            <Icon
              src={IconSrc.Community}
              className="mb-2"
              size={IconSize.Smaller}
            />
            <div>{t('Connections')}</div>
          </div>
        </motion.div> 
          <motion.div
          whileTap={{ scale: 0.8 }}
          className={`d-flex flex-column align-items-center cursor-pointer ${selectedTab === TabToShow.Invite ? '' : 'opacity-50'}`}
          onClick={() => {
            if (user?.IsAnonymous) {
              return    addModal({
                children: <Authorization />,
                className: 'horny-bg-gradient_red',
              });
             }
            capture({
              event: 'page_open',
              data: {
                page_name: 'Invite',
              },
            });
            onTabSelect(TabToShow.Invite);
            window.history.pushState(null, 'Invite', '/invite');
          }}
          style={{ width: '60px' }}
        >
          <Icon src={IconSrc.Invite} className="mb-2" size={IconSize.Smaller} />
          <div>{t('Invite')}</div>
        </motion.div>  
      </div>
    </div>
  );
}
