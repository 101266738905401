import { TopBot } from 'src/services/API/BotAPI';
import { TopBotCard } from './TopBotCard';
export type TopBotListProps = {
  topBots: TopBot[];
};
export function TopBotList({ topBots }: TopBotListProps) {
  //   topBots = topBots.map((b, i) => {
  //     if (i % 2 === 0) {
  //       b.IsOwnBot = true;
  //     }
  //     return b;
  //   });
  const highestOwnBotRank = Math.min(
    ...topBots.filter((tB) => !!tB.IsOwnBot).map((tB) => tB.Rank)
  );
  return (
    <div className="w-100 d-flex flex-column gap-4">
      {topBots.map((bot, i) => {
        return (
          <TopBotCard {...bot} {...{ highestOwnBotRank }} key={bot.BotId} />
        );
      })}
    </div>
  );
}
