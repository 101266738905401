import { motion } from 'framer-motion';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/shared/utils';

export type CategorySelectorProps = {
  categories: CategorySelectorElementProps[];
  onChange: (category: string) => any;
  variant: CategorySelectorVariant;
  size: CategorySelectorSize;
};

export type CategorySelectorElementProps = {
  title: string;
  count: number;
};

export type CategorySelectorVariant = 'first-row' | 'second-row';
export type CategorySelectorSize = 'sm' | 'base';

export default function CategorySelector({
  categories,
  onChange,
  variant,
  size,
}: CategorySelectorProps) {
  const { t } = useTranslation();
  const catElRefs = useRef<HTMLDivElement[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categories[0].title
  );
  const [selectedCatPosition, setSelectedCatPosition] = useState({
    x: 0,
    width: 0,
  });

  const calculateMenuPosition = (
    index: number,
    elementRefst: MutableRefObject<HTMLDivElement[]>
  ) => {
    const element = elementRefst.current[index];
    if (element && element.parentElement) {
      const rect = element.getBoundingClientRect();
      const containerRect = element.parentElement.getBoundingClientRect();
      return {
        x: rect.left - containerRect.left,
        width: rect.width,
      };
    }
    return null;
  };

  useEffect(() => {
    const initialCatPosition = calculateMenuPosition(0, catElRefs);
    if (initialCatPosition) {
      setSelectedCatPosition(initialCatPosition);
    }
  }, []);

  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-flex-row tw-gap-4 tw-font-bold  tw-border-b tw-border-horny-gray-600 tw-overflow-x-scroll tw-scrollbar-hide',
        variant === 'first-row' && 'tw-bg-horny-gray-900',
        variant === 'second-row' && 'tw-bg-horny-gray-800',
        size === 'sm' && 'tw-py-2 tw-px-4 tw-text-sm',
        size === 'base' && 'tw-p-4'
      )}
    >
      {categories.map((cat, index) => (
        <motion.div
          ref={(el) => {
            if (el) catElRefs.current[index] = el;
          }}
          className={cn(
            'tw-flex tw-flex-row tw-gap-2 tw-items-center',
            cat.title !== selectedCategory
              ? 'tw-opacity-50 tw-cursor-pointer'
              : 'tw-pointer-events-none'
          )}
          onClick={() => {
            setSelectedCatPosition(
              calculateMenuPosition(index, catElRefs) || {
                x: 0,
                width: 0,
              }
            );
            setSelectedCategory(cat.title);
            onChange(cat.title);
          }}
          whileTap={{ scale: 0.9 }}
        >
          <div className='tw-text-nowrap'>{t(cat.title)}</div>
          <div className="tw-rounded-full tw-bg-horny-gray-600 tw-px-1 tw-text-xxs tw-opacity-50">
            {cat.count}
          </div>
        </motion.div>
      ))}
      {/* <motion.div
        className="tw-absolute tw-bottom-0 tw-left-0 tw-text-horny-gray-600 tw-h-fit"
        initial={false}
        animate={{
          x: selectedCatPosition.x,
          width: selectedCatPosition.width,
        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        <div
          className={cn(
            'tw-bg-horny-gray-600 tw-rounded-full tw-absolute tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2',
            size === 'sm' && 'tw-w-2 tw-h-2',
            size === 'base' && 'tw-w-4 tw-h-4'
          )}
          style={{ clipPath: 'inset(0 0 50% 0)' }}
        />
      </motion.div> */}
    </div>
  );
}
