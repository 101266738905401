import axios, { AxiosError } from 'axios';
import { ReactElement, useRef, useState } from 'react';
// import { NotificationType } from 'src/components/Layout/Notification/Notification';
import { Auth } from '@supabase/auth-ui-react';
import { AuthTokenResponsePassword } from '@supabase/supabase-js';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import LegalDocs from 'src/components/Shared/LegalDocs';
import { ShowSubscriptionsReason } from 'src/components/Subscription/Subscriptions';
import { appConfig } from 'src/config/app';
import { emailRegex } from 'src/config/utils';
import {
  captureMyTracker,
  captureTrafficJunky,
  useAnalyticsContext,
} from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  addTrafficJunkyReg,
  addTrafficStarTag,
} from 'src/context/AnalyticsContext/helpersDOM';
import { useModalContext } from 'src/context/Modal.Context';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { useUserContext } from 'src/context/User.context';
import { agreementStoreKey } from 'src/decorators/Agreement.Decorator';
import { UserAPI } from 'src/services/API/UserAPI';
import { getAxiosInstance, getAxiosInstanceV2 } from 'src/services/axios/axios';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
import {
  AuthErrorBlock,
  AuthorizationError,
  AuthorizationInput,
  AuthorizationStep,
  HornyAuthorizationProps,
  getAuthorizationInputError,
} from './HornyAuthorization';
// import { IconSrc, getIconUrl } from 'src/components/Shared/Icon/Icon';

const supaBaseClient = getSupabaseClient();

export function SignInUp(props: HornyAuthorizationProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { capture } = useAnalyticsContext();

  const emailInput = useRef<HTMLInputElement | null>(null);
  const passwordInput = useRef<HTMLInputElement | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const { user, setUser } = useUserContext();

  const { addNotification } = useNotificationsContext();
  const [notification, setNotification] = useState('');
  const [inputError, setInputError] = useState<AuthorizationError>();
  const posthog = usePostHog();
  const { closeTopModal } = useModalContext();
  const checkInputs = () => {
    const emailValue = emailInput.current?.value;
    const passwordValue = passwordInput.current?.value;

    if (!emailRegex.test(emailValue!)) {
      setInputError({
        input: AuthorizationInput.Email,
        error: getAuthorizationInputError(t('Please provide a valid email')),
      });
      return false;
    }
    if (passwordValue!.length < 8) {
      setInputError({
        input: AuthorizationInput.Password,
        error: getAuthorizationInputError(
          t('Password must have at least 8 characters')
        ),
      });
      return false;
    }
    return true;
  };

  const signUp = () => {
    if (isFetching || !checkInputs()) {
      return;
    } else {
      setIsFetching(true);
    }
    UserAPI.checkUser(email)
      .then(async () => {
        return await axios
          .post(
            appConfig.supabase.host + '/auth/v1/signup',
            {
              email,
              password,
              data: {},
              gotrue_meta_security: {},
              code_challenge: null,
              code_challenge_method: null,
            },
            {
              headers: {
                Apikey: appConfig.supabase.publicKey,
                Authorization: 'Bearer ' + appConfig.supabase.publicKey,
                'Content-Type': 'application/json',
                'Sec-Fetch-Mode': 'cors',
                'Sec-Fetch-Site': 'cross-site',
              },
              withCredentials: false,
            }
          )

          .then((res) => res.data)
          .then(
            async (
              res: Pick<AuthTokenResponsePassword['data'], 'user'> &
                AuthTokenResponsePassword['data']['session']
            ) => {
              if (!res.access_token || !res.user) {
                return setNotification(
                  t('Bad credentials or an error occured.')
                );
              }

              getAxiosInstance().defaults.headers.authorization =
                'Bearer ' + res.access_token;

              const { data: userResponse } = await UserAPI.get();

              await addTrafficStarTag();
              await addTrafficJunkyReg(res.user.id);
              captureTrafficJunky({
                name: 'Reg',
                type: 'Acquisition',
                value: '0.001',
              });

              posthog.identify(res.user.id, {
                email: email || null,
              });

              captureMyTracker({ type: 'setUserID', userid: res.user.id });

              captureMyTracker({
                type: 'reachGoal',
                goal: 'mt_login',
                userid: res.user.id,
              });

              capture({
                event: 'login',
                data: {
                  premium_status: userResponse.MergedSubscription.Level,
                  app_npm_version: appConfig.app.version,
                  Library: 'web',
                  app_platform: 'web',
                  agreement_version:
                    localStorage.getItem(agreementStoreKey) || null,
                },
              });
              await supaBaseClient.auth.setSession({
                access_token: res.access_token,
                refresh_token: res.refresh_token,
              });
              window.location.reload();
            }
          )
          .catch((e: AxiosError<Record<string, string>>) => {
            setNotification(
              e.response!.data!.error_description ||
                e.response?.data.msg ||
                t(
                  'Error occured with this email. Maybe it eas alreay in use. Please try another.'
                )
            );
          })
          .finally(() => setIsFetching(false));
      })
      .catch((e: AxiosError<Record<string, string>>) => {
        if (e.response?.status === 409) {
          setNotification(
            t(
              'You have already succesfully joined our community. Please click Sign'
            )
          );
          // addNotification({
          //   type: NotificationType.Error,
          //   message:
          //     "You have already succesfully joined our community; ;). Please click 'Sign In' button to return to your account.",
          //   timestamp: Date.now(),
          // });
        } else {
          if (
            e.response?.status &&
            e.response?.status >= 400 &&
            e.response?.status < 500
          ) {
            setNotification(
              t('Bad or alreay registered email = (. Please try another')
            );
          }
        }
      })
      .finally(() => setIsFetching(false));
  };

  const setUpUser = () => {
    if (isFetching || !checkInputs()) {
      return;
    } else {
      setIsFetching(true);
    }
    UserAPI.checkUser(email)
      .then(async () => {
        return await UserAPI.signUpAnonUser({
          Email: email,
          Password: password,
        })
          // .then(() => supaBaseClient.auth.updateUser({
          //   password
          // }))

          // return await axios.post(appConfig.supabase.host + '/auth/v1/signup', {
          //     email, password,
          //     data: {},
          //     "gotrue_meta_security":{},
          //     "code_challenge":null,
          //     "code_challenge_method":null
          //   }, {
          //   headers: {
          //     'Apikey': appConfig.supabase.publicKey,
          //     'Authorization': 'Bearer ' + appConfig.supabase.publicKey,
          //     'Content-Type': 'application/json',
          //     'Sec-Fetch-Mode': 'cors',
          //     'Sec-Fetch-Site': 'cross-site',
          //   },
          //   withCredentials: false,
          // })

          // .then((res) => res.data)
          .then(async ({ data: userResponse }) => {
            // supaBaseClient.auth
            // .updateUser({ password })
            // .then(res => {
            //   console.log({res});

            // })
            // .catch(console.error);
            // if (result.error){
            //   return setNotification(result.error.message)
            // }

            // getAxiosInstance().defaults.headers.authorization = 'Bearer ' + res.access_token;

            // const {data: userResponse} = await UserAPI.get();

            posthog.identify(userResponse.UserId, {
              email: email || null,
            });

            captureMyTracker({
              type: 'setUserID',
              userid: userResponse.UserId,
            });

            // captureMyTracker({
            //   type: 'reachGoal',
            //   goal: 'mt_login',
            //   userid: res.user.id,
            // });

            capture({
              event: 'login',
              data: {
                premium_status: userResponse.MergedSubscription.Level,
                app_npm_version: appConfig.app.version,
                Library: 'web',
                app_platform: 'web',
                agreement_version:
                  localStorage.getItem(agreementStoreKey) || null,
              },
            });

            await UserAPI.get().then(({ data }) => {
              setUser(data);
            });
            // setUser(userResponse);
            await supaBaseClient.auth.signInWithPassword({
              email,
              password,
            });
            closeTopModal();
          })
          .catch((e: AxiosError<Record<string, string>>) => {
            setNotification(
              e.response!.data!.error_description ||
                e.response?.data.msg ||
                t(
                  'Error occured with this email. Maybe it eas alreay in use. Please try another.'
                )
            );
          })
          .finally(() => setIsFetching(false));
      })
      .catch((e: AxiosError<Record<string, string>>) => {
        if (e.response?.status === 409) {
          setNotification(
            t(
              'You have already succesfully joined our community. Please click Sign'
            )
          );
          // addNotification({
          //   type: NotificationType.Error,
          //   message:
          //     "You have already succesfully joined our community; ;). Please click 'Sign In' button to return to your account.",
          //   timestamp: Date.now(),
          // });
        } else {
          if (
            e.response?.status &&
            e.response?.status >= 400 &&
            e.response?.status < 500
          ) {
            setNotification(
              t('Bad or alreay registered email = (. Please try another')
            );
          }
        }
      })
      .finally(() => setIsFetching(false));
  };
  // const [error, setError] =
  const signIn = () => {
    if (isFetching || !checkInputs()) {
      return;
    } else {
      setIsFetching(true);
    }
    axios
      .post(
        appConfig.supabase.host + '/auth/v1/token?grant_type=password',
        {
          email,
          password,
          gotrue_meta_security: {},
        },
        {
          headers: {
            Apikey: appConfig.supabase.publicKey,
            Authorization: 'Bearer ' + appConfig.supabase.publicKey,
            'Content-Type': 'application/json',
            'Sec-Fetch-Mode': 'cors',
            'Sec-Fetch-Site': 'cross-site',
          },
          withCredentials: false,
        }
      )
      .then((res) => res.data)
      .then(
        async (
          res: Pick<AuthTokenResponsePassword['data'], 'user'> &
            AuthTokenResponsePassword['data']['session']
        ) => {
          if (!res.access_token || !res.user) {
            return setNotification(t('Bad credentials or an error occured.'));
          }

          getAxiosInstance().defaults.headers.authorization =
            'Bearer ' + res.access_token;
          getAxiosInstanceV2().defaults.headers.authorization =
            'Bearer ' + res.access_token;

          const { data: userResponse } = await UserAPI.get();

          posthog.identify(res.user.id, {
            email: email || null,
          });

          captureMyTracker({ type: 'setUserID', userid: res.user.id });

          captureMyTracker({
            type: 'reachGoal',
            goal: 'mt_login',
            userid: res.user.id,
          });

          capture({
            event: 'login',
            data: {
              premium_status: userResponse.MergedSubscription.Level,
              app_npm_version: appConfig.app.version,
              Library: 'web',
              app_platform: 'web',
              agreement_version:
                localStorage.getItem(agreementStoreKey) || null,
            },
          });

          await supaBaseClient.auth.setSession({
            access_token: res.access_token,
            refresh_token: res.refresh_token,
          });

          if (user?.UserId !== userResponse.UserId) {
            window.location.href = '';
            return;
          }
          setUser(userResponse);
          closeTopModal();
        }
      )
      .catch((e: AxiosError<Record<string, string>>) => {
        setNotification(t(e.response!.data!.error_description as any));
      })
      .finally(() => setIsFetching(false));
  };

  const timerText = {
    [ShowSubscriptionsReason.Messages]: (
      <span>
        {t('No free messages left')}{' '}
        <Icon src={IconSrc.Chat} size={IconSize.XSmall} /> &nbsp;{' '}
        {t('Sign up to get more')}.
      </span>
    ),
    [ShowSubscriptionsReason.Energy]: (
      <div className="d-flex align-items-center">
        {t('Too few energy')}
        <Icon src={IconSrc.Energy} size={IconSize.XSmall} /> &nbsp;{' '}
        {t('Sign up to get more')}.
      </div>
    ),
    [ShowSubscriptionsReason.CommunityMedia]: (
      <div className="d-flex align-items-center">
        {t('Subscribe to see community media')}
      </div>
    ),
    [ShowSubscriptionsReason.Upgrade]: (
      <div className="d-flex align-items-center">{t('Upgrade required')}</div>
    ),
    [ShowSubscriptionsReason.Premium]: (
      <div className="d-flex align-items-center">{t('Premium required')}</div>
    ),
    [ShowSubscriptionsReason.PrivateBot]: (
      <div className="d-flex align-items-center">{t('Premium of at least Boyfriend level required')}</div>
    ),
  };

  const anonymousSignUpBlock = (content?: ReactElement) => {
    return (
      <div className="horny-bg_grey  rounded-4 p-6 text-white m-auto d-flex flex-column gap-2 justify-content-between horny-bg_grey_opac mb-3">
        <div className="d-flex justify-content-center">
          {content ? (
            <div className="d-flex align-items-center gap-1">{content} </div>
          ) : (
            <div className="d-flex align-items-center gap-2 flex-column">
              <div>{t('Sign up and get more')} </div>
              <div>
                <Icon src={IconSrc.Energy} size={IconSize.XSmall} />{' '}
                {t('Energy')} {t('and')}{' '}
                <Icon src={IconSrc.Chat} size={IconSize.XSmall} />{' '}
                {t('Messages')}{' '}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-100 d-flex flex-column gap-3 align-items-center justify-content-start   mx-auto my-20">
      {isFetching && <FullSizeLoader />}
      <div className="mb-5">
        <img
          src=""
          style={{ width: 0, height: 0, position: 'absolute' }}
          onError={() => {
            capture({
              event: 'page_open',
              data: {
                page_name: 'Authorization',
              },
            });
          }}
        />
        <div className="fs-2 fw-bold">
          {user && user.IsAnonymous ? t('Sign up to continue') : t('Join us')}
        </div>

        <div style={{ maxWidth: 400 }} className="tw-flex tw-flex-col tw-gap-3">
          {t(
            'By pressing the button “Sign up”, “Sign In“ or social authorizarion buttons, you agree to all the documents below and confirm that you are 18 years old or older.'
          )}
          {/* <Link url="https://temptationgames.com/privacy-policy">
            {t('Privacy Policy')}
          </Link>{' '}
          {t('and')}{' '}
          <Link url="https://temptationgames.com/terms-of-service">
            {t('Terms of Service')}
          </Link>{' '}
          {t(
            'Thanks for reading everything carefully. Also you confirm that you are 18 years old or older'
          )} */}
          <LegalDocs align="left" />
        </div>
      </div>
      <Auth
        supabaseClient={supaBaseClient}
        socialLayout={'horizontal'}
        onlyThirdPartyProviders={true}
        providerScopes={{}}
        view="sign_up"
        appearance={{
          // If you want to extend the default styles instead of overriding it, set this to true
          //  extend: false,
          // Your custom classes
          className: {
            // divider: 'mt-2 mb-2 m-auto text-center ',
            container: 'mx-auto text-center test mt-3 mb-3 d-flex   ',
            input:
              'w-100 horny-input horny-input_authorization  horny-bg-gradient_orange_light rounded-5 horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange form-control',
            // label: 'm-auto',
            // anchor: 'd-block text-white mb-2 text-center',
            button:
              'authorization_button_social m-auto gap-3   horny-mw_control horny-shadow horny-bg-gradient_orange rounded-5 fs-5  border-0 text-left  btn btn-primary',
            //..
          },
        }}
        providers={['google', 'discord']}
        redirectTo={window.location.origin + '/auth-redirect'}
      />
      <div className="w-100">
        {notification && <AuthErrorBlock errorMessage={notification} />}
        {user && user.IsAnonymous
          ? props.showReason
            ? anonymousSignUpBlock(timerText[props.showReason])
            : anonymousSignUpBlock()
          : null}
        <div className="w-100 mb-2">{t('Your email')}</div>
        <input
          ref={emailInput}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          autoComplete="email"
          id="email"
          placeholder={t('Enter your email')}
          className="w-100 horny-input horny-input_authorization  horny-bg-gradient_orange_light rounded-5 horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange form-control"
        />
        <div className="text-center">
          {inputError &&
            inputError.input === AuthorizationInput.Email &&
            inputError.error}
        </div>
      </div>

      <div className="w-100">
        <div className="w-100 mb-2">{t('Your password')}</div>
        <input
          ref={passwordInput}
          placeholder={t('Enter your password')}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          id="password"
          autoComplete="password"
          className="w-100 horny-input horny-input_authorization  horny-bg-gradient_orange_light rounded-5 horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange form-control"
        />
        <div className="text-center">
          {inputError &&
            inputError.input === AuthorizationInput.Password &&
            inputError.error}
        </div>
      </div>
      <HornyButton
        bg={HornyButtonBG.Orange}
        height={HornyButtonHeight.Tall}
        onClick={() => signIn()}
        className="w-100 mw-100"
      >
        <div className="mx-auto">{t('Sign in')}</div>
      </HornyButton>
      <HornyButton
        bg={HornyButtonBG.Orange}
        height={HornyButtonHeight.Tall}
        onClick={() => (user && user.IsAnonymous ? setUpUser() : signUp())}
        className="w-100 mw-100"
      >
        <div className="mx-auto">{t('Sign up')}</div>
      </HornyButton>

      {/* <button onClick={() => signIn()}>sign in</button>
            <button onClick={() => signUp()}>sign up</button> */}
      <div>
        <u
          className="cursor-pointer fw-bold"
          onClick={() => props.goTo(AuthorizationStep.ForgotPassword)}
        >
          {t('Forgot your password?')}
        </u>
      </div>
    </div>
  );
}
