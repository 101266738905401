import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonHeight,
  HornyButtonBG,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc, IconSize, Icon } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';
import { ExampleMessage } from 'src/services/API/CharacterAPI';
// import { replaceUserName } from 'src/shared/helpers';
import {
  //formatText,
  focusEditableElement,
  //splitTextLines,
} from 'src/shared/utils';

export type MessageEditProps = {
  message: ExampleMessage;
  onChange: (content: string) => any;
};

export function MessageEdit({ message, onChange }: MessageEditProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [isEditing, setIsEditing] = useState(false);
  const editinRef = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState(message.content);
  // const [editing, setEditing] = useState(false);
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => focusEditableElement(editinRef), 100);
    }
  }, [isEditing]);

  useEffect(() => {
    if (message.content !== content) {
      setContent(message.content);
    }
  }, [message.content]);

  // const replacedText = replaceUserName(content, user!);

  return (
    <div
      className={`d-flex justify-content-${message.name === 'example_user' ? 'end' : 'start'}`}
    >
      <div style={{ maxWidth: '75%' }}>
        <div
          className={`my-3 chat_message chat_message_${message.name === 'example_user' ? 'user' : 'assistant'}`}
          ref={editinRef}
          contentEditable={isEditing ? 'true' : 'false'}
          // onInput={(e) => {
          //   const target = e.target as HTMLDivElement;
          //   //  setContent(target.innerText);
          //   onChange(target.innerText);
          // }}
        >
          <p className="mb-0" style={{ whiteSpace: 'pre-line' }}>
            {
              /* {message.name === 'example_user'
              ? splitTextLines(content)
              : formatText(content)} */ content
            }
          </p>
        </div>
        <HornyButton
          height={HornyButtonHeight.Short}
          shadow="horny-shadow-red-with-orange"
          onClick={() => {
            if (isEditing) {
              if (editinRef.current) {
                onChange(editinRef.current.textContent || '');
              }
            }
            setIsEditing(!isEditing);
          }}
          bg={HornyButtonBG.Orange}
        >
          <div>
            <Icon
              src={isEditing ? IconSrc.Save : IconSrc.Edit}
              size={IconSize.Small}
            />
          </div>
          <div>{isEditing ? t('Save') : t('Edit')}</div>
          <div></div>
        </HornyButton>
      </div>
    </div>
  );
}
