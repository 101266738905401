import { motion } from 'framer-motion';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { MediaAPI } from 'src/services/API/MediaAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { HornyButton } from '../Shared/HornyButton/HornyButton';
import { ReportProps, processReasons } from './shared';
import { useTranslation } from 'react-i18next';

export function ReportMedia({ Id, onReport }: ReportProps) {
  const { t } = useTranslation();
  const limitInputLength = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    console.log({ e: (e.target as HTMLSpanElement).innerText });
    // if (handleInputRef.current) {
    if (
      e.key !== 'Backspace' &&
      (e.target as HTMLSpanElement).innerText.length >= 32
    ) {
      e.preventDefault();
      return false;
    }
    // }

    return true;
  };

  const reportMedia = () => {
    if (!reasons.length) {
      return;
    }
    MediaAPI.reportMedia({
      MediaId: Id,
      ReportedItems: reasons,
      Message: message,
    }).then(() => onReport());
  };

  const allowedReasons = [
    t('Inappropriate media'),
    t('Inconsistent / bad image generation'),
  ];
  const [reasons, setReasons] = useState<string[]>([]);
  const [message, setMessage] = useState('');
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div
          className="px-6 py-11 d-flex flex-column gap-4  horny-bg_grey_opac rounded-3 text-white w-100 horny-shadow-gray"
          style={{ maxWidth: 600 }}
        >
          <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-3 fw-bold">{t('Report media')}</span>
          </div>
          <div className="">
            {t('Tell us about why you gonna report this media or such.')}
          </div>

          <div className="d-flex flex-column gap-4">
            <Form
              style={{
                color: 'white !important',
              }}
            >
              {allowedReasons.map((reason) => (
                <div
                  key={reason}
                  className="mb-3 text-white d-flex gap-2 align-items-center"
                >
                  <Form.Check // prettier-ignore
                    type="switch"
                    id={'custom-switch-' + reason}
                    // className='text-white'

                    //  className='horny-bg-gradient_orange_light horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange'
                    // id={`default-checkbox`}
                    // label={reason}
                    className="horny-toggle"
                    onChange={(e) =>
                      setReasons(
                        processReasons(reasons, reason, e.target.checked)
                      )
                    }
                  />
                  <div>{reason}</div>
                </div>
              ))}
            </Form>
            <input
              className="horny-input horny-bg-gradient_orange_light rounded-5 horny-shadow-just-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange"
              style={{ height: 40, width: 250, maxWidth: '75%' }}
              placeholder={t('Tell us what you think')}
              onChange={(e) => setMessage(e.target.value)}
            />
            <HornyButton
              {...(!reasons.length ? { disabled: true } : {})}
              onClick={() => reportMedia()}
            >
              {t('Report media')}
            </HornyButton>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
