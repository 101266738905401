import {
  HornyNutakuSDK,
  createPaymentOptions,
  PaymentEvent,
} from 'hornynutakusdk';

export class Nutaku {
  static getUserId() {
    return HornyNutakuSDK.getNutakuUserId();
  }

  static createPayment(options: createPaymentOptions) {
    return HornyNutakuSDK.createPayment(options);
  }

  static addListener(
    eventName: string,
    listenerFunc: (info: PaymentEvent) => void
  ) {
    return HornyNutakuSDK.addListener(eventName, listenerFunc);
  }

  static logout() {
    return HornyNutakuSDK.nutakuLogout();
  }
}

export type nutakuCreatePaymentOptions = createPaymentOptions;

export type nutakuPaymentEvent = PaymentEvent;
