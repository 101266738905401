// import { Image } from "react-bootstrap";
import { Image } from 'react-bootstrap';
import './index.css';
import { appConfig } from 'src/config/app';

export type SwipeCharactersProps = {
  onTouch: () => any;
};

export function SwipeCharacters({ onTouch }: SwipeCharactersProps) {
  const pointerImg = (
    <Image
      src={appConfig.host.CDN + '/img/tutorial/pointer-svg.png'}
      className="position-absolute horny-opacity-90"
      style={{
        width: 32,
        height: 32,
        animationDelay: '6s',
        animation: ' move 3s infinite ease-out',
      }}
    />
  );

  const pointer = (
    <div
      className="position-absolute"
      style={{
        // transform: 'rotateY(0deg) rotate(-45deg)',
        bottom: 0,
        left: 5,
      }}
    >
      {pointerImg}
    </div>
  ); //<Image src={pointerImageSrc} />
  const slideTrack = (
    <div
      style={{
        width: 10,
        height: 150,
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
      }}
      className="swipe-characters_track"
    ></div>
  );

  return (
    <div
      className="w-100 h-100 z-3  position-absolute  bg-black opacity-75 pe-none"
      onClick={() => onTouch()}
    >
      <div className="position-fixed z-3 swipe-characters">
        {slideTrack}
        {pointer}
      </div>
    </div>
  );
}
