import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
import { focusEditableElement } from 'src/shared/utils';

export function UserDelete() {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const handleInputRef = useRef<HTMLSpanElement>(null);
  const { setUser } = useUserContext();
  const { capture } = useAnalyticsContext();
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => focusEditableElement(handleInputRef), 100);
    }
  }, [isEditing]);
  const deleteUser = () => {
    if (
      handleInputRef.current &&
      handleInputRef.current.innerText === 'delete' &&
      !isDeleting
    ) {
      setIsDeleting(true);
      UserAPI.deleteUser()
        .then(() => {
          capture({ event: 'account_deletion', data: {} });
          const supaBaseClient = getSupabaseClient();
          supaBaseClient.auth.signOut().finally(() => {
            // setUser(null);
            window.location.reload();
          });
        })
        .catch(() => {
          setIsDeleting(false);
        });
    }
  };

  const limitInputLength = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    console.log({ e });
    if (handleInputRef.current) {
      if (
        e.key !== 'Backspace' &&
        handleInputRef.current.innerText.length >= 32
      ) {
        e.preventDefault();
      }
    }

    return true;
  };

  return (
    <div className="h-100 d-flex justify-content-center px-2 min-dvh-100">
      <div className="px-6 py-11 d-flex flex-column gap-4 align-self-center horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
        <div className="fs-3 mb-2 fw-bold">{t('Delete account')}</div>

        <div className="d-flex">
          <div className="w-75">
            <Trans
              i18nKey="deleteAccountPrompt"
              components={[<i className="text-danger fw-bold" />, <i />]}
            />
            {/* If you want to delete your account, please type{' '}
            <i className="text-danger fw-bold">delete</i> in the field below and
            press <i>Delete account</i>.<br /> We will always miss you. */}
          </div>
        </div>
        <div className="d-flex align-items-center gap-5">
          <div className="  ">
            <div
              className="py-1  px-4 text-white rounded-4 horny-bg-dark_opac"
              style={{ maxWidth: 150 }}
            >
              {!isEditing ? (
                <span
                  className="d-inline-block"
                  onClick={() => setIsEditing(true)}
                  style={{ minWidth: 80 }}
                >
                  <span className="opacity-50">
                    <Trans i18nKey="type delete" components={[<i />]} />
                    {/* type <i>delete...</i> */}
                  </span>
                </span>
              ) : (
                <span
                  className="d-inline-block"
                  contentEditable={'true'}
                  onPaste={(e) => e.preventDefault()}
                  style={{ minWidth: 80 }}
                  ref={handleInputRef}
                  onKeyDown={(e) => limitInputLength(e)}
                ></span>
              )}
            </div>
          </div>
          <div className="fw-bold">
            <u
              className="text-danger cursor-pointer"
              onClick={() => deleteUser()}
            >
              {t('Delete account')}
            </u>
          </div>
        </div>
      </div>
    </div>
  );
}
