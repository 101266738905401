import { UpgradeAPIModel } from '@temptations-ai/types';
import { APIBase } from './APIBase';

export type NutakuShareMedia = {
  Url: string;
  Username: string;
  LikesCount: number;
};

export type NutakuShareBot = {
  BotId: string;
  Name: string;
  Url: string;
  Story: string;
  Username: string;
  LikesCount: number;
};

export type NutakuShareResponse = {
  Bot: NutakuShareBot;
  Media: NutakuShareMedia | null;
  Rewards: {
    Type: string;
    Messages: number;
    Energy: number;
  };
};

export type NutakuShareRequest = {
  BotId: string | null;
  MediaId: string | null;
};

export class NutakuAPI extends APIBase {
  static completeNutakuPurchase(PaymentId: string) {
    return this.clientV2.post<any>('v1/nutaku/product', {
      PaymentId: PaymentId,
    });
    // return this.client.post<any>('nutakubuyproductreact', {
    //   PaymentId: PaymentId,
    // });
  }

  static getNutakuShareData(params: NutakuShareRequest) {
    return this.client.get<NutakuShareResponse>('nutakugetsharedata', {
      params,
    });
  }

  static getNutakuPrice(level: string) {
    return this.clientV2.get<{ price: number; price_year: number }>(
      'v1/nutaku/price',
      {
        params: {
          Level: level,
        },
      }
    );
  }

  static getUpgrades() {
    return this.clientV2.get<UpgradeAPIModel[]>('v1/upgrades');
  }
}
