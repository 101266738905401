import { motion } from 'framer-motion';
import { useState } from 'react';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import {
  AnimParamNutakuCopy,
  TransTweenAnticipate05,
} from 'src/shared/animations';
import { Icon, IconSize, IconSrc } from '../Icon/Icon';
import './index.css';
import { useTranslation } from 'react-i18next';

export type CopyCodeProps = {
  code: string;
  textClass?: string;
};

export function CopyCode({ code, textClass = 'fs-2' }: CopyCodeProps) {
  const { t } = useTranslation();
  const [showCopied, setShowCopied] = useState(false);
  const { nutakuEnv } = useNutakuContext();
  const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str);
  };
  return (
    <div className="copy-code-block d-flex position-relative">
      <div className="copy-code-copied-text">
        <motion.div
          className="z-10 fw-bold"
          initial="initial"
          animate={showCopied ? 'in' : 'initial'}
          variants={AnimParamNutakuCopy}
          transition={TransTweenAnticipate05}
          onAnimationComplete={() => setShowCopied(false)}
        >
          {t('Code Copied')}
        </motion.div>
      </div>
      <div
        className={`pt-3 pb-3 ps-5 pe-5 w-100 ${textClass} fw-bold d-flex align-items-center justify-content-center`}
      >
        {code}
      </div>
      {nutakuEnv !== NutakuEnv.Web && (
        <motion.div
          className="copy-code-copy-button d-flex align-items-center justify-content-center ps-7 pe-7 cursor-pointer"
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setShowCopied(true);
            copyToClipboard(code);
          }}
        >
          <Icon src={IconSrc.Copy} size={IconSize.Medium} />
        </motion.div>
      )}
    </div>
  );
}
