import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { Icon, IconSize, IconSrc } from '../Icon/Icon';
import './index.css';

export type AddBlockProps = {
  className?: string;
  children?: ReactElement<any, any>;
  icon?: IconSrc;
  onClick?: (...args: any[]) => any;
};

export function AddBlock({
  children,
  icon,
  className = '',
  onClick,
}: AddBlockProps) {
  return (
    <motion.div
      whileTap={{ scale: 0.8 }}
      className={`add-block horny-border_100 max-w-max ${className}`}
      onClick={onClick}
    >
      <div className="d-flex horny-gap_tiny justify-content-center align-items-center">
        {icon && (
          <Icon className={`add-block_icon`} src={icon} size={IconSize.Tiny} />
        )}
        {children && children}
      </div>
    </motion.div>
  );
}
