import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';

export function SubscribeStarLanding() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const codeIsProcessing = useRef(false);
  const { user } = useUserContext();
  useEffect(() => {
    if (codeIsProcessing.current || !user) {
      return;
    }
    codeIsProcessing.current = true;
    code && UserAPI.addSubscribeStarUser(code).then(() => window.close());
  });

  return <div>landing</div>;
}
