import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { ImageGenerationModel, maxCustomPrompts } from 'src/@types/common';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { cn } from 'src/shared/utils';
import { usePromptPickerStore } from '../PromptPickerStore';
import CategorySelector, {
  CategorySelectorElementProps,
} from './CategorySelector';
import CustomPromptElement from './CustomPromptElement';

export type CustomPromptTabProps = {
  onChange: (customPrompts: string[]) => any;
  model: ImageGenerationModel;
};

export default function CustomPromptTab({
  model,
  onChange,
}: CustomPromptTabProps) {
  const { t } = useTranslation();
  const promptPickerStore = usePromptPickerStore();
  const { mediaPrompts } = useResourcesContext();
  const serverCustomPrompts = mediaPrompts?.CustomPrompts[model]!;
  const categories: CategorySelectorElementProps[] = Object.keys(
    serverCustomPrompts
  ).map((category) => ({
    title: category,
    count: Object.keys(serverCustomPrompts[category]).length,
  }));
  const initialCatgeory = categories[0].title;
  const initialSubcategory = Object.keys(
    serverCustomPrompts[initialCatgeory]
  )[0];
  const [selectedCategory, setSelectedCategory] =
    useState<string>(initialCatgeory);
  const [subcategories, setSubcategories] = useState<
    CategorySelectorElementProps[]
  >(
    Object.keys(serverCustomPrompts[initialCatgeory]).map((subcategory) => ({
      title: subcategory,
      count: serverCustomPrompts[initialCatgeory][subcategory].length,
    }))
  );
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<string>(initialSubcategory);
  const [customPrompts, setCustomPrompts] = useState<string[]>(
    serverCustomPrompts[initialCatgeory][initialSubcategory]
  );
  // const [selectedElements, setSelectedElements] = useState<string[]>([]);

  const [isRemoving, setIsRemoving] = useState(false);

  const [isMaxPrompts, setIsMaxPrompts] = useState(false);

  const [filter, setFilter] = useState<string>('');

  const updateSubcategories = (category: string) => {
    setSelectedCategory(category);
    setSelectedSubcategory(Object.keys(serverCustomPrompts[category])[0]);
    const updatedSubcategories = Object.keys(serverCustomPrompts[category]).map(
      (subcategory) => ({
        title: subcategory,
        count: serverCustomPrompts[category][subcategory].length,
      })
    );
    setSubcategories(updatedSubcategories);
  };

  const updateCustomPrompts = () => {
    const categoryCustomPrompts =
      serverCustomPrompts[selectedCategory][selectedSubcategory];
    const filteredCustomPrompts = categoryCustomPrompts
      .filter((p) => !promptPickerStore.selectedCutromPrompts.includes(p))
      .filter((p) =>
        filter ? p.toLowerCase().includes(filter.toLowerCase()) : true
      );

    setCustomPrompts(filteredCustomPrompts);
  };

  const addSelectedCustomPrompt = (title: string) => {
    setIsRemoving(false);
    promptPickerStore.setSelectedCustomPrompts([
      ...promptPickerStore.selectedCutromPrompts,
      title,
    ]);
    setCustomPrompts((prev) => prev.filter((t) => t !== title));
  };

  const removeSelectedCustomPrompt = (title: string) => {
    setIsRemoving(true);
    if (
      serverCustomPrompts[selectedCategory][selectedSubcategory].includes(title)
    ) {
      setCustomPrompts((prev) => [...prev, title]);
    }
    promptPickerStore.setSelectedCustomPrompts(
      promptPickerStore.selectedCutromPrompts.filter((t) => t !== title)
    );
  };

  const selectedPromptsDiv = useRef<HTMLDivElement>(null);

  // scroll to bottom
  const scrollSelectedPromptsToBottom = () => {
    if (selectedPromptsDiv.current && !isRemoving) {
      selectedPromptsDiv.current.scrollTo({
        top: selectedPromptsDiv.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const checkMaxPrompts = () => {
    if (promptPickerStore.selectedCutromPrompts.length > maxCustomPrompts) {
      setIsMaxPrompts(true);
    } else {
      setIsMaxPrompts(false);
    }
  };

  useEffect(() => {
    scrollSelectedPromptsToBottom();
    checkMaxPrompts();
  }, [promptPickerStore.selectedCutromPrompts]);

  useLayoutEffect(
    () => updateCustomPrompts(),
    [
      selectedSubcategory,
      selectedCategory,
      filter,
      promptPickerStore.selectedCutromPrompts,
    ]
  );

  return (
    <div className="tw-flex-grow tw-flex tw-flex-col tw-overflow-hidden tw-bg-horny-gray-900 tw-scrollbar-hide pt-3 tw-gap-4">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-px-3">
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-flex tw-flex-row gap-3 tw-items-center">
            <h3 className="tw-text-lg tw-font-bold">{t('Custom Prompts')}</h3>
            <div
              className={cn(
                'tw-text-sm tw-font-bold',
                isMaxPrompts
                  ? 'tw-text-horny-red-600'
                  : 'tw-text-horny-gray-400'
              )}
            >
              {promptPickerStore.selectedCutromPrompts.length} /{' '}
              {maxCustomPrompts}
            </div>
          </div>
          <motion.div
            className="tw-text-sm tw-opacity-50 tw-flex tw-flex-row tw-gap-1 tw-items-center tw-font-bold tw-cursor-pointer"
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              promptPickerStore.setSelectedCustomPrompts([]);
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} size="xs" />
            <div>{t('Clear')}</div>
          </motion.div>
        </div>

        <div
          className={cn(
            'tw-relative tw-flex tw-flex-wrap tw-gap-3 tw-border tw-rounded-md tw-p-4 tw-bg-horny-gray-800 tw-shadow tw-h-48 tw-overflow-y-scroll tw-scrollbar-hide tw-content-start',
            isMaxPrompts
              ? 'tw-border-horny-red-600'
              : 'tw-border-horny-gray-600'
          )}
          ref={selectedPromptsDiv}
        >
          {promptPickerStore.selectedCutromPrompts.map((title) => (
            <CustomPromptElement
              key={title + '_selected'}
              title={title}
              onClick={() => {
                removeSelectedCustomPrompt(title);
              }}
              type="selected"
            />
          ))}
          {promptPickerStore.selectedCutromPrompts.length === 0 && (
            <div className="tw-absolute tw-flex tw-items-center tw-justify-center tw-gap-2 tw-font-bold tw-text-xl tw-inset-0 tw-opacity-25 p-4">
              <div>{t('Start adding prompts...')}</div>
            </div>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-flex-grow tw-overflow-hidden">
        <CategorySelector
          categories={categories}
          onChange={(category) => {
            updateSubcategories(category);
            setSelectedCategory(category);
          }}
          variant="first-row"
          size="sm"
        />
        <CategorySelector
          key={selectedCategory}
          categories={subcategories}
          onChange={(subcategory) => {
            setSelectedSubcategory(subcategory);
          }}
          variant="second-row"
          size="sm"
        />
        <div className="tw-px-4 tw-pt-4">
          <TextareaAutosize
            className="tw-align-top tw-bg-horny-gray-800 tw-w-full tw-rounded-md tw-resize-none tw-px-4 tw-py-2 tw-border tw-border-horny-gray-600 tw-shadow-emboss-gray tw-placeholder-horny-gray-100 tw-placeholder-opacity-50 tw-text-sm tw-text-horny-gray-100"
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            placeholder={t('Find custom prompt...')}
            maxRows={1}
          />
        </div>
        <div className="tw-relative tw-flex tw-flex-wrap tw-content-start tw-gap-x-3 tw-gap-y-3 tw-px-4 tw-py-4 tw-overflow-auto tw-h-full">
          {customPrompts.map((title) => (
            <CustomPromptElement
              key={title}
              title={title}
              onClick={() => {
                addSelectedCustomPrompt(title);
              }}
              type="unselected"
            />
          ))}
          {customPrompts.length === 0 && (
            <div className="tw-absolute tw-flex tw-items-center tw-justify-center tw-gap-2 tw-font-bold tw-text-xl tw-inset-0 tw-opacity-25 p-4">
              <div>{t('No more elements in this subcategory...')}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
