import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';

export type NoCopyPopupProps = {
  textToCopy: string;
  whatCopying: string;
};

export function NoCopyPopup({
  textToCopy = 'No text to Copy',
  whatCopying = 'sharing link',
}: NoCopyPopupProps) {
  const { t } = useTranslation();
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div className="px-6 py-11 d-flex flex-column gap-6 horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
          <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-2 fw-bold">{t('Copy')}</span>
          </div>
          <div>
            {t('Sorry, but convinient copying is unvaliable. Please copy the')}
            {' "'}
            {t(whatCopying as any)}
            {'"'} {t('manually')}.
          </div>
          <div className="copy-code-block d-flex position-relative">
            <div
              className={`pt-3 pb-3 ps-5 pe-5 w-100 fs-5 fw-bold d-flex align-items-center justify-content-center`}
            >
              {textToCopy}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
