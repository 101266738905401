import { useEffect, useMemo, useState } from 'react';
import {
  SubscriptionProviderFlowProps,
  SubscriptionProviderFlowStage,
} from './SubscriptionProviderFlow';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc, IconSize, Icon } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';

export function SubscribeStarProviderFlow(
  props: SubscriptionProviderFlowProps
) {
  const [stage, setStage] = useState<SubscriptionProviderFlowStage>(
    props.selectedProvider
      ? SubscriptionProviderFlowStage.ReadyToUpdate
      : SubscriptionProviderFlowStage.Init
  );
  const [refreshing, setRefreshing] = useState(false);
  const { setUser } = useUserContext();
  const storedSelected = useMemo(() => {
    return props.selectedProvider;
  }, [props.selectedProvider]);

  useEffect(() => {
    if (
      props.selectedProvider === true &&
      !storedSelected &&
      stage === SubscriptionProviderFlowStage.StartedPayment
    ) {
      setStage(SubscriptionProviderFlowStage.ReadyToUpdate);
    }
  }, [props.selectedProvider]);
  const refresh = () => {
    if (refreshing) {
      return false;
    }
    setRefreshing(true);
    UserAPI.get().then(({ data }) => {
      setUser(data);
      setRefreshing(false);
    });
  };

  const stageElement = {
    [SubscriptionProviderFlowStage.Init]: (
      <HornyButton
        onClick={() => {
          window.open(
            'https://www.patreon.com/checkout/TemptationsAI?rid=' +
              props.subscriptionId,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedPayment);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Short}
      >
        <Icon src={IconSrc.Discord} size={IconSize.Small} />
        Subscribe at SubscribeStar
      </HornyButton>
    ),

    [SubscriptionProviderFlowStage.StartedPayment]: (
      <HornyButton
        onClick={() => {
          window.open(
            `https://subscribestar.adult/oauth2/authorize?client_id=${props.subscriptionId}&redirect_uri=${encodeURIComponent(window.location.origin)}/subscribestar&response_type=code&scope=user.read`,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedVerification);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Short}
      >
        <Icon src={IconSrc.SubscribeStar} size={IconSize.Small} />
        Verify subscription
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.StartedVerification]: (
      <HornyButton
        onClick={() => refresh()}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Short}
      >
        <Icon
          src={IconSrc.Reload}
          size={IconSize.Small}
          className={`${refreshing ? 'horny-spin' : ''}`}
        />
        Refresh
      </HornyButton>
    ),
    [SubscriptionProviderFlowStage.ReadyToUpdate]: (
      <HornyButton
        onClick={() => {
          window.open(
            'https://www.patreon.com/checkout/TemptationsAI?rid=' +
              props.subscriptionId,
            '_blank'
          );
          setStage(SubscriptionProviderFlowStage.StartedVerification);
        }}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Short}
      >
        <Icon src={IconSrc.SubscribeStar} size={IconSize.Small} />
        Upgrade
      </HornyButton>
    ),
  };
  return stageElement[stage];
}
