import { motion } from 'framer-motion';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { InviteAPI, inviteGetInvite } from 'src/services/API/InviteAPI';
import { Platform } from 'src/services/API/ResourcesAPI';
import {
  AnimParamInOutOpacityYNeg100,
  AnimParamSmallScaleOpacity,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { getImgUrl } from 'src/shared/helpers';
import { CopyCode } from '../Shared/CopyCode/CopyCode';
import {
  HornyButton,
  HornyButtonHeight,
} from '../Shared/HornyButton/HornyButton';
import {
  Icon,
  IconSize,
  IconSrc,
  SubscriptionIcons,
} from '../Shared/Icon/Icon';

export type InviteProps = {
  active: boolean;
};

export function InviteRewards() {
  const { t } = useTranslation();
  const { prices } = useResourcesContext();
  const { isNutaku } = useNutakuContext();
  const filteredInviteRewards = prices?.InviteRewards.filter((reward) =>
    reward.Platform.includes(isNutaku ? Platform.Nutaku : Platform.Web)
  );
  return (
    <Container fluid="md">
      <Row className="fw-bold flex-table-row-header py-5">
        <Col className="flex-table-col">{t('Status')}</Col>
        <Col className="flex-table-col">{t('Points')}</Col>
      </Row>
      <Row className="flex-table-row py-5">
        <Col className="flex-table-col d-flex flex-row gap-3">
          <Icon src={IconSrc.Next} size={IconSize.Small} />
          <div>{t('Sign Up')}</div>
        </Col>
        <Col className="flex-table-col">-</Col>
      </Row>
      {filteredInviteRewards?.map((reward) => (
        <Row className="flex-table-row py-5">
          <Col className="flex-table-col d-flex flex-row gap-3">
            <Icon
              src={
                SubscriptionIcons[
                  reward.Title as keyof typeof SubscriptionIcons
                ]
              }
              size={IconSize.Small}
            />
            <div>{t(reward.Title as any)}</div>
            <div className="opacity-50">{t(reward.DaysAlias as any)}</div>
          </Col>
          <Col className="flex-table-col">{reward.Points}</Col>
        </Row>
      ))}
    </Container>
  );
}

export function InvitePurchases() {
  const { t } = useTranslation();
  const { prices } = useResourcesContext();
  const { user, setUser } = useUserContext();
  const { isNutaku } = useNutakuContext();
  const filteredInvitePrices = prices?.InvitePrices.filter((price) =>
    price.Platform.includes(isNutaku ? Platform.Nutaku : Platform.Web)
  );
  const [isInviteBuyFetching, setIsInviteBuyFetching] = useState(false);
  const [isInviteBuyFetched, setIsInviteBuyFetched] = useState<
    'in' | 'out' | null
  >(null);
  const [activeButtonId, setActiveButtonId] = useState<string | null>(null);

  const handleInviteBuy = (itemId: string) => {
    setActiveButtonId(itemId);
    setIsInviteBuyFetching(true);
    InviteAPI.inviteBuy({ ItemId: itemId })
      .then((res) => {
        setUser(res.data);
      })
      .finally(() => {
        setIsInviteBuyFetching(false);
        setIsInviteBuyFetched('in');
        setTimeout(() => {
          setIsInviteBuyFetched('out');
          setTimeout(() => {
            setIsInviteBuyFetched(null);
          }, 300);
        }, 1000);
      });
  };

  return (
    <div className="d-flex flex-column gap-6 w-100 horny-fit-content">
      {filteredInvitePrices?.map((item) => (
        <div className="d-flex flex-row gap-6 align-items-center w-100">
          <div
            className="d-flex flex-row gap-2 align-items-center"
            style={{ width: '200px' }}
          >
            <Icon
              src={
                SubscriptionIcons[item.Title as keyof typeof SubscriptionIcons]
              }
              size={IconSize.Big}
              className="opacity-75"
            />
            <div className="fw-bold text-nowrap">{t(item.Title as any)}</div>
            <div className="opacity-75">+{t(item.DaysAlias as any)}</div>
          </div>
          <div
            style={{ height: '40px', width: '200px' }}
            className="justify-content-center d-flex align-items-center"
          >
            {(activeButtonId !== item.Id ||
              (!isInviteBuyFetching && isInviteBuyFetched === null)) && (
              <HornyButton
                disabled={
                  user?.InvitePoints! < item.Points ||
                  user?.Subscription.Level !== null ||
                  (user?.InviteSubscription.Level !== null &&
                    user?.InviteSubscription.Level !== item.Title)
                    ? true
                    : undefined
                }
                onClick={() => {
                  handleInviteBuy(item.Id);
                }}
                style={{ lineHeight: 'normal' }}
                height={HornyButtonHeight.InviteShop}
              >
                <div className="fw-bold">{t('Activate')}</div>
                <div className="horny-bg-dark_half horny-border_100 fw-bold px-3 py-1">
                  {item.Points}
                  {/* {t('Points')} */}
                </div>
              </HornyButton>
            )}
            {isInviteBuyFetching && activeButtonId === item.Id && (
              <Icon
                src={IconSrc.Reload}
                size={IconSize.Small}
                className={'horny-spin'}
              />
            )}
            {isInviteBuyFetched !== null && activeButtonId === item.Id && (
              <motion.div
                animate={isInviteBuyFetched}
                initial="out"
                variants={AnimParamSmallScaleOpacity}
                transition={TransTweenAnticipate03}
              >
                {t('Subscription Activated!')}
              </motion.div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export function UserInvites() {
  const [pageSize, setPagesize] = useState(5);
  const [total, setTotal] = useState(5);
  const [startFrom, setStartFrom] = useState(0);
  const [invites, setInvites] = useState<inviteGetInvite[] | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  if (invites === null) {
    InviteAPI.invitesGet({ StartFrom: startFrom, PageSize: pageSize }).then(
      (res) => {
        setInvites(res.data.Invites);
        setStartFrom(startFrom + pageSize);
        setTotal(res.data.Pagination.Total);
      }
    );
  }

  const handleLoadMore = () => {
    setIsFetching(true);
    InviteAPI.invitesGet({ StartFrom: startFrom, PageSize: pageSize }).then(
      (res) => {
        setInvites((oldData) => [...oldData!, ...res.data.Invites]);
        setStartFrom(startFrom + pageSize);
        setTotal(res.data.Pagination.Total);
        setIsFetching(false);
      }
    );
  };

  const { t } = useTranslation();

  return (
    <>
      {invites === null && (
        <div className="d-flex justify-content-center w-100 p-10">
          <Icon
            src={IconSrc.Reload}
            size={IconSize.Small}
            className={'horny-spin'}
          />
        </div>
      )}
      {invites !== null && (
        <>
          <Container fluid="md">
            <Row className="fw-bold flex-table-row-header py-5">
              <Col className="text-nowrap text-truncate  flex-table-col">
                {t('Referral')}
              </Col>
              <Col className="flex-table-col">{t('Status')}</Col>
              <Col className="flex-table-col">{t('Points Earned')}</Col>
            </Row>
            {invites?.map((invite) => (
              <Row className="flex-table-row py-5">
                <Col
                  className={`text-nowrap text-truncate flex-table-col ${invite.Nickname === null && 'opacity-50'}`}
                >
                  {invite.Nickname === null
                    ? t('Name is hidden...')
                    : invite.Nickname}
                </Col>
                <Col className="flex-table-col d-flex flex-row gap-2">
                  <Icon
                    src={
                      invite.Status === null
                        ? IconSrc.Next
                        : SubscriptionIcons[
                            invite.Status as keyof typeof SubscriptionIcons
                          ]
                    }
                    size={IconSize.Small}
                  />
                  <div className="text-nowrap">
                    {invite.Status === null
                      ? t('Sign Up')
                      : t(invite.Status as any)}
                  </div>
                  <div className="opacity-50 ms-1">
                    {t(invite.DaysAlias as any)}
                  </div>
                </Col>
                <Col className="flex-table-col">
                  {invite.Points === null ? '-' : invite.Points}
                </Col>
              </Row>
            ))}
          </Container>
          {invites.length === 0 && (
            <div>{t('No referers records found...')}</div>
          )}
          {!isFetching && startFrom < total && (
            <motion.div
              className="d-flex justify-content-center gap-10 flex-wrap no-underline-on-hover"
              whileTap={{ scale: 0.8 }}
            >
              <div
                className="d-inline-flex align-items-end gap-2 text-nowrap cursor-pointer"
                onClick={() => {
                  handleLoadMore();
                }}
              >
                {/* <Icon src={IconSrc.Magic} /> */}
                <div className={`text-white fw-bold text-decoration-underline`}>
                  {t('Load More')}
                </div>
              </div>
            </motion.div>
          )}
          {isFetching && (
            <div className="d-flex justify-content-center w-100 p-10">
              <Icon
                src={IconSrc.Reload}
                size={IconSize.Small}
                className={'horny-spin'}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export function Invite(props: InviteProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { prices } = useResourcesContext();
  return (
    <motion.div
      animate={props.active ? 'in' : 'out'}
      initial="initial"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
      className="horny-bg-gradient-gray-tab d-flex flex-column gap-3 h-100"
      onClick={() => {}}
    >
      <div className="pt-2 px-6 d-flex flex-column gap-6 overflow-y-scroll horny-disable-scrollbar">
        <div className="d-flex gap-3 flex-column" style={{ marginTop: '60px' }}>
          <div className="fw-bold fs-1">{t('Invite you friends')}</div>
          <div className="opacity-75">
            <Trans
              i18nKey="Invite desctiption"
              values={{
                energy: prices?.InviteCodeRewards.Energy,
                messages: prices?.InviteCodeRewards.Messages,
              }}
              components={[<b />]}
            />
            {/* Invite friends to Temptations.AI, earn points and get a free
            subscription. <b>Share the code</b> with friends, and tell them to
            enter it in the "Settings" menu. They'll receive a "Welcome" bonus
            of +{prices?.InviteCodeRewards.Energy} energy and +
            {prices?.InviteCodeRewards.Messages} messages. When they subscribe,
            you'll recieve points. */}
          </div>
          <div className="opacity-75">
            {t('You can share character description')}
          </div>
          <div>
            <div className="w-100 d-flex flex-row gap-10">
              <div>
                <CopyCode code={user?.CreatorCode!} />
              </div>
              <div className="justify-content-center align-items-center d-flex flex-column">
                <div className="opacity-75">{t('Your Balance')}</div>
                <div className="fw-bold fs-3">
                  {user?.InvitePoints} {t('Points')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 flex-column">
          <div className="fw-bold fs-3">{t('Points Shop')}</div>
          {user?.Subscription.Level !== null && (
            <div className="d-flex flex-row gap-2 my-3 opacity-75">
              <Icon src={IconSrc.Warning} size={IconSize.Small} />{' '}
              {t('You cant buy invite')}
            </div>
          )}
          {user?.InviteSubscription.Level !== null && (
            <div className="d-flex flex-column gap-1 opacity-75">
              <div className="d-flex flex-row gap-2">
                <div>{t('You currently have active Invite Subscription')}:</div>
                <div className="d-flex flex-row gap-2">
                  <Icon
                    src={
                      SubscriptionIcons[
                        user?.InviteSubscription
                          .Level as keyof typeof SubscriptionIcons
                      ]
                    }
                    size={IconSize.Small}
                  />
                  <div className="fw-bold">
                    {user?.InviteSubscription.Level}
                  </div>
                </div>
              </div>
              <div>
                {t('Invite Subscription ends')}{' '}
                <b>{String(user?.InviteSubscription.Ends).split('T')[0]}</b>
              </div>
            </div>
          )}
          <InvitePurchases />
        </div>
        <div className="d-flex gap-3 flex-column">
          <div className="fw-bold fs-3">{t('Your Referrals')}</div>
          <div className="opacity-75">{t('Heres the list of invite')}</div>
          <UserInvites />
        </div>
        <div className="d-flex gap-3 flex-column">
          <div className="fw-bold fs-3">{t('Rewards')}</div>
          <div className="opacity-75">
            {t(
              'Earn points when your referrals subscribe. Below are the rewards you can get.'
            )}
          </div>
          <InviteRewards />
        </div>
        <div className="mt-4"></div>
      </div>
      <img
        className="horny-tab-background-image"
        src={getImgUrl('tabsbg/InviteBgNew.png')}
        alt="background"
      />
    </motion.div>
  );
}
