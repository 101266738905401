export const getEnvKey = (key: string) => {
  const envValue = process.env[`REACT_APP_${key}`];
  if (envValue === undefined) {
    throw new Error(`process.env.${key} is undefined`);
  }
  return envValue;
};

export const parseEnvKey = <T>(key: string) => {
  const envValue = getEnvKey(key);
  try {
    return JSON.parse(envValue) as T;
  } catch (e) {
    throw new Error(`parsing process.env.${key} with value ${envValue} failed`);
  }
};

export const emailRegex =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
