export enum WindowMessageEvent {
  GotUserId = 'GotUserId',
  Payment = 'payment',
}

export type WindowMessageGotUserId = {
  event: WindowMessageEvent.GotUserId;
  data: {
    nutakuUserId: string;
  };
};

export type WindowMessagePaymentSuccess = {
  event: WindowMessageEvent.Payment;
  isOk: true;
  data: {
    nutakuId: string;
  };
};

export type WindowMessagePaymentFail = {
  event: WindowMessageEvent.Payment;
  isOk: false;
};

// WindowMessageEvente

// export type WindowMessageContent = {

// }
