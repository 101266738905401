import { useUserContext } from 'src/context/User.context';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';
import { Icon, IconSize, IconSrc } from '../Icon/Icon';
import './index.css';

export enum PriceSize {
  Tiny = 'tiny',
}

export type PriceProps = {
  amount: number;
  className?: string;
  size?: IconSize;
  header?: boolean;
};

type PriceSizeClassName = {
  [priceSize in PriceSize]: string;
};

const priceSizeClassName: PriceSizeClassName = {
  [PriceSize.Tiny]: 'tiny',
};
export function Price({
  amount,
  className = '',
  size = IconSize.Tiny,
  header = false,
}: PriceProps) {
  const { user } = useUserContext();
  return (
    <div className={`horny-price horny-border_100 ${className}`}>
      <div className="d-flex horny-gap_tiny justify-content-center align-items-center">
        <Icon className={`horny-price_icon`} src={IconSrc.Energy} size={size} />
        <div className="text-white horny-text_small d-inline-block align-baseline fw-bold">
          {((user?.MergedSubscription.Level === SubscriptionLevel.Boss ||
            user?.MergedSubscription.Level === SubscriptionLevel.Master) &&
            !amount) ||
          ((user?.MergedSubscription.Level === SubscriptionLevel.Boss ||
            user?.MergedSubscription.Level === SubscriptionLevel.Master) &&
            header) ||
          amount === Infinity ? (
            <span>&infin;</span>
          ) : (
            amount
          )}
        </div>
      </div>
    </div>
  );
}
