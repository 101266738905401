import { EmojiSrc } from 'src/components/Shared/Emoji/Emoji';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { ellipseSliceString } from 'src/shared/utils';
export enum CharacterGenerationTagTitle {
  Asian = 'Asian',
  Another = 'Another',
}

export type CharacterGenerationTagProps = {
  title: string;
  emoji: EmojiSrc;
  selected: boolean;
  available: boolean;
  onDelete?: () => any;
  emojiCode?: string;
  className?: string;
  bg?: HornyButtonBG;
  shadow?: string;
};
export function Tag({
  title,
  emoji,
  emojiCode = '',
  available,
  onDelete,
  className,
  bg = HornyButtonBG.Orange,
  shadow = 'horny-shadow',
}: CharacterGenerationTagProps) {
  return (
    <HornyButton
      bg={bg}
      height={HornyButtonHeight.Short}
      // type={HornyButtonType.Chat}
      onClick={console.log}
      {...(!available ? { disabled: true } : {})}
      className={className + ' horny-tag ' + 'px-3'}
      shadow={shadow}
    >
      {onDelete ? <div>&nbsp;</div> : <></>}
      {emojiCode &&
        emojiCode.split('-').map((codeBlock) => (
          <span
            dangerouslySetInnerHTML={{
              __html: `&#x${codeBlock.toUpperCase()};`,
            }}
            key={codeBlock}
          ></span>
        ))}
      <div className="text-nowrap">{ellipseSliceString(title, 24)}</div>
      {onDelete ? (
        <div
          className="horny-text_tiny fw-bold ms-2 fs-7"
          onClick={() => onDelete()}
        >
          ✕
        </div>
      ) : (
        <></>
      )}
    </HornyButton>
  );
}
