import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';

export function NoMedia() {
  const { t } = useTranslation();
  return (
    <motion.div
      className="h-100 w-100 d-flex align-items-center justify-content-center"
      animate="in"
      initial="initial"
      // exit="out"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
    >
      <div className="w-75">
        <div className="fs-3 fw-bold">{t('No media :(')}</div>
        <div>
          {t('No media here yet. Chat with this character to get some!')}
        </div>
      </div>
    </motion.div>
  );
}
