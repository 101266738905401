import { useUserContext } from 'src/context/User.context';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
// import { Auth } from '@supabase/auth-ui-react';
import './index.css';
import { Link } from '../Shared/Link/Link';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import {
  HornyAuthorization,
  HornyAuthorizationProps,
} from './HornyAuthorization/HornyAuthorization';

export enum AuthState {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ForgotPassword = 'ForgotPassword',
}
export type AuthProps = {
  close: () => any;
};

export function Authorization(props: Omit<HornyAuthorizationProps, 'goTo'>) {
  const supabaseClient = getSupabaseClient();

  const { user } = useUserContext();
  if (user) {
    // window.location.reload();
  }
  const { capture } = useAnalyticsContext();
  // useEffect(() => {
  //   capture({
  //     event: 'page_open',
  //     data: {
  //       page_name: 'Main',
  //     },
  //   });
  // }, []);

  return <HornyAuthorization {...props} />;

  return (
    <div className="mx-auto w-100 authorization h-auto pt-20 px-6 z-10">
      <div className="mb-5">
        <img
          src=""
          style={{ width: 0, height: 0 }}
          onError={() => {
            capture({
              event: 'page_open',
              data: {
                page_name: 'Authorization',
              },
            });
          }}
        />
        <div className="fs-2 fw-bold">Join us</div>
        <div style={{ maxWidth: 400 }} className="no-underline-on-hover">
          By pressing the button “Sign up” or “Sign In“, you also agree to the{' '}
          <Link url="https://temptationgames.com/privacy-policy">
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link url="https://temptationgames.com/terms-of-service">
            Terms of Service
          </Link>
          . Thanks for reading them carefully. Also you confirm that you are 18
          years old or older.
        </div>
      </div>
    </div>
  );
}
