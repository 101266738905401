export enum EmojiSrc {
  Ball = 'emoji/Ball',
  Devil = 'emoji/Devil',
  Golf = 'emoji/Golf',
  Mic = 'emoji/Mic',
  Sunset = 'emoji/Sunset',
  Censor = 'emoji/Censor',
  Girl = 'emoji/Girl',
  Lol = 'emoji/Lol',
  Polo = 'emoji/Polo',
}
