import { faBroomWide, faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { GetChatResponse } from 'src/services/API/ConnectionsAPI';
import {
  AnimParamInOutOpacityY100,
  AnimParamInOutOpacityYNeg100,
  TransSpringPopup,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { cn, underlineSelected } from 'src/shared/utils';
import { Chat } from '../Chat/Chat';
import { CommunityMedia } from '../Media/CommunityMedia/CommunityMedia';
import {
  DialogueMedia,
  MediaBlock,
} from '../Media/DialogueMedia/DialogueMedia';
import { ClearChat } from './ClearChat';
import './index.css';
// import { useSearchParams } from 'react-router-dom';
// import { useSearchParams } from 'react-router-dom';
export enum DialogueTabToShow {
  Chat = 'chat',
  Media = 'media',
}

export type DialogueProps = {
  showMedia?: string;
  // chatId: string;
  // Name: string;
  // Author: string;
  // IsBotAuthor: boolean;
};

export function Dialogue({
  showMedia,
  ...chatResponse
}: GetChatResponse & DialogueProps) {
  const { t } = useTranslation();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { capture } = useAnalyticsContext();

  const [selectedTab, setSelectedTab] = useState<DialogueTabToShow | null>(
    () => {
      if (showMedia) {
        return DialogueTabToShow.Media;
      }

      return DialogueTabToShow.Chat;
    }
  );
  const [selectedMediaBlock, setSelectedMediaBlock] =
    useState<MediaBlock | null>(() => {
      if (showMedia === 'community') {
        capture({
          event: 'page_open',
          data: {
            page_name: 'Media_community',
          },
        });
        return MediaBlock.Community;
      }
      return MediaBlock.Dialogue;
    });
  const [loaded, setLoaded] = useState<boolean>(false);
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  //   const renderContent = {
  //     [TabToShow.Discover]: () => <CharacterCarousel onLoaded={() => setLoaded(true)} />,
  //     [TabToShow.Connections]: () => <Connections />
  // };

  const switchMediaBlock = (block: MediaBlock) => {
    capture({
      event: 'page_open',
      data: {
        page_name:
          block === MediaBlock.Community ? 'Media_community' : 'Media_my',
      },
    });
    setSelectedMediaBlock(null);
    setSelectedMediaBlock(block);
  };

  const [restartFromMessage, setRestartFromMessage] = useState(false);

  return (
    <motion.div
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
      className="position-relative m-auto h-100 "
      style={{
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${chatResponse.Chat.CoverUrl})  center 10% / cover no-repeat`,
      }}
    >
      <div className="dialogue_header position-absolute w-100 z-2 pe-none"></div>
      {/* <ProfileHeader />
        <Header /> */}

      {
        <div
          className="tw-absolute tw-z-10 tw-flex-row tw-flex tw-gap-3 tw- tw-justify-between tw-w-full tw-mt-20 tw-px-7 tw-font-bold" // position-absolute z-3
          // d-grid justify-content-between gap-3 mt-10 pt-10 w-100 my-5 m-auto px-7
          // style={{ height: 52, gridTemplateColumns: 'repeat(3, 1fr)' }}
        >
          <div className="d-flex gap-4 justify-content-center">
            <motion.div
              whileTap={{ scale: 0.8 }}
              onClick={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Chat',
                  },
                });
                setSelectedTab(DialogueTabToShow.Chat);
              }}
              className="d-flex flex-column gap-1 cursor-pointer"
            >
              <div className="horny-text-shadow text-nowrap">{t('Chat')}</div>
              {selectedTab === DialogueTabToShow.Chat && underlineSelected}
            </motion.div>
            <motion.div
              whileTap={{ scale: 0.8 }}
              onClick={() => {
                capture({
                  event: 'page_open',
                  data: {
                    page_name: 'Media_my',
                  },
                });
                setSelectedTab(DialogueTabToShow.Media);
                // setTimeout(() => setSelectedTab(DialogueTabToShow.Media), 4000);
              }}
              className="d-flex flex-column gap-1 cursor-pointer"
            >
              <div className="horny-text-shadow text-nowrap">{t('Media')}</div>
              {selectedTab === DialogueTabToShow.Media && underlineSelected}
            </motion.div>
            {/* <div  onClick={() => setSelectedTab(TabToSelect.Connections)}>Connections</div> */}
          </div>
          {selectedTab === DialogueTabToShow.Chat && (
            <motion.div
              className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-justify-end"
              animate="in"
              initial="initial"
              variants={AnimParamInOutOpacityYNeg100}
              transition={TransTweenAnticipate03}
            >
              <motion.div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-px-2 tw-py-1 tw-w-fit tw-text-sm"
                whileTap={{ scale: 0.9 }}
                onClick={() => setRestartFromMessage((prev) => !prev)}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  className={cn(!restartFromMessage && 'tw-opacity-50')}
                />
                <div className={cn(!restartFromMessage && 'tw-opacity-50')}>
                  {t('Edit')}
                </div>
              </motion.div>
              <motion.div
                className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-px-2 tw-py-1 tw-w-fit tw-text-sm"
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  capture({
                    event: 'page_open',
                    data: {
                      page_name: 'Clear_chat',
                    },
                  });
                  addModal({
                    children: <ClearChat id={chatResponse.Chat.BotId} />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faBroomWide} />
                <div>{t('Clear')}</div>
              </motion.div>
            </motion.div>
          )}
        </div>
      }
      <motion.div
        animate={selectedTab === DialogueTabToShow.Chat ? 'in' : 'out'}
        //initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === DialogueTabToShow.Chat ? 'd-block h-100' : 'd-none'}`}
      >
        <Chat
          chatResponse={chatResponse}
          isRestartFromMessage={restartFromMessage}
          disableRestartFrom={() => setRestartFromMessage(false)}
        />
      </motion.div>
      <motion.div
        animate={selectedTab === DialogueTabToShow.Media ? 'in' : 'out'}
        //initial="initial"
        // exit="out"
        variants={AnimParamInOutOpacityYNeg100}
        transition={TransTweenAnticipate03}
        className={`${selectedTab === DialogueTabToShow.Media ? 'd-block h-100' : 'd-none'}`}
      >
        {/* <AnimatePresence mode = "wait"> */}
        {!selectedMediaBlock ? (
          <div></div>
        ) : selectedMediaBlock === MediaBlock.Dialogue ? (
          <DialogueMedia
            selectedMediaBlock={MediaBlock.Dialogue}
            botId={chatResponse.Chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        ) : (
          <CommunityMedia
            selectedMediaBlock={MediaBlock.Community}
            botId={chatResponse.Chat.BotId}
            onSelectMediaBlock={(block) => switchMediaBlock(block)}
          />
        )}
        {/* </AnimatePresence> */}
      </motion.div>
    </motion.div>
  );
}
