import { Media, MediaSaveOptions } from '@capacitor-community/media';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { GetSingleMediaResponse } from 'src/@types/common';
import { ReportMedia } from 'src/components/Report/ReportMedia';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { NoCopyPopup } from 'src/components/Shared/NoCopyPopup/NoCopyPopup';
import { appConfig } from 'src/config/app';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { NutakuEnv, useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import { MediaAPI } from 'src/services/API/MediaAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import {
  blobToBase64,
  copyURLToClibpoard,
  shareData,
  shareNative,
} from 'src/shared/helpers';
import {
  MediaControls,
  errorImageSrc,
  showAuthorization,
} from 'src/shared/utils';

export type MediaDisplayPhotoProps = GetSingleMediaResponse & {
  controls?: MediaControls;
};

export function MediaDisplayPhoto({
  CoverUrl,
  Name,
  AddedToMedia,
  Liked,
  CanShareWithCommunity,
  MediaId,
  SharedWithCommunity,
  Author,
  LikesCount,
  BotId,
  CanAddToMedia,
  IsPony,
  IsPrivate
}: MediaDisplayPhotoProps) {
  const { t } = useTranslation();
  const { isNutaku, nutakuEnv } = useNutakuContext();
  const { user } = useUserContext();
  const [src, setSrc] = useState(CoverUrl);
  const [shareText, setShareText] = useState(t('Share'));
  const mediaUrlToShare = !isNutaku
    ? `${window.location.origin}/media?MediaId=${MediaId}`
    : `${appConfig.nutaku.nutakuShareLink}?BotId=${BotId}&MediaId=${MediaId}&CreatorCode=${user?.CreatorCode}`;
  const shareBlockRef = useRef<HTMLDivElement>(null);
  const communityBlockRef = useRef<HTMLDivElement>(null);
  const [addBlocksHidden, setAddBlocksHidden] = useState(false);

  const [isSharedWithCommunity, setIsSharedWithCommunity] =
    useState(SharedWithCommunity);
  const [isLiked, setIsLiked] = useState(Liked);
  const [likesCount, setLikesCount] = useState(LikesCount);
  const [addedToMedia, setAddedToMedia] = useState(AddedToMedia);
  const { addModal, removeModal } = useModalContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [reportText, setReportText] = useState(t('Report'));
  const [downloading, setDownloading] = useState(false);
  const { capture } = useAnalyticsContext();
  const [loadingError, setLoadingError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSquare, setIsSquare] = useState(false);
  const openedByLink = window.location.pathname.includes('media');
  const changeBlockHidden = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { target } = e;
    if (
      containerRef.current &&
      !containerRef.current.contains(target as Node)
    ) {
      return true;
    }
    if (
      shareBlockRef.current &&
      shareBlockRef.current.contains(target as Node)
    ) {
      return true;
    }

    if (
      communityBlockRef.current &&
      communityBlockRef.current.contains(target as Node)
    ) {
      return true;
    }

    setAddBlocksHidden(!addBlocksHidden);
  };

  const androidAlbumName = 'Temptations AI';

  const createAlbumAndroid = async () => {
    let response = await Media.getAlbums();
    let album = response.albums.find((a) => a.name === androidAlbumName);

    if (!album) {
      await Media.createAlbum({ name: androidAlbumName });
      response = await Media.getAlbums();
      album = response.albums.find((a) => a.name === androidAlbumName);
    }

    return album?.identifier;
  };

  const saveToGalleryAndroid = async (url: string) => {
    let opts: MediaSaveOptions = {
      path: url,
      albumIdentifier: await createAlbumAndroid(),
    };
    await Media.savePhoto(opts);
  };

  const shareMediaUrl = () => {
    if (nutakuEnv == NutakuEnv.Android) {
      console.log('Sharing with native');
      shareNative({
        title: t('Character') + ' : ' + Name,
        text: t("Check out the image i've generated using Temptations.AI!"),
        url: mediaUrlToShare,
        dialogTitle: t('Share this Character!'),
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    } else if (nutakuEnv == NutakuEnv.Web) {
      addModal({
        children: (
          <NoCopyPopup
            whatCopying={t('sharing link')}
            textToCopy={mediaUrlToShare}
          />
        ),
        className: 'horny-bg-dark_opac',
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    } else {
      shareData({
        url: mediaUrlToShare,
        mediaUrl: CoverUrl,
        name: Name || '',
      }).finally(() => {
        capture({
          event: 'share_media',
          data: {
            bot_id: BotId,
            media_id: MediaId,
            media_type: 'photo',
          },
        });
      });
    }

    copyURLToClibpoard(mediaUrlToShare);
    setShareText(t('Copied'));
    setTimeout(() => {
      setShareText(t('Shared'));
    }, 2000);
  };

  const shareWithCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.shareWithCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(true));
  };

  const removeFromCommunity = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.removeFromCommunity({
      MediaId,
    }).then(() => setIsSharedWithCommunity(false));
  };

  const addToMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.add({ MediaId }).then(() => setAddedToMedia(true));
  };

  const removeFromMedia = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.remove({ MediaId }).then(() => setAddedToMedia(false));
  };

  const like = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.like({
      MediaId,
    }).then(() => {
      setIsLiked(true);
      setLikesCount(likesCount + 1);
    });
  };

  const dislike = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    MediaAPI.dislike({
      MediaId,
    }).then(() => {
      setIsLiked(false);
      setLikesCount(likesCount - 1);
    });
  };

  const processSeeMoreClick = () => {
    if (!user) {
      window.location.href = '/?GirlId=' + BotId;
      return;
      //return showAuthorization(addModal);
    }
    if (window.location.pathname.includes('connections')) {
      ConnectionsAPI.createChat({
        BotId,
      }).then(() => {
        goToMedia();
      });
    } else {
      goToMedia();
    }
  };

  const downloadMedia = () => {
    if (downloading) {
      return;
    }
    setDownloading(true);

    if (nutakuEnv == NutakuEnv.Android) {
      saveToGalleryAndroid(CoverUrl).finally(() => setDownloading(false));
    } else {
      MediaAPI.getMediaProxy(CoverUrl)
        .then(({ data }) => blobToBase64(data))
        .then((data) => {
          const downloadElement = document.createElement('a');
          downloadElement.download = MediaId;
          downloadElement.href = data;
          downloadElement.click();
        })
        .finally(() => setDownloading(false));
    }
  };

  const goToMedia = () => {
    window.location.href = '/connections?ChatId=' + BotId + '&media=community';
  };

  const onReportClick = () => {
    if (!user) {
      return showAuthorization(addModal);
    }
    const modal = addModal({
      children: (
        <ReportMedia
          Id={MediaId}
          onReport={() => {
            removeModal(modal);
            setReportText(t('Reported'));
            setTimeout(() => setReportText(t('Report')), 3000);
          }}
        />
      ),
    });
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
      className="h-100 mx-auto w-max-content w-100 mw-100 position-relative d-flex justify-content-center"
      style={{ background: 'rgba(0,0,0,0.5)' }}
      ref={containerRef}
    >
      <Image
        className="  object-fit-cover  mx-auto pe-none"
        src={'img/Placeholder.svg'}
        style={{
          height: '100dvh',
          width: window.screen.height / 1.78125,
          maxWidth: '100vw',
        }}
      />
      <div className=" position-absolute w-100 h-100 d-flex align-items-center">
        <Image
          className={`${isSquare ? '' : 'h-100'} ${loading && !loadingError ? 'opacity-0' : ''} w-auto mw-100 object-fit-cover  mx-auto`}
          src={(() => {
            console.log({
              loadingError,
              isSquare,
              src,
            });
            if (loadingError) {
              return errorImageSrc;
            }
            if (isSquare) {
              return src + '?width=' + 1024; //Math.min(window.screen.width, 600);
            }
            return src + '?height=' + 1368; //window.screen.height;
          })()}
          onLoad={(e) => {
            const target = e.target as HTMLImageElement;
            if (target.naturalHeight === target.naturalWidth) {
              setIsSquare(true);
            }
            setLoading(false);
            capture({
              event: 'page_open',
              data: {
                page_name: 'Media',
              },
            });
          }}
          onError={() => setLoadingError(true)}
        />
      </div>
      <div
        className="position-absolute w-100 h-100 z-1 d-flex flex-column justify-content-end"
        style={{ top: 0, left: 0 }}
        onClick={(e) => changeBlockHidden(e)}
      >
        {/* {!addBlocksHidden && (
          <div className="position-absolute   pe-none horny-absolute_full"></div>
        )} */}
        {/* <div className="h-50"></div> */}
        <div className="d-grid flex-column align-items-between gap-4  position-relative z-2">
          <div
            className={`me-0 mb-10 ms-auto d-flex flex-column gap-4 px-4 horny-text-shadow`}
            ref={shareBlockRef}
            style={{ width: '70px' }}
          >
            <motion.div
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              whileTap={{ scale: 0.8 }}
            >
              <Icon
                src={isLiked ? IconSrc.AddSuper : IconSrc.AddRed}
                size={IconSize.XL}
                onClick={() => (!isLiked ? like() : dislike())}
              />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {likesCount}
              </div>
            </motion.div>
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() => downloadMedia()}
              // href={
              //   CoverUrl.replace('https://teasegames.b-cdn.net/', 'https://cdn.temptations.ai/')
              // }
              // download={true}
            >
              <Icon
                src={downloading ? IconSrc.Loader : IconSrc.DownloadUpdated}
                size={IconSize.XL}
                className={downloading ? 'horny-spin' : ''}
              />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {t('Download')}
              </div>
            </motion.div>
            {/* {!isNutaku && ( */}
            {!IsPrivate && <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() => shareMediaUrl()}
            >
              <Icon src={IconSrc.ShareBlue} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {shareText}
              </div>
            </motion.div>}
            {/* )} */}
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="cursor-pointer w-100 d-flex flex-column align-items-center justify-content-center"
              onClick={() => onReportClick()}
            >
              <Icon src={IconSrc.Report} size={IconSize.XL} />
              <div className="horny-text_tiny mt-1 text-center horny-break-words w-100">
                {reportText}
              </div>
            </motion.div>
          </div>
          <div
            className={`horny-text-shadow text-start horny-bg-gradient-black_opac d-${addBlocksHidden ? 'none' : 'flex'} flex-column align-items-between align-self-end pb-10`}
            ref={communityBlockRef}
          >
            <div></div>
            <div className="text-start d-flex flex-column gap-4 position-relative z-2 ">
              <div className="d-flex flex-wrap gap-4 justify-content-center mx-3">
                <motion.div
                  whileTap={{ scale: 0.8 }}
                  className={`d-flex gap-4 justify-content-center ${CanAddToMedia ? 'cursor-pointer' : 'pe-none opacity-75'}`}
                >
                  {!addedToMedia ? (
                    <div
                      className={`d-flex align-items-center gap-2`}
                      onClick={() => addToMedia()}
                    >
                      <Icon src={IconSrc.Add} />{' '}
                      <span className="text-nowrap">{t('Add to media')}</span>
                    </div>
                  ) : (
                    <div
                      className={`d-flex align-items-center gap-2`}
                      onClick={() => removeFromMedia()}
                    >
                      <Icon src={IconSrc.exit} />{' '}
                      <span className="text-nowrap">
                        {t('Remove from media')}
                      </span>
                    </div>
                  )}
                </motion.div>
                {!IsPrivate &&  <motion.div
                  whileTap={{ scale: 0.8 }}
                  className={
                    CanShareWithCommunity && user
                      ? 'cursor-pointer'
                      : 'pe-none opacity-75'
                  }
                >
                  {!isSharedWithCommunity ? (
                    <div
                      className={`d-flex  align-items-center gap-2 justify-content-center  `}
                      {...(CanShareWithCommunity
                        ? { onClick: () => shareWithCommunity() }
                        : {})}
                    >
                      <Icon src={IconSrc.share} />{' '}
                      <span className="text-nowrap">
                        {t('Share with Сommunity')}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="d-flex  align-items-center gap-2 justify-content-center"
                      {...(CanShareWithCommunity
                        ? { onClick: () => removeFromCommunity() }
                        : {})}
                    >
                      <Icon src={IconSrc.exit} />{' '}
                      <span className="text-nowrap">
                        {t('Remove from Сommunity')}
                      </span>
                    </div>
                  )}
                </motion.div>}
              </div>
              <div className=" justify-content-center text-center">
                {t('Author')}:{' '}
                <span className="opacity-75">@{Author || 'anonymous'} </span>
                {/* <span onClick={console.log}>
                  <Icon src={IconSrc.Info} size={IconSize.Tiny} />
                </span> */}
              </div>

              {IsPony && (
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-w-full tw-justify-center tw-text-center tw-opacity-50 tw-text-xs">
                  <Trans i18nKey="photoCreatedWith">
                    <span>Photo created with</span>
                    <a
                      href="https://purplesmart.ai/"
                      target="_blank"
                      className="tw-underline tw-underline-offset-2"
                    >
                      Pony Diffusion
                    </a>
                  </Trans>
                </div>
              )}

              <div className=" justify-content-center text-center cursor-pointer">
                {openedByLink && (
                  <u onClick={() => processSeeMoreClick()}>
                    {t('Chat with this character')}
                  </u>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
