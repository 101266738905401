import { createClient } from '@supabase/supabase-js';
import { appConfig } from 'src/config/app';

const supabaseClient = createClient(
  appConfig.supabase.host,
  appConfig.supabase.publicKey
); // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0");
// const supabaseClient = createClient("https://jojpuwpyqcefphkdnzra.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpvanB1d3B5cWNlZnBoa2RuenJhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ3NDEyNzUsImV4cCI6MjAyMDMxNzI3NX0.BLgeNnD4cyGGC0M0nPkvDhKqWA0iKtakHhVtHyDY-lM");

export const getSupabaseClient = () => supabaseClient;
