import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const defaultNS = 'en';

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: true,
    backend: {
      loadPath:
        'https://teasegames.b-cdn.net/teasegames/localization/{{lng}}.json',
      requestOptions: {
        cache: 'no-cache',
      },
    },
    // defaultNS
  });
