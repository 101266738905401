import './index.css';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/app';
import { CharacterTextParamsSubtitle } from 'src/services/API/CharacterAPI';
import { TypePreviewColor } from '../../Shared/TypePreview/TypePreview';
// export enum PersonalityTypeKind {
//     Submissive = 'Submissive',
//     Neutral = 'Neutral',
//     Dominant = 'Dominant',
// }

export type PersonalityTypeProps = {
  selected: boolean;
  kind?: CharacterTextParamsSubtitle;
  emojiCode?: string;
  title: string;
  description: string;
  onSelect: () => any;
  color?: TypePreviewColor;
};
export function PersonalityType({
  selected,
  kind,
  title,
  description,
  emojiCode = '',
  onSelect,
  color = TypePreviewColor.Red,
}: PersonalityTypeProps) {
  const { t } = useTranslation();
  const bgClass = {
    [CharacterTextParamsSubtitle.Submissive]: 'horny-bg-gradient_green',
    [CharacterTextParamsSubtitle.Neutral]: 'horny-bg-gradient_grey',
    [CharacterTextParamsSubtitle.Dominant]: 'horny-bg-gradient_red',
  }[kind!];

  const borderClass = selected
    ? 'border border-1 border-white border-opacity-75'
    : 'horny-border_transparent border border-1 border-white border-opacity-25';

  return (
    <div
      onClick={() => onSelect()}
      className={
        `horny-shadow-just-shadow-${color} cursor-pointer horny-bg_grey_dark rounded-3 position-relative d-flex flex-column gap-3 text-start px-5 py-5 overflow-hidden ` +
        borderClass
      }
      style={{ width: '150px' }}
    >
      {selected && (
        <Image
          src={appConfig.host.CDN + '/img/character/story/Chosen.png'}
          className="position-absolute"
          style={{ top: -7, right: 0, width: '70%' }}
        />
      )}
      <div className="d-flex gap-1 align-content-start align-items-left flex-column">
        {/* <Icon size={IconSize.Small} src={EmojiSrc.Devil} /> */}
        {
          <span
            className="fs-1"
            dangerouslySetInnerHTML={{
              __html: `&#x${emojiCode.toUpperCase()};`,
            }}
          ></span>
        }
        <div className="fw-bold d-flex">{title}</div>
      </div>
      <div>
        <div
          className={`rounded-4 horny-text_tiny px-4 py-1 ${bgClass} w-max-content lh-1`}
        >
          {t(kind as any)}
        </div>
      </div>
      <div className="horny-text_tiny lh-sm opacity-75">{description}</div>
    </div>
  );
}
