import { useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EmojiSrc } from 'src/components/Shared/Emoji/Emoji';
import { Tag } from 'src/components/Shared/Tag/Tag';
import { CharacterTextParamCommon } from 'src/services/API/CharacterAPI';
import './index.css';

export type TagSelectorProps = {
  onSelect: (tags: string[]) => any;
  tags: CharacterTextParamCommon[];
  tagType: string;
  limit: number;
  showFilter?: boolean;
  selectedTags?: string[];
  onRegenerate?: () => any;
  height?: string | number;
  borderClass?: string;
  selectText?: string;
};

export function TagSelector({
  onSelect,
  height,
  tagType,
  limit,
  showFilter = true,
  tags,
  selectedTags = [],
  onRegenerate,
  borderClass = '',
  selectText = '',
}: TagSelectorProps) {
  const { t } = useTranslation();
  const filterInputRef = useRef<HTMLInputElement | null>(null);
  const [filterValue, setFilterValue] = useState<string>('');

  const removeTag = (tag: string) => {
    onSelect(selectedTags.filter((t) => t !== tag));
  };

  const tagsToRender = tags.filter(
    (tag) =>
      t(tag.Title as any)
        .toLowerCase()
        .includes(filterValue.toLowerCase()) &&
      !selectedTags.includes(tag.Title)
  );

  // console.log({
  //   tags,
  //   selectedTags,
  // });
  return (
    <div className="d-flex flex-column gap-3 text-start">
      <div className="gap-2d-flex gap-3 justify-content-between">
        <div
          className="d-inline-block horny-valign-inline p-1 opacity-75 text-start"
          style={{ height: '32px', lineHeight: '26px' }}
        >{`${t('Select')} ${selectText}:`}</div>
        {tags
          .filter((t) => selectedTags.includes(t.Title))
          .map((tag) => (
            <div
              onClick={() => removeTag(tag.Title)}
              className="p-1 d-inline-block horny-valign-inline"
            >
              <Tag
                title={t(tag.Title as any)}
                emoji={EmojiSrc.Ball}
                emojiCode={tag.Icon}
                selected={false}
                available={true}
                onDelete={() => removeTag(tag.Title)}
                className=""
                key={tag.Title}
                shadow="horny-shadow-red-with-orange"
              />
            </div>
          ))}
      </div>

      {showFilter && (
        <div className="d-flex flex-column">
          <div>
            <InputGroup className="horny-control horny-height_medium rounded-2 overflow-hidden horny-shadow-just-shadow-red">
              <Form.Control
                ref={filterInputRef}
                onChange={(e) => setFilterValue(e.target.value)}
                placeholder={t('Type to filter') + ' ' + t(tagType as any)}
                aria-describedby="basic-addon1"
                className="horny-input horny-bg-gradient_orange_light border-0 horny-text_medium text-left ps-4 horny-text_orange"
              />
            </InputGroup>
          </div>
        </div>
      )}

      {
        <div
          className={`overflow-y-scroll tag-selector_render horny-disable-scrollbar ${borderClass}`}
          style={{ height: height || 'unset' }}
        >
          {tagsToRender.map((tag) => (
            <div
              className="d-inline-block m-1"
              onClick={() =>
                selectedTags.length < limit &&
                onSelect([...selectedTags, tag.Title])
              }
              key={tag.Title}
            >
              <Tag
                title={t(tag.Title as any)}
                emoji={EmojiSrc.Ball}
                emojiCode={tag.Icon}
                selected={false}
                available={selectedTags.length < limit}
                shadow="horny-shadow-red-with-orange"
              />
            </div>
          ))}
          {/* {onRegenerate ? (
          <div className="d-inline-block m-1" onClick={() => onRegenerate()}>
            <Tag
              title={'Regenerate!'}
              emoji={EmojiSrc.Ball}
              emojiCode="1F644"
              selected={false}
              available={true}
            />
          </div>
        ) : (
          ''
        )} */}
        </div>
      }
    </div>
  );
}
