import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';

export function NoBots() {
  const { t } = useTranslation();
  return (
    <motion.div
      className="h-100 w-100 d-flex align-items-center justify-content-center mt-20"
      animate="in"
      initial="initial"
      // exit="out"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
    >
      <div>
        <div className="fs-3 fw-bold">{t('No characters : (')}</div>
        <div>{t('No characters matching your filter')}</div>
      </div>
    </motion.div>
  );
}
