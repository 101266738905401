import { captureTrafficJunky } from 'src/context/AnalyticsContext/AnalyticsContext';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';

export type SubscriptionProviderFlowProps = {
  subscriptionLevel: SubscriptionLevel;
  selectedProvider: boolean;
  subscriptionId: string;
};

export enum SubscriptionProviderFlowStage {
  Init = 'init',
  StartedPayment = 'StartedPayment',
  StartedVerification = 'StartedVerification',
  ReadyToUpdate = 'ReadyToUpdate',
}

export const captureSubscriptionByTrafficJunky = (
  subLevel: SubscriptionLevel
) => {
  const eventsConfig = {
    [SubscriptionLevel.Friend]: {
      name: 'Pack4',
      type: 'Revenue',
      value: '4.000',
    },
    [SubscriptionLevel.Boyfriend]: {
      name: 'Pack1',
      type: 'Revenue',
      value: '9.000',
    },
    [SubscriptionLevel.Daddy]: {
      name: 'Pack2',
      type: 'Revenue',
      value: '25.000',
    },
    [SubscriptionLevel.Boss]: {
      name: 'Pack3',
      type: 'Revenue',
      value: '40.000',
    },
    [SubscriptionLevel.Master]: {
      name: 'Pack4',
      type: 'Revenue',
      value: '70.000',
    },
  };
  captureTrafficJunky(eventsConfig[subLevel]);
};
