import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { TemptationsButton } from '../Shared/TemptationsButton';
import { appConfig } from '../../config/app';

export default function UpdateAppOverlay() {
  const { t } = useTranslation();
  const appLinks = {
    nutaku: 'https://www.nutaku.net/games/temptations-ai/',
  };
  return (
    <div className="tw-w-full tw-h-full tw-bg-gradient-to-b tw-from-horny-gray-600 tw-to-horny-gray-900 tw-flex tw-items-center tw-justify-center">
      <div className="tw-px-10 tw-items-start tw-justify-center tw-h-full tw-flex tw-flex-col tw-gap-4 tw-max-w-[450px]">
        <div className="tw-font-bold tw-text-3xl">{t('app_update_title')}</div>
        <div>{t('app_update_description')}</div>

        <TemptationsButton
          color="red"
          size="lg"
          onClick={() => {
            if (appConfig.app.platform === 'web') {
              window.location.reload();
            } else {
              window.open(appLinks.nutaku, '_blank');
            }
          }}
        >
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
            <FontAwesomeIcon icon={faDownload} />
            <div>{t('app_update_button')}</div>
          </div>
        </TemptationsButton>
      </div>
    </div>
  );
}
