import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';

export function PatreonLanding() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const codeIsProcessing = useRef(false);
  const { user, setUser } = useUserContext();
  useEffect(() => {
    if (codeIsProcessing.current || !user) {
      return;
    }
    codeIsProcessing.current = true;
    code &&
      UserAPI.addPatreonUser(code)
        // .then(() =>
        //   UserAPI.sendPatreonWebhook()
        //     .then(() => UserAPI.get().then(({ data }) => setUser(data)))
        .then(() => {
          window.close();
        })

        .finally();
  });

  return <div>landing</div>;
}
