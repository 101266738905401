import { ConnectionsFilter } from 'src/@types/common/modules/Connections';
import { create } from 'zustand';

type State = {
  onlyOwn: boolean;
  filter: ConnectionsFilter;
  isChatOpen: boolean;
};

type Actions = {
  setOnlyOwn: (value: boolean) => void;
  setFilter: (value: ConnectionsFilter) => void;
  setIsChatOpen: (value: boolean) => void;
};

export const useConnectionsStore = create<State & Actions>((set) => ({
  onlyOwn: false,
  filter: 'All',
  isChatOpen: false,
  setOnlyOwn: (value: boolean) => set({ onlyOwn: value }),
  setFilter: (value: ConnectionsFilter) => set({ filter: value }),
  setIsChatOpen: (value: boolean) => set({ isChatOpen: value }),
}));
