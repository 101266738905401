import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
  CharacterNonPersonalityParams,
  CharacterPersonalityParams,
  CharacterTextParamsAPIModel,
  CharacterTextParamsCategory,
  CharacterTextParamsSubtitle,
} from 'src/services/API/CharacterAPI';
import { shuffleArray } from 'src/shared/helpers';
import { OnChoiceCallback } from '../CharacterGeneration';
import { IntroBlock } from '../Shared/IntroBlock';
import { StringSelector } from '../Shared/StringSelector/StringSelector';
import { TagSelector } from '../Shared/TagSelector/TagSelector';
import { PersonalityType } from './PersonalityType/PersonalityType';
import { TypePreviewColor } from '../Shared/TypePreview/TypePreview';
import { PrivateCharacterSwitcher } from './PrivateCharacterSwitcher';

export type PersonalityProps = {
  onChoice: OnChoiceCallback;
  fetched: CharacterGenerationDraftAPIModel[CharacterGenerationStage.Personality];
  onForward?: () => any;
  onFilter?: (key: string, value: string | null) => any;
};

const filterParams = (textParams: CharacterTextParamsAPIModel[]) => {
  const peronalityTextParams = textParams.filter(
    (tP) => tP.Category === CharacterTextParamsCategory.Pesonality
  ) as CharacterPersonalityParams[];
  const kinkTextParams = textParams.filter(
    (tP) => tP.Category === CharacterTextParamsCategory.Kink
  ) as CharacterNonPersonalityParams[];
  const occupationTextParams = textParams.filter(
    (tP) => tP.Category === CharacterTextParamsCategory.Occupation
  ) as CharacterNonPersonalityParams[];
  return {
    peronalityTextParams,
    kinkTextParams,
    occupationTextParams,
  };
};

export function Personality({
  onChoice,
  onForward,
  fetched,
  onFilter,
}: PersonalityProps) {
  const { t } = useTranslation();
  const [pesonality, setPersonality] = useState<string | null>(
    fetched?.Personality || null
  );
  const [personalityType, setPesonalityType] =
    useState<CharacterTextParamsSubtitle | null>(fetched?.Type || null);
  const [name, setName] = useState<string>(fetched?.Name || '');
  const [kinks, setKinks] = useState<string[]>(fetched?.Kinks || []);
  const [isPrivate, setIsPrivate] = useState(!!(fetched?.IsPrivate));
  const [occupation, setOccupation] = useState<string[]>(
    fetched?.Occupation ? [fetched.Occupation] : []
  );
  const [isFilled, setIsFilled] = useState(false);
  const fetchedRef = useRef<boolean>(false);
  const [nameIsFetching, setNameIsFetching] = useState<boolean>(false);

  const [textParams, setTextParams] = useState<
    CharacterTextParamsAPIModel[] | null
  >(null);
  const isFetching = useRef(false);

  const [error, setError] = useState<string | undefined>();

  const randomise = () => {
    if (!textParams) {
      return;
    }
    if (isFetching.current) {
      return;
    }
    isFetching.current = true;
    return randomiseName().then(() => {
      const { peronalityTextParams, kinkTextParams, occupationTextParams } =
        filterParams(textParams);
      const randomPesonality =
        shuffleArray<CharacterTextParamsAPIModel>(peronalityTextParams)[0];
      const randomKinks = shuffleArray<CharacterNonPersonalityParams>(
        kinkTextParams
      )
        .slice(0, 3)
        .map((tP) => tP.Title);
      const randomOccupation = [
        shuffleArray<CharacterNonPersonalityParams>(occupationTextParams)[0]
          .Title,
      ];
      setKinks(randomKinks);
      setPersonality(randomPesonality.Title);
      setPesonalityType(randomPesonality.Subtitle);
      setOccupation(randomOccupation);
      isFetching.current = false;
    });
  };

  const randomiseName = async () => {
    if (nameIsFetching) {
      return false;
    }
    setNameIsFetching(true);
    return CharacterAPI.generateCharacterName().then(({ data: { Name } }) => {
      setNameIsFetching(false);
      setName(Name);
      return true;
    });
  };

  const checkState = (): boolean => {
    const errors = {
      "You haven't entered the character's name": !name,
      "You haven't selected all 3 kinks": kinks.length < 3,
      "You haven't selected character's occupation": !occupation.length,
      "You haven't selected character's personality": !personalityType,
    };
    return Object.entries(errors).every(([key, val]) => {
      if (val === true) {
        setTimeout(() => setError(key), 40);
        return false;
      }
      return true;
    });
  };

  const updateDraft = () => {
    setError(undefined);

    if (!checkState() || isFetching.current) {
      return;
    }
    isFetching.current = true;
    const stage = CharacterGenerationStage.Personality;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = {
      Kinks: kinks,
      Name: name,
      Occupation: occupation[0],
      Personality: pesonality!,
      Type: personalityType!,
      IsPrivate: isPrivate,
    };
    onChoice(stage, data);
    isFetching.current = false;
  };

  useEffect(() => {
    if (!fetchedRef.current) {
      fetchedRef.current = true;

      CharacterAPI.getTextParams()
        .then(({ data }) => setTextParams(data))
        .finally(() => {});
    }
  });
  useEffect(() => {
    if (!onFilter) {
      return;
    }
    if (pesonality) {
      onFilter('Personality', pesonality);
    }
    if (occupation) {
      onFilter('Occupation', occupation[0]);
    }
    if (kinks.length) {
      onFilter('Kinks', JSON.stringify(kinks));
    }
  }, [kinks, occupation, pesonality]);

  useEffect(() => {
    setIsFilled(occupation.length === 1 && kinks.length === 3 && !!name);
  }, [occupation, kinks, name]);

  if (!textParams) {
    return null;
  }

  // console.log({ textParams });

  const { peronalityTextParams, kinkTextParams, occupationTextParams } =
    filterParams(textParams);

  return (
    <div className="w-100 h-100  d-flex flex-column gap-2 text-start ">
      {isFetching.current && <FullSizeLoader />}
      <div
        className={`overflow-y-auto horny-disable-scrollbar flex-column gap-6 d-flex  ${!onFilter ? 'pt-20  horny-border-bottom' : 'pt-20 pb-20'}`}
      >
        {onFilter && <div className="pt-7"></div>}
        {!onFilter && (
          <IntroBlock
            onButtonClick={() => randomise()}
            title={t('Personality')}
            buttonText={t('Random personality')}
            description={t('generation personality description')}
            shadow="horny-shadow-red"
          >
            </IntroBlock>
        )}
        { !onFilter &&
              <div
              className={`d-flex flex-column gap-2 text-start ${isFetching.current ? 'pe-none' : ''}`}
            >
              <div className="fs-5 fw-bold">{t('Private character')}</div>
                <PrivateCharacterSwitcher onChange={(value) => setIsPrivate(value)} IsPrivate={isPrivate}/>
              </div>  
        }
        {!onFilter && (
          <div
            className={`d-flex flex-column gap-2 text-start ${isFetching.current ? 'pe-none' : ''}`}
          >
            <div className="fs-5 fw-bold">{t('Character Name')}</div>

            <StringSelector
              placeholder={t('Enter Name')}
              onManualChange={(val) => setName(val)}
              isFetching={nameIsFetching}
              onGenerationCall={async () => {
                if (isFetching.current) {
                  return;
                }
                isFetching.current = true;
                return randomiseName().then(() => {
                  isFetching.current = false;
                });
              }}
              value={name}
            />
          </div>
        )}

        <div className="d-flex flex-column gap-2 text-start">
          <div className="d-flex justify-content-between">
            <div className="fs-5 fw-bold">{t('Character Personality')}</div>
            {onFilter && (
              <div
                className={`horny-text_tiny cursor-pointer ${!pesonality ? 'pe-none opacity-50' : ''}`}
                onClick={() => {
                  onFilter('Personality', null);
                  setPersonality(null);
                }}
              >
                <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
                {t('Clear filter')}
              </div>
            )}
          </div>
          <div className="d-flex flex-wrap justify-content-left gap-3 mt-2">
            {peronalityTextParams
              .sort((a, b) => {
                if (a.Subtitle < b.Subtitle) {
                  return -1;
                }
                if (a.Subtitle > b.Subtitle) {
                  return 1;
                }
                return 0;
              })
              // .filter(
              //   ({ Subtitle }) =>
              //     Subtitle === CharacterTextParamsSubtitle.Submissive
              // )
              .map((personalityParam, i) => (
                <PersonalityType
                  color={
                    onFilter ? TypePreviewColor.Grey : TypePreviewColor.Red
                  }
                  selected={personalityParam.Title === pesonality}
                  onSelect={() => {
                    setPersonality(personalityParam.Title);
                    setPesonalityType(personalityParam.Subtitle);
                  }}
                  kind={personalityParam.Subtitle}
                  title={t(personalityParam.Title as any)}
                  description={t(personalityParam.Text as any)}
                  emojiCode={personalityParam.Icon}
                />
              ))}
          </div>
          {/* <div className="d-flex justify-contents-start gap-3 mt-2">
            {peronalityTextParams
              .filter(
                ({ Subtitle }) =>
                  Subtitle === CharacterTextParamsSubtitle.Neutral
              )
              .map((personalityParam, i) => (
                <PersonalityType
                  color={
                    onFilter ? TypePreviewColor.Grey : TypePreviewColor.Red
                  }
                  selected={personalityParam.Title === pesonality}
                  onSelect={() => {
                    setPersonality(personalityParam.Title);
                    setPesonalityType(personalityParam.Subtitle);
                  }}
                  kind={CharacterTextParamsSubtitle.Neutral}
                  title={t(personalityParam.Title as any)}
                  description={t(personalityParam.Text as any)}
                  emojiCode={personalityParam.Icon}
                />
              ))}
          </div>
          <div className="d-flex justify-contents-start gap-3 mt-2">
            {peronalityTextParams
              .filter(
                ({ Subtitle }) =>
                  Subtitle === CharacterTextParamsSubtitle.Dominant
              )
              .map((personalityParam, i) => (
                <PersonalityType
                  color={
                    onFilter ? TypePreviewColor.Grey : TypePreviewColor.Red
                  }
                  selected={personalityParam.Title === pesonality}
                  onSelect={() => {
                    setPersonality(personalityParam.Title);
                    setPesonalityType(personalityParam.Subtitle);
                  }}
                  kind={CharacterTextParamsSubtitle.Dominant}
                  title={t(personalityParam.Title as any)}
                  description={t(personalityParam.Text as any)}
                  emojiCode={personalityParam.Icon}
                />
              ))}
          </div> */}
        </div>

        <div className="d-flex flex-column gap-2 text-start">
          <div className="d-flex justify-content-between">
            <div className="fs-5 fw-bold">
              {t('Character Kinks')} {kinks.length}
              /3
            </div>
            {onFilter && (
              <div
                className={`horny-text_tiny cursor-pointer ${!kinks.length ? 'pe-none opacity-50' : ''}`}
                onClick={() => {
                  onFilter('Kinks', null);
                  setKinks([]);
                }}
              >
                <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
                {t('Clear filter')}
              </div>
            )}
          </div>
          <TagSelector
            limit={3}
            height={205}
            tagType="kinks"
            onSelect={(kinks) => setKinks(kinks)}
            tags={kinkTextParams}
            selectedTags={kinks}
            borderClass="tags-selector-border"
            selectText={t('kinks')}
          />
        </div>

        <div className="d-flex flex-column gap-2 text-start">
          <div className="d-flex justify-content-between">
            <div className="fs-5 fw-bold">
              {t('Character Occupation')} {occupation.length}/1
            </div>
            {onFilter && (
              <div
                className={`horny-text_tiny cursor-pointer ${!occupation.length ? 'pe-none opacity-50' : ''}`}
                onClick={() => {
                  onFilter('Occupation', null);
                  setOccupation([]);
                }}
              >
                <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
                {t('Clear filter')}
              </div>
            )}
          </div>
          <TagSelector
            limit={1}
            height={205}
            tagType="occupation"
            onSelect={(occupation) => setOccupation(occupation)}
            tags={occupationTextParams}
            selectedTags={occupation}
            borderClass="tags-selector-border"
            selectText={t('occupation')}
          />
        </div>
        {onFilter && <div className="pb-5"></div>}
      </div>
      {!onFilter && (
        <div
          className="  m-auto  d-flex gap-4 flex-column align-items-end pb-4"
          style={{ bottom: 0 }}
        >
          {/* {onForward && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              {...(isFetching.current ? { disabled: true } : {})}
              onClick={() => onForward()}
              icon={IconSrc.Next}
              text={`Keep this personality`}
            ></HornyButton>
          )} */}
          {!!error ? (
            <div
              className={`text-center w-100 ${error ? 'scale-up-ver-bottom' : ''}`}
            >
              {t(error as any)}
            </div>
          ) : (
            <div className="opacity-0">{t('error')}</div>
          )}
          {!onFilter && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              // {...(!(isFilled || isFetching.current) ? { disabled: true } : {})}
              onClick={() => !isFetching.current && updateDraft()}
              icon={IconSrc.Next}
              text={`${isFetching.current ? t('Preparing...') : t('Choose story')}`}
            ></HornyButton>
          )}
        </div>
      )}
    </div>
  );
}
