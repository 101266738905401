import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { GenerateBotImagesRequest } from 'src/@types/common';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
} from 'src/services/API/CharacterAPI';
import { UserAPI } from 'src/services/API/UserAPI';
import {
  AnimParamInOutOpacityY100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from '../Subscription/Subscriptions';
import { ConfigureAppearance } from './ConfigureAppearance/ConfigureAppearance';
import { ConfigureStory } from './ConfigureStory/ConfigureStory';
import { ConfigureImageGenerationModel } from './ImageGenerationModel/ImageGenerationModel';
import { Personality } from './Personality/Personality';
import { PhotoChoice } from './PhotoChoice/PhotoChoice';
import './index.css';

const stagesOrder: CharacterGenerationStage[] = [
  CharacterGenerationStage.Personality,
  CharacterGenerationStage.Story,
  CharacterGenerationStage.ImageGenerationModel,
  CharacterGenerationStage.Appearance,
  CharacterGenerationStage.PhotoChoice,
];

export const getFirstNullStage = (
  characterDraft: CharacterGenerationDraftAPIModel
): keyof typeof CharacterGenerationStage => {
  const firstNullStage = stagesOrder.find((stage) => {
    return characterDraft[stage] === null;
  });
  return firstNullStage || CharacterGenerationStage.PhotoChoice;
};
const getNextStage = (
  stage: CharacterGenerationStage
): keyof typeof CharacterGenerationStage => {
  const currentStageIndex = stagesOrder.findIndex((stg) => {
    return stg === stage;
  });
  if (currentStageIndex === stagesOrder.length - 1) {
    return CharacterGenerationStage.PhotoChoice;
  }
  return stagesOrder[currentStageIndex + 1];
};

export type OnChoiceCallback = <
  Stage extends CharacterGenerationStage,
  StageData extends CharacterGenerationDraftAPIModel[Stage],
>(
  stage: Stage,
  data: StageData,
  genParams?: GenerateBotImagesRequest
) => Promise<any>;

export type CharacterGenerationProps = {
  characterGenerationDraft: CharacterGenerationDraftAPIModel;
  onUpdate?: (draft: CharacterGenerationDraftAPIModel) => any;
};

export function CharacterGeneration({
  characterGenerationDraft,
  onUpdate,
}: CharacterGenerationProps) {
  const { setUser, user} = useUserContext();
  const { addModal } = useModalContext();

  const [characterDraft, setCharacterDraft] =
    useState<CharacterGenerationDraftAPIModel>(characterGenerationDraft);

  const [stage, setStage] = useState<CharacterGenerationStage>(
    CharacterGenerationStage[getFirstNullStage(characterDraft)]
  );
  const [savedGenParams, setSavedGenParams] =
    useState<GenerateBotImagesRequest | null>(null);

  const { capture } = useAnalyticsContext();

  const regeneratePhotos = () =>
    updateDraft(
      CharacterGenerationStage.Appearance,
      characterDraft[CharacterGenerationStage.Appearance]
    );

 
 
  
  const updateDraft: OnChoiceCallback = (stageToUpdate, data, genParams) => {
    const characterToUpsert = {
      ...characterDraft,
      [stageToUpdate]: data,
    };

    return CharacterAPI.upsertDraft(characterToUpsert)
      .then(async ({ data }) => {
        if (genParams) {
          setSavedGenParams(genParams);
        }
        if (stageToUpdate === CharacterGenerationStage.Appearance) {
          return CharacterAPI.generateBotImage(
            genParams! || savedGenParams
          ).catch((e) => {
            if (Number(e.response?.status) === 402) {
              // console.log('catched payment error');
              addModal({
                children: (
                  <Subscriptions
                    source="pose_premium"
                    showReason={ShowSubscriptionsReason.Energy}
                  />
                ),
              });
            }
            return { data: characterDraft };
          });
        } else {
          return { data };
        }
      })
      .then(({ data }) => {
        if (data) {
          setCharacterDraft(data);

          capture({
            event: 'page_open',
            data: {
              page_name: 'Create_character_' + getNextStage(stageToUpdate),
            },
          });

          setStage(CharacterGenerationStage[getNextStage(stageToUpdate)]);

          onUpdate && onUpdate(data);

          UserAPI.get().then(({ data }) => setUser(data));
        }
      })
 
  };

  const updateDraftFromChildren = (draft: CharacterGenerationDraftAPIModel) => {
    setCharacterDraft(draft);
  };

  const currentStage = {
    [CharacterGenerationStage.Personality]: () => (
      <Personality
        onChoice={updateDraft}
        fetched={characterDraft[CharacterGenerationStage.Personality]}
        onForward={
          characterDraft[CharacterGenerationStage.Personality]
            ? () => setStage(CharacterGenerationStage.Story)
            : undefined
        }
      />
    ),
    [CharacterGenerationStage.Story]: () => (
      <ConfigureStory
        onChoice={updateDraft}
        fetched={characterDraft[CharacterGenerationStage.Story]}
        personality={characterDraft[CharacterGenerationStage.Personality]!}
        onBack={() => setStage(CharacterGenerationStage.Personality)}
        onForward={
          characterDraft[CharacterGenerationStage.Story]
            ? () => setStage(CharacterGenerationStage.Appearance)
            : undefined
        }
      />
    ),
    [CharacterGenerationStage.ImageGenerationModel]: () => (
      <ConfigureImageGenerationModel
        onBack={() => setStage(CharacterGenerationStage.Story)}
        fetched={characterDraft[CharacterGenerationStage.ImageGenerationModel]}
        onChoice={updateDraft}
        onForward={
          characterDraft[CharacterGenerationStage.ImageGenerationModel]
            ? () => setStage(CharacterGenerationStage.Appearance)
            : undefined
        }
      />
    ),
    [CharacterGenerationStage.Appearance]: () => (
      <ConfigureAppearance
        onForward={
          characterDraft[CharacterGenerationStage.PhotoChoice]
            ? () => setStage(CharacterGenerationStage.PhotoChoice)
            : null
        }
        model={characterDraft[CharacterGenerationStage.ImageGenerationModel]!}
        onChoice={updateDraft}
        fetched={characterDraft[CharacterGenerationStage.Appearance]}
        onBack={() => setStage(CharacterGenerationStage.ImageGenerationModel)}
        isGenerated={!!characterDraft[CharacterGenerationStage.PhotoChoice]}
      />
    ),
    [CharacterGenerationStage.PhotoChoice]: () => (
      <PhotoChoice
        updateDraftFromChildren={(draft) => updateDraftFromChildren(draft)}
        fetched={characterDraft[CharacterGenerationStage.PhotoChoice]!}
        onChoice={updateDraft}
        onBack={() => setStage(CharacterGenerationStage.Appearance)}
        regeneratePhotos={() => regeneratePhotos()}
        model={characterDraft[CharacterGenerationStage.ImageGenerationModel]!}
        appearance={characterDraft[CharacterGenerationStage.Appearance]}
      />
    ),
  }[stage];

  return (
    <div className="px-6 h-100 pb-6">
      <AnimatePresence mode="wait">
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={AnimParamInOutOpacityY100}
          transition={TransTweenAnticipate03}
          className="w-100 d-flex flex-column gap-2  h-100 text-start"
          key={stage}
        >
          {currentStage()}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export type GenerationCheckRefConfig = {
  [refName: string]: {
    value: any;
    ref: HTMLDivElement;
  };
};

// export const checkRefs = (refSet: GenerationCheckRefConfig) => {
//   Object.entries(refSet).forEach(([key, data]) => {
//     if ()
//   })
// }
