import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
import { Link } from 'src/components/Shared/Link/Link';
import { BotAPI, BotTournament } from 'src/services/API/BotAPI';
import { splitTextLines } from 'src/shared/utils';
import { TournamentInfo, TournamentInfoLocation } from './TournamentInfo';

export function TournamentList() {
  const { t } = useTranslation();
  const [tournaments, setTournaments] = useState<BotTournament[]>();
  useEffect(() => {
    BotAPI.getBotTournamentList().then(({ data }) => setTournaments(data)); // BotAPI.getTournamentBots(data[0].TournamentId))
  }, []);

  return tournaments ? (
    <div className="d-flex flex-column gap-6">
      <div className="my-2 opacity-75">
        {splitTextLines(t('tournaments info'))}
      </div>
      {tournaments.map((t) => {
        return (
          <TournamentInfo
            {...t}
            {...{ location: TournamentInfoLocation.List }}
            key={t.TournamentId}
          />
        );
      })}
      <div className="text-center mt-2 mb-6">
        <Link
          url="https://temptationgames.com/instructions/how-to-get-your-rewards"
          underlined={true}
          icon={IconSrc.Link}
          className="fw-bold"
        >
          {t('How to get rewards?')}
        </Link>
      </div>
    </div>
  ) : null;
}
