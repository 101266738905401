import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Authorization } from 'src/components/Authorization/Authorization';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
import { ImgIcon } from 'src/components/Shared/Icon/ImgIcon';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { Timer } from 'src/components/Shared/Timer/Timer';
import { Subscriptions } from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useUserContext } from 'src/context/User.context';

const msInDay = 1000 * 60 * 60 * 24;

export const subProposalStorageKey = 'showSubProposal';
export const upgradeProposalStorageKey = 'showUpgradeProposal';

// SET UPGRADE (User after the subscription)
export type UpgradeProposalType = {
  show: boolean;
  date: Date;
};

export const setUpgradeProposal = (days: number) => {
  const upgradeProposal: UpgradeProposalType = {
    show: true,
    date: new Date(Date.now() + days * msInDay),
  };
  localStorage.setItem(
    upgradeProposalStorageKey,
    JSON.stringify(upgradeProposal)
  );
};
// /SET UPGRADE

type ProposalType = 'subscription' | 'upgrade' | undefined;

export function useProposalType() {
  const { user } = useUserContext();
  const [proposalType, setProposalType] = useState<ProposalType>('subscription');

  // Показываем предложение апгрейда раз в 3 дня
  function showUpgradeProposal() {
    if (user?.MergedSubscription.Level === null) {
      return false;
    }
    const storedUpgradeProposalString = localStorage.getItem(
      upgradeProposalStorageKey
    );

    // Если нет предложения, ставим его и показываем (текущая дата)
    if (!storedUpgradeProposalString) {
      setUpgradeProposal(0);
      return true;
    }

    const storedUpgradeProposal: UpgradeProposalType = JSON.parse(
      storedUpgradeProposalString,
      (key, value) => {
        if (key === 'date') {
          return new Date(value);
        }
        return value;
      }
    );

    if (storedUpgradeProposal.date.getTime() > Date.now()) {
      return false;
    }

    // setUpgradeProposal(3);
    return true;
  }

  // Показываем предложение подписки, если нет подски, не показывали ранее или после 3 дней после последнего показа
  function showSubProposal() {
    if (user?.MergedSubscription.Level !== null) {
      return false;
    }
    const storedProposal = localStorage.getItem(subProposalStorageKey);
    if (!storedProposal) {
      return true;
    }
    const storedProposalAsTimestamp = Number(storedProposal);
    if (Number.isNaN(storedProposalAsTimestamp)) {
      localStorage.removeItem(subProposalStorageKey);
      return true;
    }

    const timeDiff = Date.now() - storedProposalAsTimestamp;

    if (timeDiff / 1000 / 60 / 60 / 24 >= 3) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (showUpgradeProposal()) {
      setProposalType('upgrade');
    } else if (showSubProposal()) {
      setProposalType('subscription');
    } else {
      setProposalType(undefined);
    }
  }, [user]);

  return proposalType;
}

type ProposalProps = {
  proposalType: 'subscription' | 'upgrade';
  onClose: () => any;
};

export function Proposal({ proposalType, onClose }: ProposalProps) {
  const { addModal } = useModalContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { isNutaku } = useNutakuContext();

  const handleButtonClick = () => {
    if (user?.IsAnonymous) {
      return addModal({
        showBalance: false,
        children: <Authorization />,
        className:
          'tw-z-50 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600',
      });
    }
    return addModal({
      children: (
        <Subscriptions
          source="offer_button"
          defaultTab={
            proposalType === 'subscription' ? 'subscription' : 'upgrade'
          }
        />
      ),
      showBalance: false,
    });
  };

  return (
    <div className="tw-w-full tw-px-12 tw-flex tw-flex-row tw-gap-6 tw-justify-center tw-items-center tw-bg-gradient-to-b tw-from-horny-gray-600 tw-to-horny-gray-900 tw-border-t tw-border-white tw-h-[60px] tw-relative">
      <TemptationsButton color="red" size="base" onClick={handleButtonClick}>
        <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-text-sm">
          <ImgIcon src={IconSrc.Premium} size="sm" />
          <div className="tw-leading-tight tw-line-clamp-2">
            {proposalType === 'subscription' && (
              <Trans
                i18nKey="Premium up to new"
                values={{ percent: isNutaku ? 80 : 33 }}
                components={[
                  <span className="tw-underline tw-underline-offset-2 tw-uppercase" />,
                ]}
              />
            )}
            {proposalType === 'upgrade' &&
              t('Upgrade your subscription at a big discount!')}
          </div>
          <div className="tw-px-3 tw-py-1 tw-rounded-full tw-bg-black tw-bg-opacity-50">
            <Timer onTimeReach={() => {}} />
          </div>
        </div>
      </TemptationsButton>
      <motion.div
        onClick={() => {
          onClose();
          if (proposalType === 'subscription') {
            localStorage.setItem(subProposalStorageKey, Date.now().toString());
          }
          if (proposalType === 'upgrade') {
            setUpgradeProposal(3);
          }
        }}
        className="tw-cursor-pointer tw-p-2 tw-absolute tw-right-2"
        whileTap={{ scale: 0.8 }}
      >
        <FontAwesomeIcon icon={faXmark} size="xl" />
      </motion.div>
    </div>
  );
}
