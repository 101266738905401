//import { AnimatePresence, motion } from 'framer-motion';
import { MessageTypeEnum } from 'src/@types/common';
import { useUserContext } from 'src/context/User.context';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import { replaceUserName } from 'src/shared/helpers';
import { cn, formatText, splitTextLines } from 'src/shared/utils';
import { ChatMedia } from '../ChatMedia/ChatMedia';
import { Replay, ReplayContent } from './Replay/Replay';
import { UnlockMedia } from './UnlockMedia/UnlockMedia';

import { faSpinnerThird, faWarning } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRotate, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  ChatMessage as ChatMessageType,
  MessageSenderEnum,
  MessageStatusEnum,
} from 'src/@types/common';
import { handleRequestPhotoProps } from '../Chat';

export type ChatMessageProps = ChatMessageType & {
  canRegenerate: boolean;
  onScroll?: () => any;
  onRegenerate: () => any;
  onMediaRequest: () => any;
  IsBotAuthor: boolean;
  requestRandomMedia: (data: handleRequestPhotoProps) => any;
  model: ImageGenerationModel;
  mediaChoiseDisabled: boolean;
  message: ChatMessageType;
  botId: string;
  isRestartFrom: boolean;
  onRestartFrom: (messageId: string) => any;
};

const circleAnimation = {
  scale: [1, 1.2, 1],
  opacity: [0.75, 1, 0.75],
};

const baseCircleTransition = {
  duration: 1,
  repeat: Infinity,
  ease: 'easeInOut',
};

export function ChatMessage({
  onRegenerate,
  IsBotAuthor,
  requestRandomMedia,
  canRegenerate,
  mediaChoiseDisabled,
  model,
  message,
  botId,
  onRestartFrom,
  isRestartFrom = true,
}: ChatMessageProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const messageText = message.Text
    ? message.SkipLLM && message.Text && !message.Text.includes('Intro')
      ? t(message.Text as any)
      : message.MessageSender === MessageSenderEnum.Bot
        ? formatText(replaceUserName(message.Text, user!) || '')
        : splitTextLines(replaceUserName(message.Text, user!) || '')
    : null;

  return (
    <div
      className={cn(
        'tw-flex tw-gap-2',
        message.MessageSender === MessageSenderEnum.Bot && 'tw-justify-start',
        message.MessageSender === MessageSenderEnum.User && 'tw-justify-end',
        message.MessageSender === MessageSenderEnum.Server &&
          'tw-justify-center'
      )}
    >
      <div className="tw-flex tw-gap-2 tw-flex-col tw-max-w-[75%]">
        <div
          className={cn(
            'tw-p-4 tw-gap-1 tw-flex tw-flex-col',
            message.MessageSender === MessageSenderEnum.Bot &&
              'tw-rounded-lg tw-rounded-tl-none tw-bg-horny-gray-900 tw-shadow tw-bg-opacity-95',
            message.MessageSender === MessageSenderEnum.User &&
              'tw-rounded-lg tw-rounded-br-none tw-bg-horny-gray-600 tw-shadow tw-bg-opacity-95',
            message.MessageSender === MessageSenderEnum.Server &&
              'tw-rounded-lg tw-bg-black tw-shadow tw-bg-opacity-75'
          )}
        >
          {/* Media Loading : готовится, есть GenerationInfo, но не готово */}
          {message.MessageStatus === MessageStatusEnum.preparing &&
            !!message.MediaGenerationInfo && (
              <div className="tw-w-48 tw-aspect-square tw-bg-black tw-bg-opacity-55 tw-rounded-md tw-flex tw-flex-col tw-gap-3 tw-items-center tw-justify-center">
                <div>{t('Media generating')}...</div>
                <FontAwesomeIcon icon={faSpinnerThird} size="xl" spin />
              </div>
            )}

          {/* Text Loading  : готовится, нет GenerationInfo, но не готово */}
          {message.MessageStatus === MessageStatusEnum.preparing &&
            !message.MediaGenerationInfo && (
              <div className="tw-flex tw-items-center tw-gap-2">
                <motion.div
                  animate={circleAnimation}
                  transition={baseCircleTransition}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="xs"
                    className="tw-aspect-square"
                  />
                </motion.div>
                <motion.div
                  animate={circleAnimation}
                  transition={{ ...baseCircleTransition, delay: 0.2 }}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="xs"
                    className="tw-aspect-square"
                  />
                </motion.div>
                <motion.div
                  animate={circleAnimation}
                  transition={{ ...baseCircleTransition, delay: 0.4 }}
                >
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="xs"
                    className="tw-aspect-square"
                  />
                </motion.div>
              </div>
            )}

          {/* Text : готово, есть текст */}
          {messageText &&
            message.MessageStatus !== MessageStatusEnum.preparing &&
            message.MessageType !== MessageTypeEnum.Error && (
              <div>{messageText}</div>
            )}

          {/* Random Image */}
          {message.MessageType === MessageTypeEnum.RandomImage && (
            <UnlockMedia
              mediaChoiseDisabled={mediaChoiseDisabled}
              mediaType={message.MessageType}
              requestRandomMedia={(data) => requestRandomMedia(data)}
              IsBotAuthor={IsBotAuthor}
              BotId={botId}
              model={model}
            />
          )}

          {/* Media : готово, есть медиа */}
          {message.Media &&
            message.MessageType === MessageTypeEnum.Normal &&
            message.MessageStatus === MessageStatusEnum.ready && (
              <ChatMedia media={message.Media} />
            )}

          {/* Error : готово, есть ошибка */}
          {message.MessageType === MessageTypeEnum.Error &&
            message.MessageStatus !== MessageStatusEnum.preparing && (
              <div className="tw-w-48 tw-h-48 tw-aspect-square tw-bg-black tw-bg-opacity-55 tw-rounded-md tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-p-2">
                <FontAwesomeIcon icon={faWarning} size="xl" />
                <div className="tw-font-bold">{t('Error occured')}</div>
                <div className="tw-text-center tw-text-sm">
                  {t('Energy has been refunded. Try regeneratating the image.')}
                </div>
              </div>
            )}
        </div>
        {/* Restart from here */}
        {isRestartFrom &&
          message.MessageType === MessageTypeEnum.Normal &&
          message.MessageSender === MessageSenderEnum.Bot &&
          message.MessageStatus === MessageStatusEnum.ready && (
            <div className={cn('tw-w-full tw-flex tw-justify-start')}>
              <motion.div
                className="tw-font-bold tw-px-2 tw-py-1 tw-bg-black tw-bg-opacity-50 tw-rounded-md tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-horny-red-500 tw-cursor-pointer"
                whileTap={{ scale: 0.9 }}
                onClick={() => onRestartFrom(message.Id)}
              >
                <FontAwesomeIcon icon={faArrowsRotate} />
                <div>{t('Restart chat from here')}</div>
              </motion.div>
            </div>
          )}
      </div>

      {canRegenerate && (
        <Replay
          BotId={botId}
          isRegenerating={
            message.MessageStatus === MessageStatusEnum.pendingLink ||
            message.MessageStatus === MessageStatusEnum.preparing
          }
          onReplay={() => onRegenerate()}
          IsBotAuthor={IsBotAuthor}
          content={
            message.MediaGenerationInfo
              ? ReplayContent.Media
              : ReplayContent.Text
          }
        />
      )}
    </div>
  );
}
