import { MediaPreviewAPIModel } from 'src/services/API/MediaAPI';
import { ChatMediaPreview } from './ChatMediaPreview';
import './index.css';

export type ChatMediaProps = {
  media: MediaPreviewAPIModel[];
};
export function ChatMedia({ media }: ChatMediaProps) {
  return (
    <div>
      {media.map(({ MediaType, PreviewUrl, MediaId }) => {
        return (
          <ChatMediaPreview
            key={MediaId}
            MediaId={MediaId}
            PreviewUrl={PreviewUrl}
            MediaType={MediaType}
          />
        );
      })}
    </div>
  );
}
