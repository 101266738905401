import { useSearchParams } from 'react-router-dom';
import { Link } from 'src/components/Shared/Link/Link';

export function SignUpLanding() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('url');
  // useEffect(() => {
  //   if (codeIsProcessing.current || !user) {
  //     return;
  //   }
  //   codeIsProcessing.current = true;
  //   code &&
  //     UserAPI.addPatreonUser(code)
  //       // .then(() =>
  //       //   UserAPI.sendPatreonWebhook()
  //       //     .then(() => UserAPI.get().then(({ data }) => setUser(data)))
  //       .then(() => {
  //         window.close();
  //       })

  //       .finally();
  // });

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      {code ? (
        <div className="rounded-4 horny-bg-dark_opac p-4">
          <div className="text-center">Please verify you're a human</div>
          <div className="text-center mt-4">
            <Link target="_self" url={code}>
              I'm a human, not like some others!
            </Link>
          </div>
        </div>
      ) : (
        <div>No URL provided</div>
      )}
    </div>
  );
}
