import { useTranslation } from 'react-i18next';
import { IconSrc, IconSize, Icon } from 'src/components/Shared/Icon/Icon';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { BotTournamentPlace } from 'src/services/API/BotAPI';

const getPlaceMedalBlock = (rank: number) => {
  const topPlacesMedalIcons: Record<string, IconSrc> = {
    1: IconSrc.MedalGold,
    2: IconSrc.MedalSilver,
    3: IconSrc.MedalBronze,
  };

  if (rank < 4) {
    return (
      <Icon src={topPlacesMedalIcons[rank.toString()]} size={IconSize.Small} />
    );
  } else {
    return (
      <div
        style={{ width: 16 }}
        className="fw-bold horny-text_tinies w-100 h-100 d-flex align-items-center"
      >
        <span>4-10</span>
      </div>
    );
  }
};

export function TournamentPlace(place: BotTournamentPlace) {
  const { t } = useTranslation();
  const { isNutaku } = useNutakuContext();
  return (
    <div className="d-flex gap-2">
      <div>{getPlaceMedalBlock(place.Rank)}</div>
      <div>—</div>
      <div>
        {isNutaku && place.PrizeNutaku
          ? t(place.PrizeNutaku as any)
          : t(place.PrizeWeb as any)}
      </div>
      <div>
        {(place.IconNutaku || place.IconWeb) && (
          <Icon
            size={IconSize.Small}
            src={
              isNutaku && place.IconNutaku
                ? (place.IconNutaku as IconSrc)
                : (place.IconWeb as IconSrc)
            }
          />
        )}
      </div>
    </div>
  );
}
