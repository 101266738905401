import {
  GetSingleMediaRequest,
  GetSingleMediaResponse,
} from 'src/@types/common';
import { APIBase, PaginationCommonParams, PaginationResponse } from './APIBase';
import { BotRequestParams } from './BotAPI';
import { NudityLevel } from './ResourcesAPI';

export enum MediaType {
  Photo = 'image',
  Video = 'video',
  Audio = 'audio',
}

export type MediaAPIModel = {
  MediaId: string;
  MediaType: MediaType;
  CoverUrl: string;
  Author: string;
  LikesCount: number;
  Liked: boolean;
  SharedWithCommunity: boolean;
  AddedToMedia: boolean;
  CanShareWithCommunity: boolean;
  Name: string;
  BotId: string;
  CanAddToMedia: boolean;
  IsPrivate: boolean;
};

export interface MediaPreviewAPIModel {
  MediaType: MediaType;
  MediaId: string;
  PreviewUrl: string;
}

export type MediaResponse = {
  Media: MediaPreviewAPIModel[];
};

export type MediaCommonParams = {
  MediaId: string;
};

export type MediaRequestParams = BotRequestParams & PaginationCommonParams;

export type FilterMediaTypeParams = {
  type?: MediaType;
};

export type MediaRequestBase = {
  PromptId: string;
  NudityLevel: NudityLevel;
};

export type ReporMediaData = {
  MediaId: string;
  ReportedItems: string[];
  Message: string;
};

export class MediaAPI extends APIBase {
  static domain = 'media';

  static async getCommunityMedia(params: MediaRequestParams) {
    return await this.client.get<PaginationResponse<MediaResponse>>(
      `getcommunitymedia`,
      {
        params,
      }
    );
  }

  static getDialogueMedia(params: MediaRequestParams) {
    return this.client.get<PaginationResponse<MediaResponse>>(
      `getdialoguemedia`,
      {
        params,
      }
    );
  }

  static getSingleMedia(params: GetSingleMediaRequest) {
    return this.clientV2.get<GetSingleMediaResponse>('v1/media/single_media', {
      params,
    });
    // return this.client.get<MediaAPIModel>('getsinglemedia', {
    //   params,
    // });
  }

  static shareWithCommunity(data: MediaCommonParams) {
    return this.client.patch<any>(`publishmedia`, data);
  }

  static removeFromCommunity(data: MediaCommonParams) {
    return this.client.delete<any>(`unpublishmedia`, { data });
  }

  static like(data: MediaCommonParams) {
    return this.client.post<any>(`likemedia`, data);
  }

  static dislike(data: MediaCommonParams) {
    return this.client.delete<any>(`dislikemedia`, { data });
  }

  static add(data: MediaCommonParams) {
    return this.client.post<any>(`addmedia`, data);
  }

  static remove(data: MediaCommonParams) {
    return this.client.delete<any>(`removemedia`, { data });
  }

  static getMediaProxy(url: string) {
    return this.client.get('proxymedia', {
      params: {
        url,
      },
      responseType: 'blob',
    });
  }

  static reportMedia(data: ReporMediaData) {
    return this.client.post('ReportMedia', data);
  }
}
