export const checkState = (
  errorConfig: Record<string, boolean>,
  setter: (e: string) => any
): boolean => {
  // const errors = {
  //   [appContent['generation-personality-enter-name']]: !name,
  //   'Enter kinks': kinks.length < 3,
  //   'Enter occupation': !occupation.length,
  //   'Enter personality': !personalityType,
  // };
  return Object.entries(errorConfig).every(([key, val]) => {
    if (val === true) {
      setTimeout(() => setter(key), 40);
      return false;
    }
    return true;
  });
};
