import { create } from 'zustand';

type State = {
  selectedCutromPrompts: string[];
};

type Actions = {
  setSelectedCustomPrompts: (value: string[]) => void;
};

export const usePromptPickerStore = create<State & Actions>((set) => ({
  selectedCutromPrompts: [],
  setSelectedCustomPrompts: (value: string[]) =>
    set({ selectedCutromPrompts: value }),
}));
