import { Share } from '@capacitor/share';
import { MediaAPI } from 'src/services/API/MediaAPI';
import { UserAPIModel } from 'src/services/API/UserAPI';

export type ShareDataProps = {
  url: string;
  name: string;
  mediaUrl: string;
};

export const shuffleArray = <T>(items: T[]): T[] => {
  return [...items].sort(() => Math.random() - 0.5);
};

export const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const replaceUserName = (text: string, user: UserAPIModel) => {
  if (!user) {
    return text;
  }
  const name = user ? user.Nickname : 'vis-a-vis';
  const replacedText = text.replace(/({{.*?}})/g, name);
  //  .replace(/[{}]/g, '');
  return replacedText;
};

export type ShareNativeProps = {
  title: string;
  text: string;
  url: string;
  dialogTitle: string;
};

export const shareNative = async (data: ShareNativeProps) => {
  console.log('Получили шарилку');
  await Share.share({
    title: data.title,
    text: data.text,
    url: data.url,
    dialogTitle: data.dialogTitle,
  });
  console.log('Поделились');
};

export const copyURLToClibpoard = (urlToShare: string) => {
  return new Promise((res) =>
    navigator.clipboard.writeText(urlToShare).then(
      () => {
        console.log('Content copied to clipboard');
        res(true);
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        console.error('Failed to copy');
        /* Rejected - text failed to copy to the clipboard */
      }
    )
  );
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

export const shareData = (data: ShareDataProps) => {
  // console.log({ data, ext: data.mediaUrl.split('.') });
  //   if (!navigator.share) {
  // copyURLToClibpoard(data.url);
  //   } else {
  return new Promise((res) => {
    const [ext] = data.mediaUrl.split('.').reverse();
    const [extension] = ext.split('?');
    console.log({ name: 'test.' + extension });
    MediaAPI.getMediaProxy(data.mediaUrl)
      .then(({ data: blob }) => {
        const shareData = {
          title: 'Nice girl ' + data.name,
          text: 'Look at this pretty girl! ' + data.mediaUrl,
          url: data.url,
          files: [
            new File([blob], data.name.replace(/ /g, '') + '.' + extension, {
              type: blob.type,
            }),
          ],
        };

        navigator.share(shareData).catch(() => {});
      })
      .then(() => console.log('Successful share')) //this is for sucess
      .catch((error) => console.log('Error sharing', error))
      .finally(() => res(true));
  });
};

export const getEnvValue = (key: string) => {
  const value = process.env[key];
  if (!value) {
    throw new Error(`process env key ${key} is undefined.`);
  }
  return value;
};

export const userHandleRegex = /^[^\s]+$/;

export const verifyUserHandle = (userHandle: string) =>
  userHandleRegex.test(userHandle);

export const isMobile = () =>
  !!navigator.userAgent &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const getImgUrl = (filePath: string) =>
  `${process.env.REACT_APP_CDN_HOST}/img/${filePath}`;

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getNutakuUserIdParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nutakuUserIdParam = urlParams.get('opensocial_viewer_id');
  return nutakuUserIdParam;
};


export const animalNames = [
  "dog", "cat", "elephant", "giraffe", "lion", "tiger", "bear", "zebra", "monkey", 
  "kangaroo", "panda", "koala", "rhino", "hippo", "leopard", "cheetah", "wolf", 
  "fox", "deer", "moose", "squirrel", "rabbit", "otter", "skunk", "rat", 
  "bat", "owl", "eagle", "falcon", "parrot", "penguin", "dolphin", "whale", 
  "shark", "seal", "octopus", "lobster", "crab", "jellyfish", "starfish", 
  "turtle", "snake", "frog", "alligator", "crocodile", "lizard", "ant", "bee", 
  "butterfly", "spider"
];