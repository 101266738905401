import { LLMMemory, LLMModelTitle } from "./LLM";
import { MediaType } from "./Media";
import { NudityLevel } from "./MediaPrompts";

export type Tokens = {
  completion_tokens: number;
  prompt_tokens: number;
  total_tokens: number;
} | null;

export interface BotTextRequest {
  BotId: string;
  Text: string;
}

export interface UserReply {
  Text: string;
  MessageId: string;
  Tokens?: Tokens;
}

export type UserReplyResponse = UserReply;

export interface UserReplyRequest {
  BotId: string;
  Regenerate?: boolean;
}

export enum MessageSenderEnum {
  User = "user",
  Bot = "assistant",
  Server = "notice",
}

export enum MessageStatusEnum {
  preparing = "preparing",
  ready = "ready",
  pendingLink = "pendingLink",
}

export enum MessageTypeEnum {
  Normal = "normal",
  RandomImage = "randomImage",
  RandomVideo = "randomVideo",
  RandomAudio = "randomAudio",
  Error = "error",
}

export interface BotTextResponse {
  Messages: ChatMessage[];
  Tokens: Tokens;
}

// OK, но мы не отвечает BotId / UserId
export interface ChatMessage {
  Id: string;
  LinkedMessageId?: string | null;
  Media?: ChatMessageMedia[] | null;
  MediaGenerationInfo?: ChatMessageMediaGenerationInfo | null;
  MessageSender: MessageSenderEnum;
  MessageStatus: MessageStatusEnum;
  MessageType: MessageTypeEnum;
  SkipLLM: boolean;
  Text?: string | null;
  Timestamp: Date;
}

export interface ChatMessageMedia {
  MediaId: string;
  MediaType: MediaType;
  PreviewUrl: string;
  Url?: string | null;
}

export interface ChatMessageMediaGenerationInfo {
  NudityLevel: NudityLevel;
  Place: string;
  PromptId: string;
  CustomPrompt: string;
  Type: "custom" | "predefined";
}

export interface RegenerateMessageRequst {
  BotId: string;
  MessageId: string;
}

export type RegenerateMessageResponse = ChatMessage;

export type RegenerateMessageResponseV2 = {
  Message: ChatMessage;
  Tokens: Tokens;
};

export interface RefetchMessagesRequest {
  MessageIds: string[];
}

export interface RefetchMessagesResponse {
  Messages: ChatMessage[];
}

export interface updateLLMMemory {
  LLMMemory: LLMMemory;
}

export interface updateLLMModel {
  LLMModel: LLMModelTitle;
}

export interface RequestPhotoRequest {
  BotId: string;
  PromptId?: string;
  NudityLevel: NudityLevel;
  CustomPrompt?: string[];
  Type: "custom" | "predefined";
}

export interface RequestPhotoResponse {
  Message: ChatMessage;
}

export interface RegeneratePhotoRequest {
  BotId: string;
  MessageId: string;
}

export interface RegeneratePhotoResponse {
  Message: ChatMessage;
}

export interface DeleteMessagesAfterMessageIdRequest {
  MessageId: string;
}

export interface DeleteMessagesAfterMessageIdResponse {
  Result: boolean;
}
