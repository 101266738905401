import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useModalContext } from 'src/context/Modal.Context';
import { BotAPI, FilterQuery, TopBot } from 'src/services/API/BotAPI';
import { splitTextLines } from 'src/shared/utils';
import { TopBotList } from '../Shared/TopBotList';
import { countFilters } from '../Shared/utils';
import { NoBots } from './NoBots';
import { TopBotFilters } from './TopBotFilters';
const TOP_BOTS_LIMIT = 20;
export function TopBotsGlobal() {
  const { t } = useTranslation();
  const [topBots, setTopBots] = useState<TopBot[]>();
  const [isFetching, setIsFetching] = useState(true);
  const [storedFilters, setStoredFilters] = useState<FilterQuery>();
  const [filtersCount, setFiltersCount] = useState(0);
  const [limitReached, setLimitReached] = useState(false);
  useEffect(() => {
    BotAPI.getTopBots({
      limit: TOP_BOTS_LIMIT,
      offset: 0,
    })
      .then(({ data }) => {
        setTopBots(data);
        if (data.length < TOP_BOTS_LIMIT) {
          setLimitReached(true);
        }
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  }, []);

  const { addModal, removeModal } = useModalContext();
  // const highestOwnBotRank = topBots && Math.max(...topBots.filter((tB => tB.IsOwnBot)).map(tB => tB.Rank));
  const getNextBots = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    const actualLimit = topBots!.filter((tB) => !tB.IsOwnBot).length;
    BotAPI.getTopBots({
      limit: TOP_BOTS_LIMIT,
      offset: actualLimit,
      ...(storedFilters ? { filters: storedFilters } : {}),
    })
      .then(({ data }) => {
        setTopBots((prev) => {
          const sortedBots = [...prev!, ...data].sort((p, n) =>
            p.Rank > n.Rank ? 1 : -1
          );
          const newBots: TopBot[] = [];
          sortedBots.forEach((b) => {
            if (newBots.find((nB) => nB.BotId === b.BotId)) {
              return;
            }
            newBots.push(b);
          });
          return newBots;
        });
        if (data.length < TOP_BOTS_LIMIT) {
          setLimitReached(true);
        }
      })
      .catch(() => {})
      .finally(() => setIsFetching(false));
  };
  return (
    <div className="w-100 d-flex flex-column gap-2 pb-5">
      {isFetching && <FullSizeLoader />}
      <div className="my-2 opacity-75">
        {splitTextLines(t('top bots info'))}
      </div>

      <div className="d-flex justify-content-end gap-3 align-items-center mb-4">
        <div
          className={`horny-text_very_tiny d-flex   align-items-center  cursor-pointer ${filtersCount === 0 ? 'pe-none opacity-50' : ''}`}
          onClick={() => {
            setIsFetching(true);
            BotAPI.getTopBots({
              limit: TOP_BOTS_LIMIT,
              offset: 0,
            })
              .then(({ data }) => {
                setFiltersCount(0);
                setStoredFilters(undefined);
                setTopBots(data);
                if (data.length < TOP_BOTS_LIMIT) {
                  setLimitReached(true);
                }
              })
              .catch(() => {})
              .finally(() => setIsFetching(false));
          }}
        >
          <Icon src={IconSrc.Clear} size={IconSize.XSmall} />
          {t('Clear filters')}
        </div>
        <HornyButton
          height={HornyButtonHeight.Short}
          onClick={() => {
            const modal = addModal({
              className: 'horny-bg-gradient-gray-tab',
              children: (
                <TopBotFilters
                  storedFilters={storedFilters}
                  onSelect={(filters) => {
                    removeModal(modal);

                    BotAPI.getTopBots({
                      limit: TOP_BOTS_LIMIT,
                      offset: 0,
                      filters,
                    })
                      .then(({ data }) => {
                        setTopBots(data);
                        setFiltersCount(() => countFilters(filters));
                        setStoredFilters(() => filters);
                        if (data.length < TOP_BOTS_LIMIT) {
                          setLimitReached(true);
                        }
                      })
                      .catch(() => {})
                      .finally(() => {});
                  }}
                />
              ),
            });
          }}
        >
          <div className="d-flex gap-1 align-items-center px-2">
            <Icon src={IconSrc.Find} size={IconSize.XSmall} />
            {t('Filters')}
            {filtersCount > 0 && (
              <div className="horny-bg-dark_half px-2 py-1 rounded-4 horny-text_very_tiny ms-1">
                {filtersCount}
              </div>
            )}
          </div>
        </HornyButton>
      </div>
      {!topBots ? (
        <FullSizeLoader />
      ) : topBots.length === 0 ? (
        <NoBots></NoBots>
      ) : (
        <TopBotList topBots={topBots} />
      )}
      {!limitReached && (
        <div className="w-100 d-flex justify-content-center align-items-center mt-3 pb-5">
          <HornyButton
            height={HornyButtonHeight.Medium}
            onClick={() => getNextBots()}
          >
            <div className="text-center w-100">{t('See more characters')}</div>
          </HornyButton>
        </div>
      )}
    </div>
  );
}
