import {
  faBookmark,
  faBoxArchive,
  faCamera,
  faCommentDots,
  faHeart,
  faLock,
  faShare,
  // faShareAlt,
  // faShareAltSquare,
  // faShareFromSquare
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { ArkAvatar } from 'src/components/Shared/Ark/ArkAvatar';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useUserContext } from 'src/context/User.context';
// import { ConnectionAPIModel } from 'src/services/API/ConnectionsAPI';
import { faDownToLine, faUpToLine } from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConnectionsResponse,
  ConnectionStatus,
  Connection as ConnectionType,
  DeleteConnectionRequest,
} from 'src/@types/common';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { showToast, ToastImages } from 'src/components/Shared/Ark/ArkToast';
import { TemptationsButton } from 'src/components/Shared/TemptationsButton';
import { appConfig } from 'src/config/app';
import { ModalContext } from 'src/context/Modal.Context';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { copyURLToClibpoard, replaceUserName, shareData, shareNative } from 'src/shared/helpers';
import { cn, formatStory } from 'src/shared/utils';
import { LongPressEventType, useLongPress } from 'use-long-press';
import '../../../../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import { useConnectionsStore } from '../ConnectionsStore';
import { useOpenChat } from '../OpenChatModal';
import './index.css';
import { NutakuEnv, useNutakuContext } from '../../../context/Nutaku.Context';
import { NoCopyPopup } from '../../Shared/NoCopyPopup/NoCopyPopup';

export type ConnectionProps = {
  connection: ConnectionType;
  lastItemRef?: React.Ref<HTMLDivElement>;
};

export function Connection({ connection, lastItemRef }: ConnectionProps) {
  const deleteLongPressCallback = useCallback(() => {
    showToast({
      title: t('Delete button?'),
      description: t('A long awaited feature is here!'),
      support: false,
      image: ToastImages.did_you_do_it,
    });
  }, []);

  const deleteLongPress = useLongPress(deleteLongPressCallback, {
    threshold: 3000,
    detect: LongPressEventType.Touch,
  });

  const { addModal, removeModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const storyRef = useRef(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const [isStoryHidden, setIsStoryHidden] = useState(true);

  const [status, setStatus] = useState<ConnectionStatus>(connection.Status);
  const [prevStatus, setPrevStatus] = useState<ConnectionStatus>(
    connection.Status
  );
  const currentFilter = useConnectionsStore((state) => state.filter);
  const onlyOwn = useConnectionsStore((state) => state.onlyOwn);
  const setIsChatOpen = useConnectionsStore((state) => state.setIsChatOpen);

  const { user } = useUserContext();
  const { isNutaku, nutakuEnv } = useNutakuContext();

  const queryClient = useQueryClient();

  const { capture } = useAnalyticsContext();

  const { loadChat, isChatLoading } = useOpenChat();

  const deleteMutation = useMutation({
    mutationFn: (data: DeleteConnectionRequest) =>
      ConnectionsAPI.deleteConnection(data),
    onMutate: (data) => {
      const previousConnections = queryClient.getQueryData<
        InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
      >(['Connections', onlyOwn, currentFilter]);

      queryClient.setQueryData<
        InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
      >(['Connections', onlyOwn, currentFilter], (oldData) => {
        if (!oldData) return undefined;
        const newPages = oldData.pages.map((page) => ({
          ...page,
          data: {
            ...page.data,
            Connections: page.data.Connections.filter(
              (conn) => conn.BotId !== connection.BotId
            ),
          },
        }));
        return {
          ...oldData,
          pages: newPages,
        };
      });

      return { previousConnections };
    },
    onError: (err, variables, context) => {
      if (context?.previousConnections) {
        queryClient.setQueryData(
          ['Connections', onlyOwn, currentFilter],
          context.previousConnections
        );
      }

      showToast({
        title: t('Error deleting connection'),
        description: t(
          'An error occured while deleting connection. Please try again later.'
        ),
        support: true,
      });
    },
  });

  const { data, mutate, isPending, isError, error } = useMutation({
    mutationFn: async (newStatus: ConnectionStatus) => {
      ConnectionsAPI.changeConnectionStatusNest({
        BotId: connection.BotId,
        Status: newStatus,
      });
    },
    onMutate: async (newStatus: ConnectionStatus) => {
      setPrevStatus(status);
      setStatus(newStatus);

      // delay
      await new Promise((r) => setTimeout(r, 100));

      if (
        currentFilter !== 'All' &&
        ((currentFilter === 'Favorite' && newStatus !== 'Favorite') ||
          (currentFilter === 'Archived' && newStatus !== 'Archived') ||
          (currentFilter === 'Untagged' && newStatus !== null))
      ) {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
        >(['Connections', onlyOwn, currentFilter], (oldData) => {
          if (!oldData) return undefined;
          const newPages = oldData.pages.map((page) => ({
            ...page,
            data: {
              ...page.data,
              Connections: page.data.Connections.filter(
                (conn) => conn.BotId !== connection.BotId
              ),
            },
          }));
          return {
            ...oldData,
            pages: newPages,
          };
        });
      } else {
        queryClient.setQueryData<
          InfiniteData<AxiosResponse<ConnectionsResponse, any>, unknown>
        >(['Connections', onlyOwn, currentFilter], (oldData) => {
          if (!oldData) return undefined;

          const newPages = oldData.pages.map((page) => ({
            ...page,
            data: {
              ...page.data,
              Connections: page.data.Connections.map((conn) => {
                if (conn.BotId === connection.BotId) {
                  return {
                    ...conn,
                    Status: newStatus,
                  };
                } else {
                  return conn;
                }
              }),
            },
          }));

          return {
            ...oldData,
            pages: newPages,
          };
        });
      }
    },

    onError: () => {
      setStatus(prevStatus);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['Connections'],
        exact: false,
        refetchType: 'inactive',
      });
      // Можно сразу рефетчить, но нет смысла
      // await queryClient.refetchQueries({
      //   queryKey: ['Connections', onlyOwn, currentFilter],
      //   type: 'active',
      //   exact: true,
      // });
    },
  });
  const [shareText, setShareText] = useState(t('Share'));


  useEffect(() => {
    const isEllipsisActive = (el: HTMLDivElement) => {
      return el.offsetHeight < el.scrollHeight;
    };

    if (storyRef.current && isEllipsisActive(storyRef.current)) {
      setIsEllipsisActive(true);
    }
  }, []);
  const botLinkToShare = !isNutaku
  ? `${window.location.origin}?GirlId=${connection.BotId}`
  : `${appConfig.nutaku.nutakuShareLink}?BotId=${connection.BotId}&CreatorCode=${user?.CreatorCode}`;
  const shareBotUrl = () => {
    if (nutakuEnv == NutakuEnv.Android) {
      console.log('Sharing with native');
      shareNative({
        title: t('Character') + ' : ' + connection.Name,
        text: t('Want to chat with this AI Character? Try Temptations.AI!'),
        url: botLinkToShare,
        dialogTitle: t('Share this Character!'),
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: connection.BotId,
            is_own_bot: null,
          },
        });
      });
    } else if (nutakuEnv == NutakuEnv.Web) {
      addModal({
        children: (
          <NoCopyPopup
            whatCopying={t('sharing link')}
            textToCopy={botLinkToShare}
          />
        ),
        className: 'horny-bg-dark_opac',
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: connection.BotId,
            is_own_bot: null,
          },
        });
      });
    } else {
      shareData({
        url: botLinkToShare,
        mediaUrl: connection.AvatarUrl,
        name: connection. Name,
      }).finally(() => {
        capture({
          event: 'share_bot',
          data: {
            bot_id: connection.BotId,
            is_own_bot: null,
          },
        });
      });
    }


    copyURLToClibpoard(botLinkToShare);
    setShareText(t('Copied'));
    setTimeout(() => {
      setShareText(t('Share'));
    }, 2000);
  }

  const showDeleteModal = () => {
    const deleteModal = addModal({
      children: (
        <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
          <motion.div
            className="tw-flex tw-flex-col tw-mx-4 tw-w-full tw-gap-4 tw-p-6 tw-bg-horny-gray-600 tw-rounded-lg tw-shadow-bezel"
            initial="initial"
            animate="in"
            transition={TransSpringPopup}
            variants={AnimParamInOutOpacityY100}
          >
            <div className="tw-w-full tw-font-bold tw-text-2xl">
              {t('Delete connection?')}
            </div>
            <div className="tw-w-full">
              {t(
                'Chatacter will be removed from the list of your connections. The character will not be deleted, since other users may still be interacting with it.'
              )}
            </div>
            <TemptationsButton
              color="red"
              size="base"
              onClick={() => {
                deleteMutation.mutate({ BotId: connection.BotId });
                removeModal(deleteModal);
              }}
            >
              <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                <FontAwesomeIcon icon={faTrashCan} />
                <span>{t('Delete')}</span>
              </div>
            </TemptationsButton>
          </motion.div>
        </div>
      ),
    });
  };

  return (
    <motion.div
      className={`tw-flex tw-flex-col tw-gap-4 tw-w-full tw-p-5 tw-content-start`}
      ref={lastItemRef}
      key={connection.BotId}
      layout="position"
    >
      {isChatLoading && <FullSizeLoader />}
      <div className="tw-flex tw-flex-row gap-4 tw-items-center">
        <ArkAvatar
          size="sm"
          src={connection.AvatarUrl}
          fallback={connection.Name[0]}
        />
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="d-flex gap-2 align-items-center">
            <div className='tw-font-bold tw-text-2xl '>{connection.Name}</div> 
          {connection.IsPrivate && <FontAwesomeIcon
            title={t('Private bot')}
            icon={faLock}
            className={cn(
              'tw-shadow',
              'tw-font-normal'
            )}
          />}
          </div>
          <div className="tw-opacity-75 tw-font-bold tw-text-lg tw-flex tw-flex-row tw-gap-4">
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <FontAwesomeIcon icon={faHeart} />
              <span>{connection.LikesCount}</span>
            </div>
            <div className="tw-flex tw-flex-row tw-gap-2 tw-text-lg tw-items-center">
              <FontAwesomeIcon icon={faCamera} />
              <span>{connection.MediaCount}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-overflow-scroll tw-scrollbar-hide tw-bg-horny-gray-900 tw-rounded-md tw-border tw-border-horny-gray-600 tw-py-2 tw-flex-no-wrap tw-opacity-75">
        <div className="tw-flex tw-flex-row tw-items-center tw-mx-3 tw-gap-4">
          {connection.Tags.map((tag) => (
            <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-w-auto tw-text-nowrap flex-shrink-0 tw-font-bold">
              <img
                src={appConfig.host.EMOJI + '/128/' + tag.icon + '.png'}
                className="tw-w-5 tw-h-5 tw-drop-shadow-sm tw-block tw-grayscale"
              />
              {t(tag.title as any)}
            </div>
          ))}
          <div className="tw-opacity-0">.</div>
        </div>
      </div>
      <div className="tw-text-lg tw-opacity-75">
        <div className={cn(isStoryHidden && 'tw-line-clamp-3')} ref={storyRef}>
          {replaceUserName(formatStory(connection.Story), user!)}
        </div>
        {isEllipsisActive && (
          <div
            className="tw-cursor-pointer tw-w-full tw-text-lg tw-items-center tw-flex tw-justify-center tw-gap-2 tw-p-2"
            onClick={() => setIsStoryHidden(!isStoryHidden)}
          >
            <span className="tw-text-base">
              {isStoryHidden ? t('More') : t('Less')}
            </span>
            <FontAwesomeIcon icon={isStoryHidden ? faDownToLine : faUpToLine} />
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-row tw-gap-6 tw-text-2xl tw-justify-end">
        {!connection.IsPrivate && <motion.div
          whileTap={{ scale: 0.8 }}
          className="tw-cursor-pointer tw-px-2 tw-flex tw-flex-col tw-items-center tw-gap-2"
          style={{width: '66px'}}
          onClick={() => {shareBotUrl()}}
        >
          <FontAwesomeIcon
            icon={faShare}
            className={cn(
              'tw-shadow',
            )}
          />
          <div
            className={cn(
              'tw-text-xs tw-font-bold',
            )}
          >
            {shareText}
          </div>
        </motion.div>}
        <motion.div
          whileTap={{ scale: 0.8 }}
          className="tw-cursor-pointer tw-px-2 tw-flex tw-flex-col tw-items-center tw-gap-2"
          onClick={() => {
            connection.Status;
            mutate(connection.Status === 'Favorite' ? null : 'Favorite');
          }}
        >
          <FontAwesomeIcon
            icon={faBookmark}
            className={cn(
              'tw-shadow',
              status === 'Favorite' && 'tw-text-horny-red-500'
            )}
          />
          <div
            className={cn(
              'tw-text-xs tw-font-bold',
              status === 'Favorite' && 'tw-text-horny-red-500'
            )}
          >
            {t('Favorite')}
          </div>
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className="tw-cursor-pointer tw-px-2 tw-flex tw-flex-col tw-items-center tw-gap-2"
          onClick={() => {
            mutate(connection.Status === 'Archived' ? null : 'Archived');
          }}
        >
          <FontAwesomeIcon
            icon={faBoxArchive}
            className={cn(
              'tw-shadow',
              status === 'Archived' && 'tw-text-horny-red-500'
            )}
          />
          <div
            className={cn(
              'tw-text-xs tw-font-bold',
              status === 'Archived' && 'tw-text-horny-red-500'
            )}
          >
            {t('Archive')}
          </div>
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.8 }}
          className={`tw-cursor-pointer tw-px-2 tw-flex tw-flex-col tw-items-center tw-gap-2 ${connection.IsPrivate ? 'pe-none opacity-50' : ''}`}
          onClick={() => {
            showDeleteModal();
          }}
          {...deleteLongPress()}
        >
          <FontAwesomeIcon icon={faTrashCan} className={cn('tw-shadow')} />
          <div className={cn('tw-text-xs tw-font-bold')}>{t('Delete')}</div>
        </motion.div>
        <div className="tw-border-l tw-pl-8 tw-border-l-horny-gray-600 tw-content-center">
          <motion.div
            className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-cursor-pointer"
            onClick={() => {
              capture({
                event: 'page_open',
                data: {
                  page_name: 'Chat',
                },
              });
              loadChat(connection);
              setIsChatOpen(true);
            }}
            key={connection.BotId}
            whileTap={{ scale: 0.8 }}
          >
            <FontAwesomeIcon icon={faCommentDots} className="tw-shadow" />
            <div className="tw-text-base tw-font-bold">{t('Chat')}</div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}