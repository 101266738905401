import { motion } from 'framer-motion';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BotAPI, FindBotResponse } from 'src/services/API/BotAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import {
  HornyButton,
  HornyButtonHeight,
} from '../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { NewInput } from '../Shared/Input/NewInput';

type FoundCharacterProps = {
  character: FindBotResponse;
};

export function FoundCharacter({ character }: FoundCharacterProps) {
  const OpenCharacter = (BotId: string) => {
    window.location.href = `/?GirlId=${BotId}`;
  };
  return (
    <div className="d-flex align-items-stretch gap-4 justify-content-betwee">
      <img src={character.AvatarUrl} className="horny-small-avatar" />
      <div className="d-flex gap-1 flex-column">
        <div className="fw-bold fs-4">{character.Name}</div>
        <div className="opacity-75 horny-text_tiny">{character.BotId}</div>
      </div>
      <motion.div
        className="horny-bg-dark_half px-8 py-3 horny-fit-content horny-border_100 cursor-pointer d-flex flex-column align-items-center justify-content-center"
        whileTap={{ scale: 0.8 }}
        style={{ marginLeft: 'auto' }}
        onClick={() => OpenCharacter(character.BotId)}
      >
        <div className="d-inline-flex align-items-center gap-3 text-nowrap cursor-pointer">
          <Icon src={IconSrc.Next} size={IconSize.Small} />
          <div className="text-white fw-bold">
            <b>Open</b>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export function FindCharacter() {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string | null>(null);
  const handleValueChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const [isFetchingFindBot, setIsFetchingFindBot] = useState(false);
  const [foundCharacters, setFoundCharacters] = useState<
    FindBotResponse[] | null
  >(null);
  const findCharactersRequest = (qyery: string) => {
    setIsFetchingFindBot(true);
    BotAPI.findBot({ Query: qyery }).then((response) => {
      setFoundCharacters(response.data);
      setIsFetchingFindBot(false);
    });
  };

  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div className="px-6 py-11 d-flex flex-column gap-6 horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
          <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-2 fw-bold">{t('Find Character')}</span>
          </div>
          <div>
            <Trans
              i18nKey="Find Char Description"
              components={[<b />, <b />]}
            />
            {/* Find a character by entering either the <b>Character ID</b> or the{' '}
            <b>Character Name</b>. */}
          </div>
          <NewInput
            onValueChange={handleValueChange}
            defaultText={t('Enter Character ID or Name...')}
          />
          <div className="d-flex justify-content-center w-100">
            <HornyButton
              onClick={() => {
                if (inputValue !== null && inputValue !== '') {
                  findCharactersRequest(inputValue);
                }
              }}
              height={HornyButtonHeight.Tall}
              icon={IconSrc.Find}
            >
              <div className="me-auto">{t('Find Character')}</div>
            </HornyButton>
          </div>
          {foundCharacters !== null && (
            <div className="fw-bold fs-3">{t('Characters')}</div>
          )}
          {!isFetchingFindBot &&
            foundCharacters !== null &&
            foundCharacters!.length > 0 && (
              <div
                style={{ maxHeight: '250px', overflowY: 'scroll' }}
                className="d-flex w-100 gap-4 flex-column find-character-picker-border horny-disable-scrollbar"
              >
                <div className="mt-3"></div>
                {foundCharacters?.map((character) => (
                  <FoundCharacter character={character} />
                ))}
                <div className="mb-3"></div>
              </div>
            )}
          {foundCharacters?.length === 0 && !isFetchingFindBot && (
            <div className="d-flex flex-column gap-6 align-items-center">
              <div className="w-75">
                <div className="fw-bold fs-4">
                  {t('No Characters Found...')}
                </div>
                <div className="opacity-75">
                  {t('We couldnt find any character')}
                </div>
              </div>
            </div>
          )}
          {isFetchingFindBot && (
            <div className="m-auto">
              <Icon
                src={IconSrc.Loader}
                size={IconSize.XL}
                // onLoad={() => getConnections()}
                className="horny-spin"
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}
