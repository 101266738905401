import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { ExampleMessage } from 'src/services/API/CharacterAPI';
import { generatePlaceholderBlocks } from '../ConfigureStory';
import { MessageEdit } from './MessageEdit';

export type CreateMessagesProps = {
  name: string;
  onSelect: (value: ExampleMessage[]) => any;
  description: string;
  disabled: boolean;
  generateMessages: () => Promise<ExampleMessage[]>;
  fetchedMessages?: ExampleMessage[];
};

export function CreateMessages({
  name,
  onSelect,
  description,
  disabled,
  generateMessages,
  fetchedMessages,
}: CreateMessagesProps) {
  const { t } = useTranslation();
  // const [isManual, setIsManual] = useState(false);
  const [messages, setMessages] = useState<ExampleMessage[]>(
    fetchedMessages || []
  );
  const [isFetching, setIsFetching] = useState(false);

  // const [manualStory, setManualStory] = useState<string>('');
  // const [generatedStory, setGeneratedStory] = useState<string>('');
  const regenerateMessages = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    generateMessages()
      .then((messages) => {
        setMessages(messages);
        onSelect(messages);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const updateMessage = (index: number, content: string) => {
    const messagesToReplace = messages.map((message, i) => {
      if (index === i) {
        message.content = content;
      }
      return message;
    });
    setMessages(messagesToReplace);
    onSelect(messagesToReplace);
  };
  const [placeholderBlocks] = useState(() => generatePlaceholderBlocks(1400));
  // const [randomBlocks] = useState(() => generatePlaceholderBlocks(1400));

  return (
    <div
      className={`d-flex flex-column gap-3 position-relative ${disabled ? 'opacity-50' : ''}`}
    >
      {disabled && (
        <div
          className="position-absolute w-100 h-100 top-0"
          style={{ left: 0, zIndex: 5 }}
        ></div>
      )}
      <div className="d-flex gap-2 justify-content-between">
        <div className="fs-5 fw-bold">{t('Example messages')}</div>
      </div>
      <div className="opacity-75 lh-sm text-start">
        {t('generation messages description')}
      </div>
      <div>
        <span className="fw-bold opacity-75 text-decoration-underline mr-5">
          {t('Note')}:
        </span>
        <span className=" opacity-75"> {t('Structure your dialogue')}</span>
      </div>
      <div className="text-center">
        {!messages.length && (
          <div
            className="position-relative rounded-3 overflow-hidden p-5  horny-bg_grey_dark m-auto"
            style={{ width: 310, minHeight: 228 }}
          >
            {!messages.length && [
              <div
                className="position-absolute w-100 h-100 top-0 z-1 text-start p-5 horny-bg_grey_dark"
                style={{ left: 0 }}
              >
                {placeholderBlocks}
              </div>,
              <div
                className="position-absolute  w-100 h-100 top-0 z-2 d-flex align-items-center justify-content-center "
                style={{ left: 0 }}
              >
                <div className="position-relative w-100 d-flex justify-content-center">
                  {disabled && (
                    <div
                      className="position-absolute m-auto w-100 text-center"
                      style={{ zIndex: 6, opacity: 1, top: -40 }}
                    >
                      {t('Please complete the “Introduction” step first')}
                    </div>
                  )}
                  <HornyButton
                    height={HornyButtonHeight.Medium}
                    onClick={() => regenerateMessages()}
                    bg={HornyButtonBG.Orange}
                    {...(isFetching ? { disabled: true } : {})}
                  >
                    <div>
                      <Icon
                        src={isFetching ? IconSrc.Reload : IconSrc.Magic}
                        size={IconSize.Small}
                        className={`${isFetching ? 'horny-spin' : ''}`}
                      />
                    </div>
                    <div>
                      {' '}
                      {`${isFetching ? t('Generating messages') : t('Generate messages')} ${isFetching ? '...' : ''}`}
                    </div>
                    <div></div>
                  </HornyButton>
                </div>
              </div>,
            ]}
          </div>
        )}
      </div>
      {!messages.length
        ? null
        : [
            messages.map((message, i) => (
              <MessageEdit
                message={message}
                onChange={(content) => updateMessage(i, content)}
                key={i.toString()}
              />
            )),
            <div className="d-flex justify-content-center mb-4">
              <HornyButton
                height={HornyButtonHeight.Medium}
                onClick={() => regenerateMessages()}
                bg={HornyButtonBG.Orange}
                shadow="horny-shadow-red-with-orange"
                className="w-auto"
                {...(isFetching ? { disabled: true } : {})}
              >
                <div>
                  <Icon
                    src={isFetching ? IconSrc.Reload : IconSrc.Magic}
                    size={IconSize.Small}
                    className={`${isFetching ? 'horny-spin' : ''}`}
                  />
                </div>
                <div>
                  {`${isFetching ? t('Regenerating messages') : t('Regenerate messages')}`}
                  {`${isFetching ? '...' : ''}`}
                </div>
                <div></div>
              </HornyButton>
            </div>,
          ]}
    </div>
  );
}
