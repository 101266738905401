import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  ConnectionsFilter,
  GetConnectionsQueryParams,
} from 'src/@types/common/modules/Connections';
import { useUserContext } from 'src/context/User.context';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { FullSizeLoader } from '../Layout/FullSizeLoader/FullSizeLoader';

import { useInView } from 'react-intersection-observer';
import { ArkSelect } from '../Shared/Ark/ArkSelect';
import { ArkSwitch } from '../Shared/Ark/ArkSwitch';
import { Connection } from './Connection/Connection';
import { useConnectionsStore } from './ConnectionsStore';
import { useOpenChat } from './OpenChatModal';
import './tw-base.css';

export type ConnectionsProps = {
  active: boolean;
};

export function Connections(props: ConnectionsProps) {
  const { t } = useTranslation();
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const [animateOpacity, setAnimateOpacity] = useState(false);
  const toggleOpacity = () => {
    setAnimateOpacity(true);
  };

  const { inView, ref } = useInView();

  // const filterItems: ConnectionsFilter[] = [
  //   'All',
  //   'Favorite',
  //   'Archived',
  //   'Untagged',
  // ];
  type Item = { label: string; value: ConnectionsFilter };
  const filterItems: Item[] = [
    { label: t('All'), value: 'All' },
    { label: t('Favorite'), value: 'Favorite' },
    { label: t('Archived'), value: 'Archived' },
    { label: t('Untagged'), value: 'Untagged' },
  ];

  const currentFilter = useConnectionsStore((state) => state.filter);
  const setFilter = useConnectionsStore((state) => state.setFilter);
  const onlyOwn = useConnectionsStore((state) => state.onlyOwn);
  const setOnlyOwn = useConnectionsStore((state) => state.setOnlyOwn);
  const isChatOpen = useConnectionsStore((state) => state.isChatOpen);
  const setIsChatOpen = useConnectionsStore((state) => state.setIsChatOpen);

  const scrollToTop = () => {
    const currentDiv = scrollableDivRef ? scrollableDivRef.current : null;
    if (currentDiv) {
      currentDiv.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    }
  };

  const { loadChat, isChatLoading } = useOpenChat();

  const { user } = useUserContext();
  if (!user) {
    window.location.href = '/';
  }
  const [searchParams] = useSearchParams();
  const botId = searchParams.get('ChatId');
  // const chatFirstOpened = useRef(false);

  const pageSize = 10;
  const {
    isPending,
    isError,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['Connections', onlyOwn, currentFilter],
    queryFn: ({ pageParam }) => {
      const params: GetConnectionsQueryParams = {
        OnlyOwn: onlyOwn,
        Filter: currentFilter,
        PageSize: pageSize,
        StartFrom: pageParam,
        ...(botId && { BotId: botId }),
      };

      return ConnectionsAPI.getConnectionsNest(params);
    },
    getNextPageParam: (lastPage) => {
      const morePagesExist =
        lastPage.data.StartFrom + lastPage.data.PageSize < lastPage.data.Total;
      return morePagesExist
        ? lastPage.data.StartFrom + lastPage.data.PageSize
        : undefined;
    },
    initialPageParam: 0,
  });

  const connectionsPages = data?.pages.flatMap((page, pageIndex, pageArray) =>
    page.data.Connections.map((connection, index, connectionArray) => {
      const isLastItem =
        pageIndex === pageArray.length - 1 &&
        index === connectionArray.length - 1;
      return (
        <Connection
          key={connection.BotId}
          connection={connection}
          lastItemRef={isLastItem ? ref : undefined}
        />
      );
    })
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage]);

  const {
    data: botData,
    isLoading: isBotLoading,
    error: botError,
    isFetched: isBotFetched,
  } = useQuery({
    queryKey: ['connection', botId],
    queryFn: () =>
      botId
        ? ConnectionsAPI.getConnectionNest({ BotId: botId })
        : Promise.reject(new Error('No botId provided')),
    enabled: !!botId && !isChatOpen,
  });

  useEffect(() => {
    if (!!botId && isBotFetched && !isChatOpen) {
      setIsChatOpen(true);
      loadChat(botData?.data.Connection!);
    }
  });

  const loadingState = (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-font-bold tw-text-xl tw-gap-2 tw-p-10">
      <FontAwesomeIcon icon={faSpinnerThird} className="tw-animate-spin" />
      <div>{t('Loading')}...</div>
    </div>
  );

  return (
    <motion.div
      animate={props.active ? 'in' : 'out'}
      initial="initial"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
      className="tw-bg-gradient-to-b tw-from-horny-gray-600 tw-to-horny-gray-900 tw-flex tw-flex-col tw-h-full tw-pt-24"
    >
      {isChatLoading && <FullSizeLoader />}
      <div className="tw-flex tw-flex-row tw-gap-4 tw-w-full tw-justify-between tw-border-b tw-px-6 tw-pb-8 tw-border-horny-gray-400 tw-shadow">
        <ArkSelect
          items={filterItems}
          onChange={(e) => {
            toggleOpacity();
            setFilter(e as ConnectionsFilter);
            scrollToTop();
          }}
          defaultValue={currentFilter}
        />
        <ArkSwitch
          onChange={(e) => {
            toggleOpacity();
            setOnlyOwn(e);
            scrollToTop();
          }}
          defaultChecked={onlyOwn}
          label={t('My characters')}
        />
        {/* {totalConnections} */}
      </div>
      <div
        className="tw-flex tw-h-full tw-flex-col tw-gap-3 tw-pb-3 tw-overflow-scroll tw-w-full tw-scrollbar-hide tw-relative"
        ref={scrollableDivRef}
        key={'Connection'}
      >
        <div className="tw-pt-3"></div>
        <motion.div
          className="tw-fixed tw-h-full tw-w-full tw-bg-horny-gray-400 tw-z-10 tw-pointer-events-none"
          initial={{ opacity: 0 }}
          animate={animateOpacity ? { opacity: 1 } : { opacity: 0 }}
          transition={
            animateOpacity
              ? { duration: 0, type: 'tween' }
              : { duration: 0.75, type: 'tween' }
          }
          onAnimationComplete={() => setAnimateOpacity(false)}
        ></motion.div>
        {isError && (
          <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-text-xl tw-gap-2">
            <div className="tw-font-bold">{t('Error')}:</div>
            <div>{t('Failed to load connections')}</div>
          </div>
        )}
        {!isError && isPending && loadingState}
        <AnimatePresence mode="sync">{connectionsPages}</AnimatePresence>
        {data?.pages[0]?.data.Connections.length === 0 && (
          <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-text-xl tw-gap-2">
            <div className="tw-font-bold tw-text-center tw-p-6">
              {t('There are no characters that match your filters')}
            </div>
          </div>
        )}
        {isFetchingNextPage && loadingState}
      </div>
    </motion.div>
  );
}
