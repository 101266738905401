import { ReactElement, useState } from 'react';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { ShowSubscriptionsReason } from 'src/components/Subscription/Subscriptions';
import { appConfig } from 'src/config/app';
import { ForgotPassword } from './ForgotPassword';
import { SignInUp } from './SignInUp';

export enum AuthorizationStep {
  SignInUp = 'SignInUp',
  ForgotPassword = 'ForgotPassword',
}

export enum AuthorizationInput {
  Email = 'Email',
  Password = 'Password',
}

export type AuthorizationError = {
  input: AuthorizationInput;
  error: ReactElement;
};

export const getAuthorizationInputError = (errorMessage: string) => {
  return (
    <div className="d-flex align-items-center gap-2 justify-content-center py-2">
      <Icon src={IconSrc.Warning} size={IconSize.Small} />
      <span className="fw-bold">{errorMessage}</span>
    </div>
  );
};

export type HornyAuthorizationProps = {
  goTo: (step: AuthorizationStep) => any;
  showReason?: ShowSubscriptionsReason;
};
export function HornyAuthorization(
  props: Omit<HornyAuthorizationProps, 'goTo'>
) {
  const [step, setStep] = useState(AuthorizationStep.SignInUp);

  const componentsToRender = {
    [AuthorizationStep.SignInUp]: (
      <SignInUp goTo={(step) => setStep(step)} showReason={props.showReason} />
    ),
    [AuthorizationStep.ForgotPassword]: (
      <ForgotPassword goTo={(step) => setStep(step)} />
    ),
  };

  return (
    <div className="mx-auto w-100 authorization h-100 overflow-y-auto px-6 z-10">
      {componentsToRender[step]}
      <div className="text-center"> {appConfig.app.version}</div>
    </div>
  );
}

export const AuthErrorBlock = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="horny-bg_grey  rounded-4 p-6 text-white m-auto d-flex flex-column gap-2 justify-content-between horny-bg_grey_opac mb-3">
      <div className="d-flex">
        <div className="pe-6 d-flex align-items-center">
          <Icon src={IconSrc.Warning} size={IconSize.Big} />
        </div>
        <div>{errorMessage}</div>
      </div>
    </div>
  );
};

// export const anonymousSignUpBlock = (content?: ReactElement) => {
//   const { t } = useTranslation();
//   return (
//   <div className="horny-bg_grey  rounded-4 p-6 text-white m-auto d-flex flex-column gap-2 justify-content-between horny-bg_grey_opac mb-3">
//     <div className="d-flex justify-content-center">
//       {content ? (
//         <div className="d-flex align-items-center gap-1">{content} </div>
//       ) : (
//         <div className="d-flex align-items-center gap-2">
//           {t('Sign up and get more')}{' '}
//           <Icon src={IconSrc.Energy} size={IconSize.XSmall} /> {t('Energy')} {t('and')}{' '}
//           <Icon src={IconSrc.Chat} size={IconSize.XSmall} /> {t('Messages')}{' '}
//         </div>
//       )}
//     </div>
//   </div>)
// };
