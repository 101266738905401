import { useEffect, useRef, useState } from 'react';

export type TimerProps = {
  // timeTill: number;
  onTimeReach: () => any;
};
export function Timer({ onTimeReach }: TimerProps) {
  const getNextDay = () => {
    const dateNow = Date.now();
    const daysPassed = Math.floor(dateNow / (1000 * 60 * 60 * 24));
    const nextDay = (daysPassed + 1) * (1000 * 60 * 60 * 24);
    return nextDay;
  };

  const [timeTill, setTimeTill] = useState(getNextDay());
  const [timeLeft, setTimeLeft] = useState<number>(
    (timeTill - Date.now()) / 1000
  );

  const currentProgressPercentRef = useRef<number>(0);

  useEffect(() => {
    // cache the current state value
    currentProgressPercentRef.current = timeLeft;
  }, [timeLeft]);

  const decrementTimeLeft = () => {
    // access the current state value
    // console.log(currentProgressPercentRef.current)
    if (currentProgressPercentRef.current - 1 > 0) {
      setTimeLeft((oldTimeLeft) => oldTimeLeft - 1);
    } else {
      setTimeTill(getNextDay());
      onTimeReach();
    }
  };

  useEffect(() => {
    const id = window.setInterval(decrementTimeLeft, 1000);
    return () => window.clearInterval(id);
  }, []);

  // const addData = () => {
  //     if (progressPercent >= 100){
  //         window.clearInterval(inter);
  //         return;
  //     }
  //     console.log({progressPercent})
  //     setProgressPercent(progressPercent + 1);

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         if (timeLeft <= 0) {
  //             clearInterval(interval);
  //             return onTimeReach();
  //         }
  //         setTimeLeft(timeLeft - 1);
  //     });
  // }, [timeTill, onTimeReach, timeLeft]);

  // console.log({ timeLeft, timeTill });
  const hours = Math.ceil(timeLeft / 60 / 60 - 1)
    .toString()
    .padStart(2, '0');
  const minutes = Math.floor((timeLeft / 60) % 60)
    .toString()
    .padStart(2, '0');
  const seconds = Math.floor(timeLeft % 60)
    .toString()
    .padStart(2, '0');
  return (
    <div>
      {hours}:{minutes}:{seconds}
    </div>
  );
}
