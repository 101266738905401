import { MediaType } from 'src/services/API/MediaAPI';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from '../../../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../../../Shared/Icon/Icon';
import { useTranslation } from 'react-i18next';

export type MediaFilterProps = {
  selectedType: MediaType | null;
  onSelect: (type: MediaType | null) => any;
};

export function MediaFilter({ selectedType, onSelect }: MediaFilterProps) {
  const { t } = useTranslation();
  return (
    <div className="d-flex gap-1">
      {Object.keys(MediaType)
        .filter((key) => Number.isNaN(Number(key)))
        .map((mediaType) => {
          const mediaTypeToProcess: keyof typeof MediaType =
            mediaType as keyof typeof MediaType;
          // console.log({ selectedType, mediaType });
          return (
            <HornyButton
              key={mediaType}
              style={{
                ...(mediaTypeToProcess === 'Photo' ? {} : { opacity: 0.7 }),
                // ...((selectedType !== MediaType[mediaTypeToProcess])
                //   ? selectedType !== null ? {} : { opacity: 0.8 }
                //   : {}),
              }}
              height={HornyButtonHeight.Short}
              bg={HornyButtonBG.Red}
              data-anal="feature"
              data-anal-feature="filter_media"
              data-anal-value={`${
                MediaType[mediaTypeToProcess] === selectedType
                  ? 'all'
                  : MediaType[mediaTypeToProcess].toLowerCase()
              }`}
              onClick={() => {
                return false;
                // mediaType === MediaType.Photo
                onSelect(
                  MediaType[mediaTypeToProcess] === selectedType
                    ? null
                    : MediaType[mediaTypeToProcess]
                );
              }}
            >
              <Icon
                src={IconSrc[mediaType as keyof typeof MediaType]}
                size={IconSize.Tiny}
              />
              <span className="text-nowrap">
                {t(mediaTypeToProcess as any)}
              </span>
            </HornyButton>
          );
        })}
    </div>
  );
}
