import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { NutakuSharePopup } from 'src/components/NutakuShare/NutakuSharePopup';
import { CopyCode } from 'src/components/Shared/CopyCode/CopyCode';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useModalContext } from 'src/context/Modal.Context';
import { NutakuAPI, NutakuShareResponse } from 'src/services/API/NutakuAPI';
import { useWindowDimensions } from 'src/shared/utils';
import './NutakuShare.css';

export function NutakuShare() {
  const [searchParams] = useSearchParams();

  const [pageData, setPageData] = useState<NutakuShareResponse | null>(null);

  const getPageData = () => {
    const botParam = searchParams.get('BotId');
    const mediaParam = searchParams.get('MediaId');
    NutakuAPI.getNutakuShareData({
      BotId: botParam,
      MediaId: mediaParam,
    }).then(({ data }) => {
      setPageData(data);
    });
  };

  useEffect(() => {
    getPageData();
  }, []);

  const isMedia = pageData?.Media !== null;

  const codeParam = searchParams.get('CreatorCode');
  const { width, height } = useWindowDimensions();
  const isMobile = width < height;
  const { addModal } = useModalContext();

  const showOpenInApp = () => {
    const modal = addModal({
      children: <NutakuSharePopup BotId={pageData?.Bot.BotId!} />,
      isFullscreen: true,
    });
  };

  return (
    <div
      className={`w-100 h-100 ${!isMobile ? 'd-flex justify-content-center p-5' : ''} `}
      style={{ background: 'linear-gradient(180deg, #555 0%, #333 100%)' }}
    >
      {!pageData ? (
        <FullSizeLoader />
      ) : (
        <div
          className={`${!isMobile ? 'h-100 d-flex gap-5 align-items-center' : 'w-100 h-100'}`}
        >
          {/* Nutaku Share {botSearchParam}{' '} */}
          <div
            className={`h-100 ${!isMobile ? 'align-items-center justify-content-center d-flex w-50' : 'w-100'}`}
          >
            <img
              src={pageData?.Media ? pageData.Media.Url : pageData.Bot.Url}
              className={`${!isMobile ? 'NutakuShareBotImage' : 'w-100'}`}
            />
          </div>
          <div
            className={`d-flex flex-column ${isMobile ? 'position-sticky w-100 bottom-0' : 'w-50 gap-10'}`}
            style={{ maxWidth: !isMobile ? '500px' : '100%', width: 'auto' }}
          >
            <div
              className={`gap-3 d-flex flex-column ${isMobile && 'NutakuShareBlackBG p-10'}`}
            >
              <div className="fs-2 fw-bold">{pageData?.Bot.Name}</div>
              <div className={`${!isMobile ? 'opacity-75' : ''}`}>
                {pageData?.Bot.Story.replace(/({{.*?}})/g, 'You 🫵')}
              </div>
              <div className="d-flex gap-3 align-items-center">
                <div className="d-flex gap-1 align-items-center">
                  <div className="fw-bold">Character Author:</div>
                  <div className="fw-bold opacity-75">
                    @{pageData?.Bot.Username}
                  </div>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <Icon
                    src={IconSrc.Add1}
                    size={IconSize.XSmall}
                    className="opacity-75"
                  />
                  <div className="opacity-75">{pageData?.Bot.LikesCount}</div>
                </div>
                {/* <div className="d-flex gap-1 align-items-center">
                <Icon
                  src={IconSrc.Chat}
                  size={IconSize.XSmall}
                  className="opacity-75"
                />
                <div className="opacity-75">Chats Count</div>
              </div> */}
              </div>
              {pageData.Media != null && (
                <div className="d-flex gap-3 align-items-center">
                  <div className="d-flex gap-1 align-items-center">
                    <div className="fw-bold">Image Author:</div>
                    <div className="fw-bold opacity-75">
                      @{pageData?.Media?.Username}
                    </div>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <Icon
                      src={IconSrc.Add1}
                      size={IconSize.XSmall}
                      className="opacity-75"
                    />
                    <div className="opacity-75">
                      {pageData?.Media?.LikesCount}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={`${isMobile ? 'NutakuShareRefferalBlockMobile' : 'NutakuShareRefferalBlock'} p-10 d-flex flex-column gap-7 align-items-center`}
            >
              {codeParam && (
                <div className="d-flex gap-5">
                  <CopyCode code={codeParam} />
                  <div style={{ maxWidth: '250px' }}>
                    Enter this creator code in the App "Setting" menu and get
                    rewards: <b>{pageData.Rewards.Energy} Energy</b> and{' '}
                    <b>{pageData.Rewards.Messages} messages</b>. Applies only
                    once.
                  </div>
                </div>
              )}
              <HornyButton
                onClick={() =>
                  window.open(
                    'https://www.nutaku.net/games/temptations-ai/',
                    '_blank'
                  )
                }
                height={HornyButtonHeight.Tall}
                icon={IconSrc.NutakuLogo}
                text="Play Temptations AI on Nutaku"
              ></HornyButton>
              <motion.div
                className="d-flex justify-content-center gap-10 flex-wrap no-underline-on-hover"
                whileTap={{ scale: 0.8 }}
              >
                <div
                  className="d-inline-flex align-items-end gap-2 text-nowrap cursor-pointer"
                  onClick={showOpenInApp}
                >
                  <Icon src={IconSrc.Magic} />
                  <div
                    className={`text-white fw-bold text-decoration-underline`}
                  >
                    <b>Open Character in the App</b>
                  </div>
                </div>
              </motion.div>
              {/* <div className="d-flex justify-content-center gap-10 flex-wrap no-underline-on-hover">
              <Link
                underlined={true}
                url="https://temptationgames.com/privacy-policy"
                icon={IconSrc.Web}
                className="fw-bold"
              >
                Open Character on Web
              </Link>
              <Link
                underlined={true}
                url="https://temptationgames.com/privacy-policy"
                icon={IconSrc.Android}
                className="fw-bold"
              >
                Open Character in APK
              </Link>
            </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
