import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { appConfig } from 'src/config/app';
import { useModalContext } from 'src/context/Modal.Context';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { BotAPI, BotTournament } from 'src/services/API/BotAPI';
import { splitTextLines } from 'src/shared/utils';
import { useWindowSize } from '../Shared/TopBotCard';
import { Tournament } from './Tournament';
import { TournamentPlace } from './TournamentPlace';
import './index.css';
export enum TournamentStatus {
  Upcoming = 'Upcoming',
  Ongoing = 'Ongoing',
  Finished = 'Finished',
}
export type TournamentData = {
  status: TournamentStatus;
  left: number;
};
export const getTournamentStatus = (
  tournament: BotTournament
): TournamentData => {
  const now = Date.now();
  const start = new Date(tournament.Start).getTime();
  const end = new Date(tournament.End).getTime();
  console.log({
    start,
    end,
    now,
    startExtNow: Math.ceil((start - now) / 1000 / 60 / 60 / 24),
    endExtNow: Math.floor((now - end) / 1000 / 60 / 60 / 24),
  });
  if (start > now) {
    return {
      status: TournamentStatus.Upcoming,
      left: Math.ceil((start - now) / 1000 / 60 / 60 / 24),
    };
  }
  if (end < now) {
    return {
      status: TournamentStatus.Finished,
      left: Math.floor((now - end) / 1000 / 60 / 60 / 24),
    };
  }

  return {
    status: TournamentStatus.Ongoing,
    left: Math.ceil((end - now) / 1000 / 60 / 60 / 24),
  };
};

export enum TournamentInfoLocation {
  List = 'List',
  Tournament = 'Tournament',
}

export type TournamentInfoProps = {
  location: TournamentInfoLocation;
};

export function TournamentInfo(
  tournament: BotTournament & TournamentInfoProps
) {
  const { t } = useTranslation();
  const { addModal } = useModalContext();
  const { isNutaku } = useNutakuContext();
  const tournamentData = getTournamentStatus(tournament);
  const [width, height] = useWindowSize();
  const lowTierScreen = width < 440;
  return (
    <div
      className={`tournament-info position-relative `}
      style={
        {
          // backgroundSize: 'cover',
          // backgroundImage:
          // //   tournament.location === TournamentInfoLocation.List
          //    // ?
          //      `url(${tournament.Image || (appConfig.host.CDN + '/img/tournaments/Tournament.png')})`
          // : 'none',
        }
      }
    >
      <div
        className={`position-absolute w-100 h-100    z-1 pe-none ${tournament.location === TournamentInfoLocation.List ? ' rounded-4 horny-bg-dark-gray rounded-3 text-white w-100 horny-bg_grey_opac rounded-4 border border-1 border-white horny-shadow-just-shadow border-opacity-25' : ' tournament-info_image-mask '}`}
      >
        <img
          className={`h-100 w-100 object-fit-cover horny-opacity-10 ${tournament.location === TournamentInfoLocation.List ? 'rounded-4' : ''}`}
          src={
            (tournament.Image ||
              appConfig.host.CDN + '/img/tournaments/Tournament.png') +
            '?width=750'
          }
        />
      </div>
      <div className="d-flex flex-column gap-2 rounded-4 py-6 px-6 position-relative z-2">
        <div
          className={`d-flex gap-2 fw-bold align-items-center justify-content-between position-relative ${tournament.location === TournamentInfoLocation.Tournament ? 'mt-20' : ''}`}
        >
          <div
            className={`fw-bold ${tournament.location === TournamentInfoLocation.List ? 'fs-5' : 'fs-2'}`}
          >
            {t(tournament.Title as any)}
          </div>
          {tournament.location === TournamentInfoLocation.List &&
            !lowTierScreen && (
              <div className="horny-bg-dark_half rounded-4 px-5 py-3 horny-text_very_tiny d-flex align-items-center gap-2">
                {tournamentData.status !== TournamentStatus.Finished && (
                  <Icon src={IconSrc.Next} size={IconSize.Tiny} />
                )}

                <div>
                  {t(tournamentData.status as any)}.{' '}
                  {tournamentData.status !== TournamentStatus.Finished
                    ? tournamentData.left + ' ' + t('days left')
                    : ''}
                </div>
              </div>
            )}
        </div>
        {tournament.location === TournamentInfoLocation.List &&
          lowTierScreen && (
            <div className="horny-bg-dark_half rounded-4 px-5 py-3 horny-text_very_tiny d-flex align-items-center gap-2 w-max-content ">
              {tournamentData.status !== TournamentStatus.Finished && (
                <Icon src={IconSrc.Next} size={IconSize.Tiny} />
              )}

              <div>
                {t(tournamentData.status as any)}.{' '}
                {tournamentData.status !== TournamentStatus.Finished
                  ? tournamentData.left + ' ' + t('days left')
                  : ''}
              </div>
            </div>
          )}
        <div className="opacity-75  fw-light">
          {splitTextLines(
            isNutaku && tournament.NutakuDescription
              ? t(tournament.NutakuDescription as any)
              : t(tournament.Description as any)
          )}
        </div>
        <div className="d-flex flex-column gap-2 mt-4 mb-6">
          <div className="fw-bold">{t('Rewards')}</div>
          {tournament.Places.slice(
            0,
            tournament.location === TournamentInfoLocation.List ? 4 : 99
          ).map((p) => {
            return <TournamentPlace {...p} key={p.Rank} />;
          })}
        </div>

        {tournament.location === TournamentInfoLocation.List && (
          <div className="text-center">
            <HornyButton
              className="mw-max-content"
              height={HornyButtonHeight.Medium}
              //   icon={IconSrc.Next}
              onClick={() => {
                BotAPI.getTournamentBots(tournament.TournamentId).then(
                  ({ data }) => {
                    addModal({
                      className: 'horny-bg-gradient-gray-tab',
                      children: (
                        <Tournament topBots={data} tournament={tournament} />
                      ),
                      // children: <>
                      //     <TournamentInfo {...tournament} {...{location: TournamentInfoLocation.Tournament}} />
                      //     <TopBotList topBots={data}/>
                      // </>
                    });
                  }
                );
              }}
            >
              <div className="d-flex justify-content-start align-items-center gap-2">
                <Icon src={IconSrc.Next} size={IconSize.Small} />
                {t('Check the top characters')}
              </div>
            </HornyButton>
          </div>
        )}
      </div>
    </div>
  );
}
