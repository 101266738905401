import { Avatar } from '@ark-ui/react';
import { cn } from 'src/shared/utils';

export const AvatarSize = {
  sm: 'tw-w-24 tw-h-24 tw-border-2',
  md: 'tw-w-32 tw-h-32 tw-border-4',
  lg: 'tw-w-52 tw-h-52 tw-border-4',
};

type AvatarProps = {
  size?: keyof typeof AvatarSize;
  src: string;
  fallback: string;
};

export const ArkAvatar = ({ size = 'sm', src, fallback }: AvatarProps) => {
  return (
    <Avatar.Root
      className={cn(
        'tw-rounded-full tw-overflow-hidden tw-border-horny-orange-200 tw-shadow tw-shrink-0 tw-flex',
        AvatarSize[size]
      )}
    >
      <Avatar.Fallback className="tw-bg-horny-gray-900 tw-h-full tw-w-full tw-text-3xl tw-font-extrabold tw-flex tw-items-center tw-justify-center">
        <span>{fallback}</span>
      </Avatar.Fallback>
      <Avatar.Image src={src} alt="avatar" className="tw-h-full tw-w-full" />
    </Avatar.Root>
  );
};
