import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorization } from 'src/components/Authorization/Authorization';
import {
  CharacterGeneration,
  getFirstNullStage,
} from 'src/components/CharacterGeneration/CharacterGeneration';
import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Price } from 'src/components/Shared/Price/Price';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import {
  CharacterAPI,
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
} from 'src/services/API/CharacterAPI';

const defaultCharacterGenerationDraft: CharacterGenerationDraftAPIModel = {
  [CharacterGenerationStage.Personality]: null,
  [CharacterGenerationStage.Story]: null,
  [CharacterGenerationStage.ImageGenerationModel]: null,
  [CharacterGenerationStage.Appearance]: null,
  [CharacterGenerationStage.PhotoChoice]: null,
};

export function CharacterGenerationControl() {
  const { t } = useTranslation();
  const [characterDraft, setCharacterDraft] = useState<
    CharacterGenerationDraftAPIModel | null | undefined
  >(undefined);
  const { addModal } = useModalContext();
  const { user } = useUserContext();

  const { prices } = useResourcesContext();
  const getCharacterDraft = () => {
    CharacterAPI.getExistingDraft().then(({ data = null }) => {
      setCharacterDraft(data || null);
    });
  };

  const [fetching, setFetching] = useState(false);
  const { capture } = useAnalyticsContext();
  const createCharacterDraft = () => {
    if (fetching) {
      return;
    }
    setFetching(true);
    CharacterAPI.createDraft(defaultCharacterGenerationDraft)
      .then(({ data }) => {
        setCharacterDraft(data);
        capture({
          event: 'page_open',
          data: {
            page_name: 'Create_character_' + getFirstNullStage(data),
          },
        });
        capture({
          event: 'currency_spent',
          data: {
            amount: prices!.Actions.CreateBot,
            spent_on: 'bot_creation',
          },
        });
        addModal({
          className: 'horny-bg-gradient_red',
          children: (
            <CharacterGeneration
              characterGenerationDraft={data}
              onUpdate={(draft) => setCharacterDraft(draft)}
            />
          ),
        });
      })
      .catch(() => {})
      .finally(() => setFetching(false));
  };

  return (
    <motion.div
      whileTap={{ scale: 0.8 }}
      className="text-center horny-shadow horny-bg-gradient_red rounded-5 position-relative px-4 py-3 pt-4  horny-text_tiny d-flex flex-column justify-content-center cursor-pointer"
      style={{ height: 50, width: 93 }}
      onClick={() => {
        if (!user) {
          return addModal({
            children: <Authorization />,
            className: 'horny-bg-gradient_red',
          });
        }

        if (characterDraft === undefined) {
          return;
        }

        if (characterDraft === null) {
          return createCharacterDraft();
        }

        if (characterDraft) {
          capture({
            event: 'page_open',
            data: {
              page_name:
                'Create_character_' + getFirstNullStage(characterDraft),
            },
          });
          addModal({
            className: 'horny-bg-gradient_red',
            children: (
              <CharacterGeneration
                characterGenerationDraft={characterDraft}
                onUpdate={(draft) => setCharacterDraft(draft)}
              />
            ),
          });
        }
      }}
    >
      {
        <Icon
          src={
            !user || characterDraft === null
              ? IconSrc.Add
              : characterDraft === undefined
                ? IconSrc.Loader
                : IconSrc.Shape
          }
          size={IconSize.Smaller}
          className={`position-absolute mx-auto ${user && characterDraft === undefined ? 'horny-spin' : ''}`}
          style={{ top: -7, left: 'calc(50% - 7px)' }}
          {...(characterDraft !== null
            ? { onLoad: () => user && getCharacterDraft() }
            : {})}
        />
      }
      <div className="my-2 horny-text_very_tiny lh-1 text-center fw-bold">
        {t('New character')}
      </div>
      {(!user || characterDraft === null) && (
        <AddBlock className="horny-bg-dark_half p-y-1 px-3 m-auto">
          <Price
            amount={prices!.Actions.CreateBot}
            className="p-0 horny-text_very_tiny lh-1"
          />
        </AddBlock>
      )}
      {!!user && !!characterDraft && (
        <div className="text-center lh-1" style={{ fontSize: 6 }}>
          {t('Continue editing')}
        </div>
      )}
    </motion.div>
  );
}
