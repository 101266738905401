import { createBrowserRouter } from 'react-router-dom';
import { NotFound } from 'src/pages/Error/NotFound';
import { AuthRedirect } from 'src/pages/Landing/AuthRedirect';
import { PatreonLanding } from 'src/pages/Landing/PatreonLanding';
import { ResetPasswordLanding } from 'src/pages/Landing/ResetPassword.Landing';
import { SignUpLanding } from 'src/pages/Landing/SignUpLanding';
import { SubscribeStarLanding } from 'src/pages/Landing/SubscribeStarLanding';
import { Main, TabToShow } from 'src/pages/Main/Main';
import { MediaLanding } from 'src/pages/MediaLanding/MediaLanding';
import { NutakuShare } from 'src/pages/NutakuShare/NutakuShare';

const MainPage =
  process.env.REACT_APP_DOMAIN_TYPE === 'NutakuShare' ? (
    <NutakuShare />
  ) : (
    <Main tabToShow={TabToShow.Discover} />
  );

export const router = createBrowserRouter(
  [
    {
      path: '',
      element: MainPage,
    },

    {
      path: 'connections',
      element: <Main tabToShow={TabToShow.Connections} />,
    },

    {
      path: 'top',
      element: <Main tabToShow={TabToShow.Top} />,
    },

    {
      path: 'invite',
      element: <Main tabToShow={TabToShow.Invite} />,
    },

    {
      path: 'media',
      element: <MediaLanding />,
    },

    {
      path: 'auth-redirect',
      element: <AuthRedirect />,
    },
    {
      path: 'patreon',
      element: <PatreonLanding />,
    },
    {
      path: 'subscribestar',
      element: <SubscribeStarLanding />,
    },
    {
      path: 'sign-up',
      element: <SignUpLanding />,
    },
    {
      path: 'drop-password',
      element: <SignUpLanding />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordLanding />,
    },
    {
      path: '*',
      element: <NotFound />,
      //   const El = () => {
      //     console.log({ loc: useLocation() });
      //     return <div>Error</div>;
      //   };
      //   return <El />;
      // })(),
    },
  ],
  process.env.REACT_STORY
    ? {
        basename: '/iframe.html',
      }
    : {}
);
