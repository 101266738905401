import {
  APIBase,
  PaginationCommonParams,
  PaginationResponseProps,
} from './APIBase';
import { SubscriptionLevel } from './ResourcesAPI';
import { UserAPIModel } from './UserAPI';

export type inviteBuyRequest = {
  ItemId: string;
};

export type inviteGetInvite = {
  Nickname: string | null;
  Points: number | null;
  DaysAlias: string;
  Status: SubscriptionLevel | null;
};

export type inviteGetResponse = {
  Pagination: PaginationResponseProps;
  Invites: inviteGetInvite[];
};

export class InviteAPI extends APIBase {
  static inviteBuy(params: inviteBuyRequest) {
    return this.client.post<UserAPIModel>('invitebuy', params);
  }

  static invitesGet(params: PaginationCommonParams) {
    return this.client.get<inviteGetResponse>('getinvites', { params });
  }
}
