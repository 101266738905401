import { Link } from 'src/components/Shared/Link/Link';

export function NotFound() {
  return (
    <div>
      <div>Nothing found here : (</div>
      <div>
        <Link url="/">Visit the main page</Link>
      </div>
    </div>
  );
}
