import { AxiosRequestConfig } from 'axios';
import { ImageGenerationModelType } from 'src/@types/common';
import { APIBase, PaginationResponse } from './APIBase';
import {
  CharacterGenerationAppearance,
  CharacterGenerationPersonality,
} from './CharacterAPI';

type Character = {
  appearance: any;
};

type UserProps = {
  handle: string;
};
type UserResponse = UserProps & {
  editingCharacter: Character;
};

export type BotRequestParams = {
  BotId: string;
};
export type BotTag = {
  icon: string;
  title: string;
};

export type BotTags = {
  kinks: BotTag;
  occupation: BotTag;
  personality: BotTag;
};
export type BotAPIModel = {
  CoverUrl: string;
  Name: string;
  BotId: string;
  LikesCount: number;
  Author: string;
  Liked: boolean;
  Chatted: boolean;
  Viewed: boolean;
  Rating: number;
  Tags: BotTags;
  Story: string;
  IsBotAuthor: boolean;
};

export interface TopBot {
  BotId: string; // UUID type in PostgreSQL is typically represented as a string in TypeScript
  Rating: number; // Numeric type in PostgreSQL is typically represented as a number in TypeScript
  Media: number; // Integer type in PostgreSQL is typically represented as a number in TypeScript
  Likes: number; // Integer type in PostgreSQL is typically represented as a number in TypeScript
  Messages: number; // Integer type in PostgreSQL is typically represented as a number in TypeScript
  Rank: number; // Integer type in PostgreSQL is typically represented as a number in TypeScript
  Avatar: string; // Text type in PostgreSQL is typically represented as a string in TypeScript
  Name: string; // Text type in PostgreSQL is typically represented as a string in TypeScript
  Author: string; // Text type in PostgreSQL is typically represented as a string in TypeScript
  IsOwnBot: boolean; // Boolean type in PostgreSQL is typically represented as a boolean in TypeScript
}

export type CharacterGetAllResponse = {
  Characters: BotAPIModel[];
};

type Choice = [string, string];
type StoryResponse = {
  interactive: boolean;
  choices: Choice[];
};

export type CharacterStatistics = {
  like: number;
  photo: number;
  video: number;
  audio: number;
};

export type ReportBotData = {
  BotId: string;
  ReportedItems: string[];
  Message: string;
};

export type FindBotRequest = {
  Query: string;
};

export type FindBotResponse = {
  BotId: string;
  Name: string;
  LikesCount: number;
  AvatarUrl: string;
};

export type FilterQuery = {
  Appearance?: Partial<CharacterGenerationAppearance>;
  Personality?: Partial<CharacterGenerationPersonality>;
  ImageGenerationModel: ImageGenerationModelType | null;
};

export type GetTopBotsParams = {
  filters?: FilterQuery;
  limit: number;
  offset: number;
};

export interface BotTournament {
  TournamentId: string; // UUID type in SQL is represented as a string in TypeScript
  Title: string;
  Description: string;
  NutakuDescription: string;
  Start: Date;
  End: Date;
  Active: boolean;
  Places: BotTournamentPlace[];
  Image: string;
}

export interface BotTournamentPlace {
  Rank: number;
  PrizeWeb: string;
  PrizeNutaku: string;
  IconNutaku: string;
  IconWeb: string;
}

export class BotAPI extends APIBase {
  // client: Axios;
  // constructor({client}: APIProps) {
  //     this.client = client;
  // }

  static getAll(config: AxiosRequestConfig = {}) {
    // return this.client.get<PaginationResponse<CharacterGetAllResponse>>(
    return this.clientV2.get<PaginationResponse<CharacterGetAllResponse>>(
      'v1/bots',
      config
    );
  }

  static get() {
    return this.client.get<UserResponse>('');
  }

  static generateCharacterName() {
    return this.client.put<StoryResponse>('/name');
  }

  static generateUserRole() {
    return this.client.put<StoryResponse>('/user/role');
  }

  static likeBot(data: BotRequestParams) {
    return this.client.post<unknown>('likebot', data);
  }

  static viewBot(BotId: string) {
    return this.client.post<unknown>('viewbot', {
      BotIds: [BotId],
    });
  }

  static getTextParams() {
    return this.client.get('gettextparams');
  }

  static create() {
    return this.clientV2.post<BotAPIModel>('v1/bots');
    return this.client.post<BotAPIModel>('createbot');
  }

  static reportBot(data: ReportBotData) {
    return this.client.post('ReportBot', data);
  }

  static findBot(params: FindBotRequest) {
    return this.clientV2.get<FindBotResponse[]>('v1/bots/find', {
      params,
    });
    return this.client.get<FindBotResponse[]>('findbot', {
      params,
    });
  }

  static getTopBots({ filters, limit, offset }: GetTopBotsParams) {
    return this.clientV2.get<TopBot[]>('v1/top/bots', {
      params: {
        ...(filters ? { filters: JSON.stringify(filters) } : {}),
        limit,
        offset,
      },
    });
    return this.client.get<TopBot[]>('GetTopbots', {
      params: {
        ...(filters ? { filters: JSON.stringify(filters) } : {}),
        limit,
        offset,
      },
    });
  }

  static getBotTournamentList() {
    return this.clientV2.get<BotTournament[]>('v1/top/tournaments');
    return this.client.get<BotTournament[]>('GetBotTournamentList', {});
  }

  static getTournamentBots(tournamentId: string) {
    return this.clientV2.get<TopBot[]>('v1/top/tournaments/bots', {
      params: {
        tournamentId,
      },
    });
    return this.client.get<TopBot[]>('GetTournamentBots', {
      params: {
        tournamentId,
      },
    });
  }
}
