import { RadioGroup } from '@ark-ui/react';
import { faCircle, faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { cn } from 'src/shared/utils';
import './ArkRadioGroup.css';

export type RadioItem = {
  value: string;
  label: string;
  disabled: boolean;
};

export type ArkRadioGroupProps = {
  onChange: (value: string) => any;
  items: RadioItem[];
};
export const ArkRadioGroup = ({ onChange, items }: ArkRadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    items.find((item) => !item.disabled)?.value
  );

  useEffect(() => {
    const newDefaultValue = items.find((item) => !item.disabled)?.value;
    setSelectedValue(newDefaultValue);
  }, [items]);

  return (
    <RadioGroup.Root
      className="tw-flex tw-flex-row tw-gap-4 tw-w-fit tw-font-bold"
      value={selectedValue}
      onValueChange={(value) => {
        setSelectedValue(value.value);
        onChange(value.value);
      }}
    >
      {items.map((item) => (
        <RadioGroup.Item
          key={item.value}
          value={item.value}
          className={cn(
            'tw-flex tw-flex-row tw-gap-1 tw-items-center tw-group radio-group-item tw-cursor-pointer',
            item.disabled && 'tw-opacity-50'
          )}
          disabled={item.disabled}
        >
          <RadioGroup.ItemControl>
            <span className="radio-icon-checked">
              <FontAwesomeIcon icon={faCircleDot} size="lg" />
            </span>
            <span className="radio-icon-unchecked">
              <FontAwesomeIcon icon={faCircle} size="lg" />
            </span>
          </RadioGroup.ItemControl>
          <RadioGroup.ItemText>{item.label}</RadioGroup.ItemText>
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>
      ))}
    </RadioGroup.Root>
  );
};
