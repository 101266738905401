import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Icon, IconSrc } from 'src/components/Shared/Icon/Icon';
import {
  captureMyTracker,
  useAnalyticsContext,
} from 'src/context/AnalyticsContext/AnalyticsContext';
import { addClickMagicReg } from 'src/context/AnalyticsContext/helpersDOM';
import { useModalContext } from 'src/context/Modal.Context';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { animalNames, shuffleArray, verifyUserHandle } from 'src/shared/helpers';
import { focusEditableElement } from 'src/shared/utils';
import { NotificationType } from '../Notification/Notification';
import { useTranslation } from 'react-i18next';

export function UserNameRequest() {
  const { t } = useTranslation();
  const userNickNameHint = t('The user name must be');
  const { setUser, user } = useUserContext();
  const generateRandomName = () => {
    const randomAnimalName = shuffleArray(animalNames)[0];
    const idLastGroup = shuffleArray(user!.UserId.split('-').reverse())[0];
    const randomName = randomAnimalName + '-' + idLastGroup;
    return randomName;
  }
  const { capture } = useAnalyticsContext();
  const [shareText, setShareText] = useState(t('Copy'));
  const [saveText, setSaveText] = useState(t('Edit'));
  const [isEditing, setIsEditing] = useState(true);
  const handleInputRef = useRef<HTMLSpanElement>(null);
  const [inputValue, setInputValue] = useState(user!.Nickname || generateRandomName());
  const [userHandle, setUserHandle] = useState(inputValue);
  const [init, setInit] = useState(true);
  const { addNotification } = useNotificationsContext();

  const changeUserHandle = () => {
    // if (!verifyUserHandle(inputValue)) {
    //   return;
    // }
    if (
      inputValue.length < 3 ||
      inputValue.length > 32 ||
      !verifyUserHandle(inputValue)
    ) {
      return addNotification({
        type: NotificationType.Info,
        message: userNickNameHint,
        timestamp: Date.now(),
      });
    }
    if (handleInputRef.current) {
      UserAPI.changeHandle(inputValue)
        .then(({ data: userResponse }) => {
          captureMyTracker({
            type: 'reachGoal',
            goal: 'mt_registration',
            userid: user!.UserId,
          });

          capture({
            event: 'registration',
            data: {},
          });

          addClickMagicReg();
          setUser({
            ...user!,
            Nickname: userResponse.Nickname,
          });
          setUserHandle(userResponse.Nickname);
          setInputValue(userResponse.Nickname);
          // setIsEditing(false);
          setTimeout(() => {
            setSaveText(t('Saved'));
            setTimeout(() => {
              setSaveText(t('Edit'));
            }, 1500);
          }, 50);
        })
        .catch((e: AxiosError) => {
          // console.log(e);

          if (e.response?.status && e.response.status < 500) {
            setError(e.response?.data as unknown as string);
          }
          // setIsEditing(false);
        });
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => focusEditableElement(handleInputRef), 100);
    }
  }, [isEditing]);

  const limitInputLength = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    const target = e.target as HTMLSpanElement;
    if (e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
    if (e.key !== 'Backspace' && target.innerText.length >= 32) {
      e.preventDefault();
      return false;
    }
    if (!/^[^\s]+$/.test(e.nativeEvent.key)) {
      e.preventDefault();
      return false;
    }

    return true;
  };

  const [userName, setUserName] = useState(user!.Nickname);
  const { user: contextUser, logout } = useUserContext();
  const { addModal } = useModalContext();
  const [error, setError] = useState('');

  const setRandomName = () => {
    const randomName = generateRandomName();
    // setUserHandle(randomName);
    setInputValue(randomName);
      handleInputRef.current!.innerText = randomName;
      setInit(false);
  }
  return (
    <motion.div
      className="position-fixed horny-absolute_full z-20"
      style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="h-100 d-flex justify-content-center px-2">
        <div className="align-self-center px-1 w-100">
          <div
            className="horny-bg_grey_opac horny-shadow-gray px-6 py-11 d-flex flex-column gap-4  horny-bg_grey rounded-3 text-white w-100 mx-auto"
            style={{ maxWidth: 560 }}
          >
            {/* <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-3 fw-bold">Settings</span>
          </div> */}
            <div className="fs-4 fw-bold">{t('Tell us your name')} </div>
            <div>{userNickNameHint}</div>
            <div className="d-flex ">
              <div className=" d-flex align-items-center">
                {/* <span className="fw-bold">Handle:</span> */}
                {
                  !isEditing ? (
                    <div className="    align-items-center py-1 px-4 rounded-4 opacity-75 horny-bg-dark_opac">
                      <span className="opacity-75">@{userHandle}</span>
                    </div>
                  ) : (
                    <div className="   align-items-center py-1 px-4 text-white rounded-4   horny-bg-dark_opac">
                      <span className="opacity-75">
                        @
                        <span
                          className="d-inline-block"
                          contentEditable={'true'}
                          ref={handleInputRef}
                          onKeyDown={(e) => limitInputLength(e)}
                          onPaste={(e) => e.preventDefault()}
                          onInput={(e) => {
                            // console.log('changing');
                            setInputValue(
                              (e.target as HTMLSpanElement).innerText
                            );
                          }}
                          style={{ minWidth: 120 }}
                          defaultValue={userHandle}
                        >
                          {userHandle}
                        </span>
                      </span>
                    </div>
                  )
                  // <div className="position-relative">
                  //   <div className="position-absolute" style={{left: 18, bottom: 3}}>
                  //     @
                  //   </div>
                  //   <div ref={handleInputRef}  className="ms-2  text-white d-inline-flex align-items-center py-1 pe-4 rounded-4   horny-bg-dark_opac border-0" style={{width: 100, height: 23.14, paddingLeft: 24}} defaultValue={user.Username}/>
                  // </div>
                }
              </div>
              <motion.div
                whileTap={{ scale: 0.8 }}
                className={`cursor-pointer d-flex ms-5 align-items-center gap-2 ${(inputValue === userHandle && !init )|| !inputValue ? ' pe-none opacity-50' : ''}`}
                onClick={() => {
                  if (!isEditing) {
                    setIsEditing(true);
                  } else {
                    changeUserHandle();
                  }
                }}
              >
                <Icon src={!isEditing ? IconSrc.Edit : IconSrc.Save} />
                <span>{!isEditing ? saveText : t('Save')}</span>
              </motion.div>
            </div>
            <div><u onClick={() => setRandomName()} className='cursor-pointer'>{t('Generate random nickname')}</u></div>

            <div className="text-danger">
              {error ? error : <span>&nbsp;</span>}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}


 