import { motion } from 'framer-motion';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { getImgUrl } from 'src/shared/helpers';
import { CopyCode } from '../Shared/CopyCode/CopyCode';

type NutakuSharePopupProps = {
  BotId: string;
};

export function NutakuSharePopup({ BotId }: NutakuSharePopupProps) {
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100">
        <div className="px-6 py-11 d-flex flex-column gap-4 horny-bg_grey rounded-3 text-white w-100 horny-bg_grey_opac horny-shadow-gray">
          <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-2 fw-bold">Find this Character</span>
          </div>
          <div className="fs-4 fw-bold ps-2">1. Copy Character ID</div>
          <CopyCode code={BotId} textClass="fs-5" />
          <div className="fs-4 fw-bold ps-2">
            2. Open the App and Click “Find”
          </div>
          <img
            src={getImgUrl('nutakushare/NutakuShare1.png')}
            className="w-50 NutakuSharePopupImage"
          />
          <div className="fs-4 fw-bold ps-2">3. Paste ID and press “Find”</div>
          <img
            src={getImgUrl('nutakushare/NutakuShare2.png')}
            className="w-50 NutakuSharePopupImage"
          />
        </div>
      </div>
    </motion.div>
  );
}
