import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectionsAPI } from 'src/services/API/ConnectionsAPI';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import { HornyButton } from '../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';

export type ClearChatProps = {
  id: string;
};
export function ClearChat(props: ClearChatProps) {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);

  const clearMessages = () => {
    setFetching(true);
    ConnectionsAPI.clearMesages({
      BotId: props.id,
    })
      .then(() => (window.location.href = '/connections?ChatId=' + props.id))
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          window.location.reload();
        }
      })
      .finally(() => setFetching(false));
  };
  return (
    <motion.div
      className="h-100 d-flex justify-content-center px-2"
      initial="initial"
      animate="in"
      transition={TransSpringPopup}
      variants={AnimParamInOutOpacityY100}
    >
      <div className="align-self-center px-1 w-100 text-center">
        <div className="px-6 py-11 d-flex flex-column gap-4  horny-bg_grey rounded-3 text-white mx-auto horny-bg_grey_opac horny-shadow-gray w-100">
          <div className=" mb-2  d-flex justify-content-between w-100">
            <div className="fs-3 fw-bold w-100">{t('Are you sure?')}</div>
          </div>
          <div className="w-100 d-flex flex-column gap-2">
            {t(
              'By clicking "Clear chat" you are goin to lose all generated media and messages.'
            )}{' '}
            {t('Consider adding media to "My media".')}
          </div>
          <div className="d-flex justify-content-center">
            <HornyButton onClick={() => !fetching && clearMessages()}>
              <div className="d-flex gap-3">
                <Icon src={IconSrc.Clear} size={IconSize.Small} />{' '}
                {t('Clear chat')}
              </div>
            </HornyButton>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
