import { AxiosProgressEvent } from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useSearchParams } from 'react-router-dom';
import { LanguageHint } from 'src/components/Layout/LanguageHint/LanguageHint';
import { MainLoader } from 'src/components/Layout/MainLoader';
import { SwipeCharacters } from 'src/components/Layout/Tutorial/SwipeCharacters';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { useUserContext } from 'src/context/User.context';
import { BotAPI, BotAPIModel } from 'src/services/API/BotAPI';
import {
  AnimParamInOutOpacityY100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { isMobile, shuffleArray } from 'src/shared/helpers';
import { ConnectionProps } from '../../Connections/Connection/Connection';
import { CharacterCarouselSlide } from './CharacterCarouselSlide/CharacterCarouselSlide';
import './index.css';
import { Link } from 'src/components/Shared/Link/Link';
import { useTranslation } from 'react-i18next';
const startLoadingBeforeSlides = 5;
const pageSize = 30;

export type Character = ConnectionProps & {
  src: string;
  tags?: string[];
  authorHandle: string;
};

export type CharactersResponse = {
  characters: Character[];
  from: number;
  size: number;
  total: number;
};

export type CharacterCarouselProps = {
  hidden?: boolean;
  active: boolean;
};

export function CharacterCarousel(props: CharacterCarouselProps) {
  const [searchParams] = useSearchParams();
  const [charactersToRender, setCharactersToRender] = useState<
    BotAPIModel[] | null
  >(null);
  const { t } = useTranslation();


  const botSearchParam = searchParams.get('GirlId');
  const nswfSearchParam = searchParams.get('nsfw');
  const [sharedBotId, setSharedBotId] = useState<string | null>(
    botSearchParam || null
  );

  const [nsfwParam, setNsfwParam] = useState<string | null>(
    nswfSearchParam || null
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [downloadPercentage, setDownloadPercentage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(false);
  const [total, setTotal] = useState(0);
  const { capture } = useAnalyticsContext();

  // const loadInitCharacters = () => {
  useEffect(() => {
    setIsFetching(true);
    getCharacters(0)
      .then(({ Characters, Total }) => {
        // if (window.location.pathname === '/') {
        //   capture({
        //     event: 'page_open',
        //     data: {
        //       page_name: 'Main',
        //     },
        //   });
        setCharactersToRender(Characters);
        setTotal(Total);
        setSharedBotId(null);
        // }
        // onLoaded();
      })
      .catch((e) => setError(true))
      .finally(() => setIsFetching(false));
  }, []);
  // };
  const [scrolling, setScrolling] = useState<boolean | undefined>(undefined);
  const [swipeTutorialIsHidden, setSwipeTutorialIsHidden] = useState(() => {
    return !!localStorage.getItem('swipeTutorialWasShown');
  });
  const hideSwipeTutorial = () => {
    localStorage.setItem('swipeTutorialWasShown', 'true');
    setSwipeTutorialIsHidden(true);
  };
  const carouselRef = useRef<Carousel | null>(null);
  const { modals } = useModalContext();

  const { user } = useUserContext();

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (props.hidden) {
        return true;
      }
      if (e.key === 'ArrowDown') {
        return carouselRef.current?.increment(1);
      }
      if (e.key === 'ArrowUp') {
        return carouselRef.current?.decrement(1);
      }
      return true;
    };
    !props.hidden && window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [props.hidden]);

  const lazyLoadCharacters = (slide: number) => {
    if (
      charactersToRender &&
      startLoadingBeforeSlides >= charactersToRender.length - 1 - slide &&
      total > charactersToRender.length &&
      !isFetching
    ) {
      setIsFetching(true);
      getCharacters(charactersToRender.length)
        .then(({ Characters, Total }) => {
          const mergedCharacters = [...charactersToRender, ...Characters];

          setCharactersToRender(mergedCharacters);
          setTotal(Total);
        })
        .catch((e) => setError(true))
        .finally(() => setIsFetching(false));
    }
  };

  const onBotLike = (botId: string, isLikedByUser: boolean) => {
    if (!charactersToRender) {
      return;
    }
    setCharactersToRender(
      charactersToRender.map((characterToRender) => {
        let isLiked = characterToRender.Liked;
        if (characterToRender.BotId === botId) {
          isLiked = isLikedByUser;
          characterToRender.LikesCount = characterToRender.LikesCount + 1;
        }
        characterToRender.Liked = isLiked;

        return characterToRender;
      })
    );
  };

  const onCreateChat = (botId: string) => {
    if (!charactersToRender) {
      return;
    }
    setCharactersToRender(
      charactersToRender.map((characterToRender) => {
        let chatted = characterToRender.Liked;
        if (characterToRender.BotId === botId) {
          chatted = true;
        }
        characterToRender.Chatted = chatted;

        return characterToRender;
      })
    );
  };

  const getCharacters = (from: number) => {
    return BotAPI.getAll({
      params: {
        PageSize: pageSize,
        StartFrom: from,
        GirlId: sharedBotId,
        Nsfw: nsfwParam,
        Anon: !user || user.IsAnonymous ? 'true' : null,
      },
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        const progressPercetage = Math.ceil(
          progressEvent.loaded / (progressEvent.bytes / 100)
        );
        setDownloadPercentage(progressPercetage);
      },
    }).then(({ data }) => {
      if (!user) {
        return data;
      }
      const firstChars = data.Characters.slice(0, 5);
      const nextChars = shuffleArray(data.Characters.slice(5));
      data.Characters = [...firstChars, ...nextChars];
      const linkedBot = data.Characters.find((b) => b.BotId === sharedBotId);
      if (linkedBot) {
        data.Characters = [
          linkedBot,
          ...[...firstChars, ...nextChars].filter(
            (b) => b.BotId !== linkedBot.BotId
          ),
        ];
      }
      return data;
    });
  };

  const onBotView = (botId: string) => {
    setCharactersToRender(
      (charactersToRender || []).map((b) => ({
        ...b,
        Viewed: b.BotId === botId ? true : b.Viewed,
      }))
    );
  };

  // if (user === undefined) {
  //   return <MainLoader progressPercent={0} />;
  // }

  const containerRef = useRef(null);
  if (!charactersToRender && !error) {
    return (
      <MainLoader
        progressPercent={downloadPercentage}
        // onLoad={() => loadInitCharacters()}
      />
    );
  }
  if (!charactersToRender && error) {
    return <div className='d-flex h-100 w-100 justify-content-center align-items-center'>
       <div>
          <div> {t('An error occured')}</div>
          <div>   <Link target='_self' url='/'>{t('Discover')}</Link>.</div>

          </div>
    </div>;
  }
  return (
    charactersToRender && (
      // <AnimatePresence mode = "wait">
      <motion.div
        initial="initial"
        animate={props.active ? 'in' : 'out'}
        //exit="out"
        variants={AnimParamInOutOpacityY100}
        transition={TransTweenAnticipate03}
        className="h-100"
        ref={containerRef}
        onKeyDown={(e) => {}}
        // onScroll={e }
        // onWheelCapture={e => {
        //   setScrolling(true);
        //   // set
        //   // console.log({e, scrolling});
        //   // if (scrolling.current){
        //   //   e.preventDefault();
        //   //   return false;
        //   // }
        //   // scrolling.current = true;
        //   // // r.current?.increment( 1);
        //   // // r.current?.selectItem(currentSlide + 1);
        //   // // const slide = r.current.sele
        //   //  setTimeout(() => r.current?.increment(1), 200);
        //     // setCurrentSlide(currentSlide + 1);

        // }}
      >
        {!swipeTutorialIsHidden && modals.length <= 0 && !isMobile() && (
          <SwipeCharacters onTouch={() => setSwipeTutorialIsHidden(true)} />
        )}
        <Carousel
          ref={carouselRef}
          axis="vertical"
          onSwipeStart={() => setSwipeTutorialIsHidden(true)}
          onClickItem={() => setSwipeTutorialIsHidden(true)}
          onChange={(slideNumber) => {
            // console.log({ slideNumber });
            setCurrentSlide(slideNumber);
            lazyLoadCharacters(slideNumber);
            hideSwipeTutorial();
            // setScrolling(undefined);
          }}
          emulateTouch={true}
          //  onSwipeEnd={}
          showThumbs={false}
          showArrows={!isMobile()}
          showIndicators={false}
          showStatus={false}
          className="h-100 character-carousel"
        >
          {charactersToRender.map((char, i) => (
            <CharacterCarouselSlide
              character={char}
              key={char.BotId}
              onLike={() => onBotLike(char.BotId, true)}
              onCreateChat={() => onCreateChat(char.BotId)}
              onView={() => onBotView(char.BotId)}
              isCurrentSlide={i === currentSlide}
            />
          ))}
        </Carousel>
        {swipeTutorialIsHidden && <LanguageHint />}
      </motion.div>
      // </AnimatePresence>
    )
  );
}
