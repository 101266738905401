import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthErrorBlock } from 'src/components/Authorization/HornyAuthorization/HornyAuthorization';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import {
  HornyButton,
  HornyButtonHeight,
  HornyButtonBG,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Link } from 'src/components/Shared/Link/Link';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { useUserContext } from 'src/context/User.context';
import { UserAPI } from 'src/services/API/UserAPI';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
const supaBaseClient = getSupabaseClient();

export function ResetPasswordLanding() {
  const navigate = useNavigate();
  const { setUser, user } = useUserContext();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const [password, setPassword] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const { addNotification } = useNotificationsContext();
  const [notification, setNotification] = useState('');

  useEffect(() => {
    // token &&
    //   UserAPI.get().then(({ data: userResponse }) => {
    //     setUser(userResponse);
    //     localStorage.setItem('token', token);
    //   });
  });

  const setNewPassword = () => {
    if (isFetching) {
      return;
    } else {
      setIsFetching(true);
    }
    UserAPI.changeUserPassword({
      Password: password,
    })
      .then((res) => {
        navigate('/');
      })
      .catch((e: AxiosError) => setNotification(e.message))
      .finally(() => setIsFetching(false));
  };

  // useEffect(() => {

  //    user === null && addNotification({
  //     type: NotificationType.Error,
  //     message: 'An error occured while processing this token. Please go to the main page'
  //    })
  // }, [user, navigate]);

  return user ? (
    <div
      className="position-fixed horny-absolute_full z-20"
      style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      {isFetching && <FullSizeLoader />}

      <div className="h-100 d-flex justify-content-center px-2">
        <div className="align-self-center px-1 w-100">
          <div
            className="horny-bg_grey_opac horny-shadow-gray px-6 py-11 d-flex flex-column gap-4  horny-bg_grey rounded-3 text-white w-100 mx-auto"
            style={{ maxWidth: 560 }}
          >
            {/* <div className=" mb-2  d-flex justify-content-between">
            <span className="fs-3 fw-bold">Settings</span>
          </div> */}
            <div className="fs-4 fw-bold">Set your new password</div>
            <div>Password should be at least 8 characters long.</div>
            {notification && <AuthErrorBlock errorMessage={notification} />}

            <div className="d-flex flex-column gap-3">
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Enter your new password"
                className="w-100 horny-input horny-input_authorization  horny-bg-gradient_orange_light rounded-5 horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange form-control"
              />
              <HornyButton
                height={HornyButtonHeight.Tall}
                bg={HornyButtonBG.Orange}
                onClick={() => setNewPassword()}
                className="w-100 mw-100"
              >
                <div className="mx-auto">Set new password</div>
              </HornyButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      {user === undefined ? (
        <Icon src={IconSrc.Loader} size={IconSize.XL} className="horny-spin" />
      ) : (
        <div className="d-flex flex-column gap-4" style={{ maxWidth: 400 }}>
          <div>
            <div>
              An error occured! Maybe the link was out of date.... Please go to
              the main page and try to get the link again.{' '}
            </div>
            <div>If this error repeats - contact the support team.</div>
          </div>
          <div className="text-center">
            <Link url="/">Go to main page</Link>
          </div>
          <div className="text-center">
            <Link url="https://temptationgames.com/#block-ad19c8cf0b23468ab41bb66f7d6af393">
              Contact support
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
