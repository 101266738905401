import { MediaPlayer } from '@vidstack/react';

export type MediaDisplayVideoProps = {
  src: string;
};
export function MediaDisplayVideo({ src }: MediaDisplayVideoProps) {
  // const [paused, setPaused] = useState(true);
  // const playIcon = paused ?
  // <Icon src={IconSrc.Play} onClick={() => setPaused(false)} size={IconSize.XL} style={{zIndex: 3}} className='position-absolute m-auto media_video_play-icon'/> :
  // <Icon src={IconSrc.Pause} onClick={() => setPaused(true)} size={IconSize.XL} style={{zIndex: 3}} className='position-absolute m-auto media_video_play-icon'/>;
  // return <div></div>
  return (
    <MediaPlayer className="w-100">
      <video
        className="w-100"
        src={process.env.REACT_APP_API_HOST + '/proxymedia?url=' + src}
        preload="auto"
        data-video="0"
        controls
      />
    </MediaPlayer>
  );
}
