import { motion } from 'framer-motion';
import { cn } from 'src/shared/utils';

type Color = 'red';
const Colors: Record<Color, string> = {
  red: 'tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600',
};

type Size = 'base' | 'lg';
const Sizes: Record<Size, string> = {
  base: 'tw-px-6 tw-py-3 tw-text-lg tw-rounded-full',
  lg: 'tw-px-8 tw-py-4 tw-text-xl tw-rounded-3xl',
};

type TemptationsButtonProps = {
  color: Color;
  size: Size;
  children?: React.ReactNode;
  onClick?: () => void;
};

export function TemptationsButton({
  color,
  size,
  children,
  onClick,
}: TemptationsButtonProps) {
  return (
    <motion.button
      className={cn(
        'tw-shadow-bezel tw-font-bold tw-w-fit',
        Colors[color],
        Sizes[size]
      )}
      whileTap={{ scale: 0.85 }}
      onClick={onClick}
    >
      {children || 'Button'}
    </motion.button>
  );
}
