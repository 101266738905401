import { createContext, useState, useContext, ReactElement } from 'react';
import { usePostHog } from 'posthog-js/react';

import { PostHog } from 'posthog-js';
import { useResourcesContext } from '../ResourcesContext';
import { registerAnalyticsListeners } from './registerAnalyticsListeners';
import { useUserContext } from '../User.context';
import { appConfig } from 'src/config/app';
import { currentAgreementVersion } from 'src/decorators/Agreement.Decorator';
import { useNutakuContext } from '../Nutaku.Context';
 
export enum AnalyticsProviderType {
  posthog = 'Posthog',
  myTracker = 'MyTracker',
}
export type AnalyticsProviders = {
  posthog: PostHog | null;
};
export type AnalitycsCaptureParams = {
  event: string;
  providers?: AnalyticsProviderType[];
  data: Record<string, string | number | null | boolean>;
};
export type AnalyticsContextProps = {
  providers: AnalyticsProviders;
  capture: (params: AnalitycsCaptureParams) => any;
  setPurchaseFrom: (from: string) => any;
};
export const AnalyticsContext = createContext<AnalyticsContextProps>({
  providers: {
    posthog: null,
  },
  capture: () => {},
  setPurchaseFrom: () => {},
});
export const AnalyticsContextProvider = AnalyticsContext.Provider;
export const useAnalyticsContext = () =>
  useContext<AnalyticsContextProps>(AnalyticsContext);

export const AnalyticsProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const [providers, setProviders] = useState<
    AnalyticsContextProps['providers']
  >({
    posthog: usePostHog(),
  });
  const [purchaseFrom, setPurchaseFrom] = useState('none');

  const { user } = useUserContext();
   const providersMatch = {
    [AnalyticsProviderType.posthog]: providers.posthog,
    [AnalyticsProviderType.myTracker]: providers.posthog,
  };



  const { prices } = useResourcesContext();
  const { isNutaku, nutakuEnv } = useNutakuContext();
  const [lastEventPrint, setLastEventPrint] = useState('');

  const capture = (params: AnalitycsCaptureParams) => {
    if (params.event === 'page_open'){
      return;
    }
    // if (JSON.stringify(params) === lastEventPrint){
    //   return;
    // }
    // console.log({lastEventPrint, params: JSON.stringify(lastEventPrint)})
    // setLastEventPrint(JSON.stringify(params));
    const appPlatform = !isNutaku ? 'web' : 'nutaku_' + nutakuEnv;
    const data: Record<string, any> = {
      ...params.data,
      premium_status:
        params.data.premium_status ||
        (!user ? 'none' : user.MergedSubscription.Level || 'none'),
      is_anonymous: !user ? null : user.IsAnonymous,
      ...(params.event === 'prem_bought' ? { action: purchaseFrom } : {}),
      app_npm_version: appConfig.app.version,
      Library: 'web',
      app_platform: appPlatform,
      agreement_version: currentAgreementVersion,
    };
    // if (params.providers) {
    //   params.providers.forEach((pp) => {
    //     const providerToCapture = providersMatch[pp];
    //     providerToCapture?.capture(params.event, data);
    //   });
    //   return;
    // }
    // console.log({
    //   event: params.event,
    //   data,
    // });
    providers.posthog?.capture(params.event, data);
     ///------///

    // if (
    //   params.event === 'subscribe_process' &&
    //   data.state.endsWith('_verified')
    // ) {
    //   //@ts-ignore
    //   const _tmr = window._tmr || (window._tmr = []);

    //   _tmr.push({
    //     type: 'reachGoal',
    //     goal: 'mt_purchase',
    //   });
    // }
    ///------///
    //     subscribe_process
    // Patreon_verified
    // //@ts-ignore
    // // if (window._tmr){

    // }
  };

  // useEffect(() => {
  //   if (isNutaku && !!user){
  //     capture({
  //       event: 'login',
  //       data: {
  //         test: 1
  //       },
  //     })
  //   }
  // }, []);

  if (prices) {
    registerAnalyticsListeners({ providers, capture }, prices!);
  }

  return (
    <AnalyticsContextProvider
      value={{
        providers,
        capture,
        setPurchaseFrom,
      }}
    >
      {children}
    </AnalyticsContextProvider>
  );
};

export const captureMyTracker = (params: Record<string, any>) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  //@ts-ignore
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ ...params, id: '3482567' });
};

export const captureTrafficJunky = (params: Record<string, any>) => {
  if (appConfig.nutaku.isNutaku) {
    return true;
  }
  //@ts-ignore
  window.idsync.send_event(params);
};
