import { underlineSelected } from 'src/shared/utils';
import { MediaBlock } from '../../DialogueMedia/DialogueMedia';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useUserContext } from 'src/context/User.context';
import { useModalContext } from 'src/context/Modal.Context';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

export type MediaBlockSwitcherProps = {
  selectedBlock: MediaBlock;
  onSelect: (block: MediaBlock) => any;
};

export function MediaBlockSwitcher({
  selectedBlock,
  onSelect,
}: MediaBlockSwitcherProps) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addModal } = useModalContext();
  return (
    <div className="d-flex justify-content-center gap-3 position-relative w-max-content   z-1">
      <div
        onClick={() => {
          if (selectedBlock !== MediaBlock.Dialogue) {
            onSelect(MediaBlock.Dialogue);
          }
        }}
        {...(selectedBlock !== MediaBlock.Dialogue
          ? {
              'data-anal': 'feature',
              'data-anal-feature': 'filter_media_type',
              'data-anal-value': 'Dialogue',
            }
          : {})}
        className="d-flex flex-column gap-1 cursor-pointer"
      >
        <motion.div
          whileTap={{ scale: 0.8 }}
          className={
            'd-flex gap-1 align-items-center' +
            (selectedBlock === MediaBlock.Dialogue ? '' : ' opacity-50')
          }
        >
          <Icon src={IconSrc.Unlock} size={IconSize.Tiny} />
          <span className="text-nowrap">{t('My media')}</span>
        </motion.div>
        {selectedBlock === MediaBlock.Dialogue && underlineSelected}
      </div>
      <div
        onClick={() => {
          // if (!user!.Subscription.Level){
          //   addModal({
          //     children: <Subscriptions showReason={ShowSubscriptionsReason.CommunityMedia} />
          //   });
          //   return;
          // }
          if (selectedBlock !== MediaBlock.Community) {
            onSelect(MediaBlock.Community);
          }
        }}
        {...(selectedBlock !== MediaBlock.Dialogue
          ? {
              'data-anal': 'feature',
              'data-anal-feature': 'filter_media_type',
              'data-anal-value': 'Community',
            }
          : {})}
        className="d-flex flex-column gap-1 cursor-pointer"
      >
        <motion.div
          whileTap={{ scale: 0.8 }}
          className={
            'd-flex gap-1 align-items-center' +
            (selectedBlock === MediaBlock.Community ? '' : ' opacity-50')
          }
        >
          <Icon
            src={
              user!.MergedSubscription.Level
                ? IconSrc.Community
                : IconSrc.Unlock
            }
            size={IconSize.Tiny}
          />
          <div className="text-nowrap">{t('Community')}</div>
        </motion.div>
        {selectedBlock === MediaBlock.Community && underlineSelected}
      </div>
      {/* <div  onClick={() => setSelectedTab(TabToSelect.Connections)}>Connections</div> */}
    </div>
  );
}
