import { Image } from 'react-bootstrap';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import './index.css';
import { motion } from 'framer-motion';

export enum TypePreviewSize {
  VerySmall = 'very-small',
  Small = 'small',
  Big = 'big',
  XL = 'xl',
}
export enum TypePreviewColor {
  Red = 'red',
  Grey = 'gray',
}
export type TypePreviewProps = {
  src: string;
  selected: boolean;
  size: TypePreviewSize;
  className?: string;
  color?: TypePreviewColor;
};

export function TypePreview({
  src,
  selected = false,
  size,
  className = '',
  color = TypePreviewColor.Red,
}: TypePreviewProps) {
  const borderWidthSize = selected ? 2 : 2;
  const selectedPaddingSize = size === TypePreviewSize.Small ? 2 : 5;
  const selectedIconSize = {
    [TypePreviewSize.VerySmall]: IconSize.Smaller,
    [TypePreviewSize.Small]: IconSize.Medium,
    [TypePreviewSize.Big]: IconSize.XL,
    [TypePreviewSize.XL]: IconSize.XL,
  }[size];
  const renderSelectedBlock = () => {
    return (
      <div
        className={`h-100 p-${selectedPaddingSize} d-flex align-items-end justify-content-end justify-items-end w-100 position-absolute top-0 overflow-hidden rounded-3`}
      >
        <div className="position-absolute rounded-3 overflow-hidden bg-black opacity-25 horny-absolute_full"></div>
        <Icon
          src={IconSrc.checkRed}
          size={selectedIconSize}
          className="position-absolute z-2"
          style={{ bottom: 5, right: 5 }}
        />
      </div>
    );
  };
  const pickHeights = {
    [TypePreviewSize.VerySmall]: 120,
    [TypePreviewSize.Small]: 250,
    [TypePreviewSize.Big]: 300,
    [TypePreviewSize.XL]: 400,
  };
  return (
    <motion.div
      whileTap={{ scale: 0.8 }}
      className={`position-relative rounded-3 border border-danger-subtle border-${borderWidthSize} ${className} cursor-pointer horny-shadow-${color}`}
    >
      <Image
        src={src + '?width=' + pickHeights[size]}
        className={`mw-100  overflow-hidden rounded-3   type-preview type-preview_${size}`}
      />
      {selected && renderSelectedBlock()}
    </motion.div>
  );
}
