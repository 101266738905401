import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useResourcesContext } from 'src/context/ResourcesContext';
import {
  AnimParamInOutOpacityY100,
  TransSpringPopup,
} from 'src/shared/animations';
import './index.css';

export enum ReplayContent {
  Text = 'text',
  Media = 'media',
}

export type ReplayProps = {
  onReplay: () => any;
  content: ReplayContent;
  // mediaType?: MediaType;
  IsBotAuthor: boolean;
  BotId: string;
  isRegenerating: boolean;
};
export function Replay({
  onReplay,
  content,
  // mediaType,
  IsBotAuthor,
  BotId,
}: ReplayProps) {
  const { prices } = useResourcesContext();

  return (
    <AnimatePresence>
      <motion.div
        whileTap={{ scale: 0.8 }}
        initial="initial"
        animate="in"
        transition={TransSpringPopup}
        variants={AnimParamInOutOpacityY100}
        className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-ml-2 tw-cursor-pointer" // ${regenerating || disabled ? 'opacity-75 pe-none' : 'cursor-pointer'}
        // {...(content === ReplayContent.Media
        //   && {
        //       'data-anal': 'media_request',
        //       'data-anal-request_type': 'regenerate',
        //       'data-anal-media_type': 'image',
        //       'data-anal-is_own_bot': IsBotAuthor,
        //       'data-anal-bot_id': BotId,
        //     }
        //   // : {
        //   //     'data-anal': 'message_sent',
        //   //     'data-anal-request_type': 'regenerate',
        //   //     'data-anal-is_own_bot': IsBotAuthor,
        //   //     'data-anal-bot_id': BotId,
        //   //   }
        //   )}
        onClick={() => {
          onReplay();
        }}
      >
        <FontAwesomeIcon
          icon={faArrowsRotate}
          // size="xl"
          className="tw-text-4xl tw-opacity-50"
        />

        {content !== ReplayContent.Text && (
          <div className="tw-flex tw-flex-row tw-items-center tw-text-xs tw-font-bold tw-gap-1 tw-opacity-50">
            <FontAwesomeIcon icon={faBolt} />
            <div>{prices!.Media['Photo'] / 2}</div>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
