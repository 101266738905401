import { faCheck, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageGenerationModelRecord } from 'src/@types/common';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
import { useResourcesContext } from 'src/context/ResourcesContext';
import {
  CharacterGenerationDraftAPIModel,
  CharacterGenerationStage,
  // CharaterGenerationModelAPIModel,
  ImageGenerationModel,
  ImageGenerationModelType,
} from 'src/services/API/CharacterAPI';
import { cn } from '../../../shared/utils';
import { OnChoiceCallback } from '../CharacterGeneration';

export type ConfigureImageGenerationModelProps = {
  onChoice: OnChoiceCallback;
  onForward?: (() => any) | null;
  fetched: ImageGenerationModel | null;
  onBack: () => any;
  onFilter?: (model: ImageGenerationModelType | null) => any;
};

export function ConfigureImageGenerationModel({
  onBack,
  onChoice,
  onForward,
  fetched,
  onFilter,
}: ConfigureImageGenerationModelProps) {
  const { t } = useTranslation();
  const fetchedRef = useRef(false);
  const { mediaPrompts } = useResourcesContext();
  const [models, setModels] = useState<ImageGenerationModelRecord[] | null>(
    mediaPrompts?.ImageGenerationModels || null
  );

  const [selected, setSelected] = useState<ImageGenerationModelType | null>(
    fetched
  );
  const updateDraft = () => {
    if (!selected) {
      return;
    }

    const stage = CharacterGenerationStage.ImageGenerationModel;
    const data: CharacterGenerationDraftAPIModel[typeof stage] = selected;
    onChoice(stage, data);
  };

  // const getPhotoElement = ({
  //   Image,
  //   Title,
  //   Available,
  // }: ImageGenerationModelRecord) => {
  //   // Available = Title !== 'Cartoon';
  //   return (
  //     <div
  //       className={`position-relative  d-flex align-items-center justify-content-center flex-column gap-2 ${!Available ? 'pe-none ' : ''}`}
  //       onClick={() => {
  //         setSelected(
  //           onFilter && selected === Title
  //             ? null
  //             : (Title as ImageGenerationModel)
  //         );
  //         onFilter &&
  //           onFilter(
  //             selected === Title ? null : (Title as ImageGenerationModel)
  //           );
  //       }}
  //       style={{ width: 'calc(50% - 10px)' }}
  //     >
  //       {/* <div className={`${!Available ? ' pe-none opacity-75 ' : ''}`}> */}
  //       <TypePreview
  //         src={Image}
  //         size={TypePreviewSize.Big}
  //         selected={selected === Title}
  //         className={!Available ? 'opacity-50' : ''}
  //       />
  //       {/* </div> */}
  //       <div className="fw-bold">{t(Title as any)}</div>
  //       {!Available && (
  //         <>
  //           <div className="position-absolute w-100 h-100 d-flex flex-column justify-items-center align-items-center top-0 justify-content-center"></div>
  //           {<div className="m-auto position-absolute">{t('Coming soon')}</div>}
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  const getPhotoElementNew = ({
    Image,
    Title,
    Available,
  }: ImageGenerationModelRecord) => {
    return (
      <motion.div
        className={cn(
          'tw-aspect-square tw-bg-contain tw-bg-no-repeat tw-bg-center tw-rounded-md tw-relative tw-overflow-hidden tw-flex tw-flex-col tw-justify-end tw-shadow tw-border-horny-red-200 tw-border',
          selected === Title ? 'tw-pointer-events-none' : 'tw-cursor-pointer',
          !Available && 'tw-pointer-events-none'
        )}
        style={{
          backgroundImage: `url('${Image}?width=500')`,
        }}
        onClick={() => {
          setSelected(
            onFilter && selected === Title
              ? null
              : (Title as ImageGenerationModel)
          );
          onFilter &&
            onFilter(
              selected === Title ? null : (Title as ImageGenerationModel)
            );
        }}
        whileTap={{ scale: 0.9 }}
      >
        {!Available && (
          <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-font-bold tw-z-10 tw-flex-row tw-gap-2">
            <FontAwesomeIcon icon={faLockKeyhole} />
            <div>
              {Title !== 'Realistic' ? t('Coming soon') : t('Unavailable')}
            </div>
          </div>
        )}
        <div className="tw-flex tw-justify-between tw-p-3 tw-bg-gradient-to-t tw-from-horny-gray-900 tw-to-transparent tw-bg-opacity-10 tw-h-24 tw-items-end">
          <div className="tw-font-bold tw-drop-shadow-md tw-flex tw-items-end">
            {t(Title as any)}
          </div>
          {selected === Title && (
            <div className="tw-rounded-full tw-bg-black tw-bg-opacity-50 tw-h-12 tw-w-12 tw-flex tw-items-center tw-justify-center">
              <FontAwesomeIcon
                icon={faCheck}
                className="tw-text-2xl tw-drop-shadow"
              />
            </div>
          )}
        </div>
      </motion.div>
    );
  };

  // useEffect(() => {
  //   if (!fetchedRef.current) {
  //     fetchedRef.current = true;
  //     CharacterAPI.getAppearanceModels()
  //       .then(({ data }) => {
  //         setModels(data);
  //       })
  //       .finally(() => {});
  //   }
  // });
  return (
    <div className="w-100 d-flex flex-column gap-2 justify-content-between h-100 text-start">
      <div className="overflow-y-auto  pt-20 horny-border-bottom pb-3 h-100 horny-disable-scrollbar">
        <div className="d-flex flex-column gap-3 position-relative">
          <div className="fs-1 fw-bold">{t('Model')}</div>
          <div className="opacity-75">
            {t('Select the desired visual style for your AI girlfriend.')}
          </div>
          <div className="tw-w-full tw-grid tw-grid-cols-2 tw-gap-4 tw-auto-rows-max">
            {models &&
              models
                .sort((a, b) => (a.Available ? -1 : 1) - (b.Available ? -1 : 1))
                .map((model) => getPhotoElementNew(model))}
          </div>
          {/* <div className="d-flex gap-2 justify-content-between">
            {models &&
              models.slice(0, 2).map((model) => getPhotoElement(model))}
          </div>
          <div className="d-flex gap-2 justify-content-between">
            {models && models.slice(2).map((model) => getPhotoElement(model))}
          </div> */}
        </div>
      </div>

      {
        <div className=" mx-auto  d-flex gap-4 flex-column align-items-end pb-4">
          {/* {onForward && (
            <HornyButton
              height={HornyButtonHeight.Tall}
              onClick={() => onForward()}
              icon={IconSrc.Next}
              text="Keep this model"
              className="mx-auto"
            ></HornyButton>
          )} */}

          <HornyButton
            height={HornyButtonHeight.Tall}
            {...(!selected ? { disabled: true } : {})}
            onClick={() => updateDraft()}
            icon={IconSrc.Next}
            text={t('Choose appearance')}
            className="mx-auto"
            //   {...{
            //     'data-anal': 'media_request',
            //     'data-anal-request_type': 'regenerate',
            //     'data-anal-media_type': 'image',
            //     'data-anal-is_own_bot': true,
            //     'data-anal-bot_id': null,
            //   }}
          ></HornyButton>

          {!onFilter && (
            <div className="mx-auto cursor-pointer  fw-bold text-decoration-underline">
              <u onClick={onBack}>{t('Back')}</u>
            </div>
          )}
        </div>
      }
    </div>
  );
}
