import { useTranslation } from 'react-i18next';
import { Timer } from 'src/components/Shared/Timer/Timer';
import 'src/components/Shared/tw-base.css';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import { SubscriptionLevel } from 'src/services/API/ResourcesAPI';

export function LeftMessagesCount() {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { prices } = useResourcesContext();
  let messagesToRefill: number;

  if (!user?.MergedSubscription.Level) {
    messagesToRefill = Number(prices?.NoSubscription.Messages);
  } else {
    const subscription = prices?.Subscriptions[user.MergedSubscription.Level];
    messagesToRefill = Number(subscription?.Messages);
  }

  if (
    (user?.MergedSubscription.Level && 
    user?.MergedSubscription.Level !== SubscriptionLevel.Friend) ||
    messagesToRefill <= Number(user!.MessagesLeft)
  ) {
    return null;
  }

  return user!.MessagesLeft > 0 ? (
    <div className="tw-flex tw-text-center tw-text-xs tw-gap-1 tw-leading-none">
      {user!.MessagesLeft} {t('free messages left')}
    </div>
  ) : user!.IsAnonymous ? (
    <div className="tw-flex tw-items-center tw-text-xs tw-gap-1 tw-leading-none">
      <div>{t('Sign up to get more messages')}</div>
    </div>
  ) : (
    <div className="tw-flex tw-items-center tw-text-xs tw-gap-1 tw-leading-none">
      +{messagesToRefill} {t('messages in')}
      <Timer onTimeReach={console.log} />
    </div>
  );
}
