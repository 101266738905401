import { usePostHog } from 'posthog-js/react';
import { ReactElement, useState } from 'react';
import { Image } from 'react-bootstrap';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { Link } from 'src/components/Shared/Link/Link';
import { appConfig } from 'src/config/app';
export type AgeCheckDecoratorProps = {
  children: ReactElement;
};
export const currentAgreementVersion = 'v1.0';
export const agreementStoreKey = 'agreement';
export function AgreementDecorator({ children }: AgeCheckDecoratorProps) {
  const [agreementSigned, setAgreementSigned] = useState(() => {
    const storedAgreementVersion = localStorage.getItem(agreementStoreKey);
    return storedAgreementVersion === currentAgreementVersion;
  });
  const posthog = usePostHog();

  const onCheckProve = () => {
    posthog.capture('signed_agreement', {
      agreement_version: currentAgreementVersion,
    });

    localStorage.setItem(agreementStoreKey, currentAgreementVersion);
    setAgreementSigned(true);
  };
  const onCheckReject = () => {
    posthog.capture('rejected_agreement', {
      agreement_version: currentAgreementVersion,
    });

    window.location.href = 'https://google.com';
    // window.history.back();
  };
  const getLoad = () => {
    posthog.capture('page_open', { page_name: '18plus' });
  };
  return !agreementSigned ? (
    <div
      className="mw-main mx-auto h-100  position-relative"
      style={{
        background: ` url("${appConfig.host.CDN}/img/loader/18.png?height=${window.screen.height + 1}")  top center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      {' '}
      <div
        className="position-absolute pe-none w-100 h-100"
        style={{ background: 'rgba(0,0,0,.3)' }}
      ></div>
      <div className="d-flex w-100 h-100 justify-content-center align-items-center position-relative z-1">
        <div className="d-flex flex-column gap-4 align-items-center">
          <div className="text-center">
            <Image
              style={{
                width: 120,
              }}
              onLoad={() => getLoad()}
              src={appConfig.host.CDN + '/img/logo.png?width=140'}
              className="m-auto mb-3"
            />
          </div>
          <div className="text-center horny-fs-max fw-bold">Temptations.ai</div>
          <div>Are you at least 18 years old?</div>
          <div className="d-flex flex-column gap-7 align-items-center">
            <HornyButton
              height={HornyButtonHeight.Tall}
              bg={HornyButtonBG.Red}
              onClick={onCheckProve}
              className="text-center"
            >
              <div className="d-flex gap-4 align-items-center">
                <Icon src={IconSrc.Next} size={IconSize.Big} />
                Yes, I am 18 y.o or older
              </div>
            </HornyButton>
            <div className="w-100 text-center">
              <u className="cursor-pointer" onClick={() => onCheckReject()}>
                No I am younger thant 18 y.o.
              </u>
            </div>
          </div>
          <div style={{ maxWidth: 400 }}>
            By pressing the button, you also agree to the{' '}
            <Link url="https://temptationgames.com/privacy-policy">
              Privacy Policy
            </Link>{' '}
            and <br></br>
            <Link url="https://temptationgames.com/terms-of-service">
              Terms of Service
            </Link>
            . Thanks for reading them carefully.
          </div>
        </div>
      </div>
    </div>
  ) : (
    children
  );
}
