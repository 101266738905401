import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { focusEditableElement } from 'src/shared/utils'; //formatText,
import { generatePlaceholderBlocks } from '../ConfigureStory';

export type CreateStoryProps = {
  title: string;
  onSelect: (value: string) => any;
  description: string;
  disabled: boolean;
  generateStory: () => Promise<string>;
  fetchedStory?: string;
};

export function CreateStory({
  title,
  onSelect,
  description,
  disabled,
  generateStory,
  fetchedStory,
}: CreateStoryProps) {
  const { t } = useTranslation();
  const [isManual, setIsManual] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [generatedStory, setGeneratedStory] = useState<string>(
    fetchedStory || ''
  );
  const [manualStory, setManualStory] = useState<string>(
    fetchedStory || generatedStory || ''
  );

  const [isFetching, setIsFetching] = useState(false);
  const handleInputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isManual) {
      setIsEditing(false);
    }
    if (isManual) {
      setTimeout(() => {
        focusEditableElement(handleInputRef);
        if (manualStory) {
          (handleInputRef.current as unknown as HTMLDivElement).textContent =
            manualStory;
        }
      }, 100);
    }
  }, [isManual]);

  const regenerateStory = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    generateStory().then((response) => {
      setGeneratedStory(response);
      if (!manualStory) {
        setManualStory(response);
      }
      setIsFetching(false);
    });
  };

  const [randomBlocks] = useState(() => generatePlaceholderBlocks(1400));
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => focusEditableElement(handleInputRef), 100);
    }
  }, [isEditing]);

  useEffect(() => {
    onSelect(isManual ? manualStory : generatedStory);
  }, [generatedStory, manualStory, isManual, onSelect]);
  return (
    <div
      className={`d-flex flex-column gap-3 position-relative ${disabled ? 'opacity-50' : ''}`}
    >
      {disabled && (
        <div
          className="position-absolute w-100 h-100 top-0"
          style={{ left: 0, zIndex: 5 }}
        ></div>
      )}
      <div className="d-flex gap-2 justify-content-between">
        <div className="fs-5 fw-bold">
          {title} {Number(!!generatedStory || !!manualStory)}/1
        </div>
        <Form>
          <Form.Check
            type="switch"
            id="custom-switch"
            className="horny-toggle"
            label={t('Manual')}
            // className={isFetching ? 'pe-none opacity-75' : ''}
            onChange={(e) => setIsManual(e.target.checked)}
          />
        </Form>
      </div>
      <div className="opacity-75 lh-sm text-start">{description}</div>
      <div className="text-center">
        <div
          className="position-relative rounded-3 overflow-hidden p-5  horny-bg_grey_dark m-auto"
          style={{ width: 310, minHeight: 228 }}
        >
          {!isManual &&
            !generatedStory && [
              <div
                className="position-absolute w-100 h-100 top-0 z-1 text-start p-5 horny-bg_grey_dark"
                style={{ left: 0 }}
              >
                {randomBlocks}
              </div>,
              <div
                className="position-absolute  w-100 h-100 top-0 z-2 d-flex align-items-center justify-content-center "
                style={{ left: 0 }}
              >
                <div className="position-relative w-100 d-flex justify-content-center">
                  {disabled && (
                    <div
                      className="position-absolute m-auto w-100 text-center"
                      style={{ zIndex: 6, opacity: 1, top: -40 }}
                    >
                      {t('Please complete the “Place” step first')}
                    </div>
                  )}
                  <HornyButton
                    height={HornyButtonHeight.Medium}
                    onClick={() => regenerateStory()}
                    bg={HornyButtonBG.Orange}
                    {...(isFetching ? { disabled: true } : {})}
                  >
                    <div>
                      <Icon
                        src={isFetching ? IconSrc.Reload : IconSrc.Magic}
                        size={IconSize.Small}
                        className={`${isFetching ? 'horny-spin' : ''}`}
                      />
                    </div>
                    <div>
                      {`${isFetching ? t('Generating story') : t('Generate story')} ${isFetching ? '...' : ''}`}
                    </div>
                    <div></div>
                  </HornyButton>
                </div>
              </div>,
            ]}
          {!isManual && generatedStory && (
            <div
              className="position-relative w-100 h-100 top-0 z-1 text-start p-5 horny-bg_grey_dark"
              style={{ left: 0 }}
            >
              <span>{generatedStory}</span>
            </div>
          )}
          {isManual && (
            <div
              className="position-relative w-100 h-100 top-0 z-1 text-start p-5 horny-bg_grey_dark"
              style={{ left: 0, whiteSpace: 'pre-line' }}
              contentEditable={isEditing ? 'true' : false}
              ref={handleInputRef}
              onInput={() => {
                if (handleInputRef.current) {
                  /*
                      handleInputRef.current.textContent can be a string or null. 
                      We ensure that, in case it's null, an empty string will be used.
                  */
                  setManualStory(handleInputRef.current.textContent || '');
                }
                // setManualStory((e.target as HTMLDivElement).innerText)
              }}
              defaultValue={manualStory}
              content={manualStory}
            >
              {/* {manualStory} */}
              {/* {generatedStory} */}
            </div>
            // <InputGroup className="horny-control  rounded-2 overflow-hidden position-relative z-0 ">
            //   <Form.Control
            //     onChange={(e) => setManualStory(e.target.value)}
            //     as="textarea"
            //     rows={13}
            //     placeholder=""
            //     defaultValue={manualStory}
            //     aria-describedby="basic-addon1"
            //     className=" horny-bg_grey_dark  horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange"
            //   />
            // </InputGroup>
          )}
        </div>
      </div>
      {!isManual ? (
        generatedStory ? (
          <div
            className="  m-auto pb-4 d-flex justify-content-center"
            style={{ bottom: 0 }}
          >
            <HornyButton
              height={HornyButtonHeight.Medium}
              onClick={() => regenerateStory()}
              bg={HornyButtonBG.Orange}
              shadow="horny-shadow-red"
              {...(isFetching ? { disabled: true } : {})}
            >
              <div>
                <Icon
                  src={isFetching ? IconSrc.Reload : IconSrc.Magic}
                  size={IconSize.Small}
                  className={`${isFetching ? 'horny-spin' : ''}`}
                />
              </div>
              <div>
                {`${isFetching ? t('Regenerating story') : t('Regenerate story')}`}
                {`${isFetching ? '...' : ''}`}
              </div>
              <div></div>
            </HornyButton>
          </div>
        ) : null
      ) : (
        <div
          className="  m-auto pb-4 d-flex justify-content-center"
          style={{ bottom: 0 }}
        >
          <HornyButton
            height={HornyButtonHeight.Medium}
            shadow="horny-shadow-red"
            onClick={() => {
              if (isEditing) {
                onSelect(manualStory);
                setIsEditing(false);
              } else {
                setIsEditing(true);
              }
            }}
            bg={HornyButtonBG.Orange}
          >
            <div>
              <Icon
                src={isEditing ? IconSrc.check : IconSrc.Edit}
                size={IconSize.Small}
              />
            </div>
            <div>{isEditing ? t('Save') : t('Edit')}</div>
            <div></div>
          </HornyButton>
        </div>
      )}
    </div>
  );
}
