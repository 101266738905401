import { faPlus, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { cn } from 'src/shared/utils';

type CustomPromptElementProps = {
  title: string;
  onClick: () => any;
  type: 'selected' | 'unselected';
};

export default function CustomPromptSelectedElement({
  title,
  onClick,
  type,
}: CustomPromptElementProps) {
  const { t } = useTranslation();
  return (
    <motion.div
      className={cn(
        'tw-font-bold tw-text-sm tw-h-fit tw-flex tw-flex-row tw-gap-1 tw-items-center tw-cursor-pointer',
        type === 'selected' &&
          'tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-rounded-full tw-px-3 tw-py-1  tw-shadow-sm',
        type === 'unselected' && 'tw-underline tw-underline-offset-2'
      )}
      onClick={onClick}
      whileTap={{ scale: 0.9 }}
    >
      {type === 'unselected' && <FontAwesomeIcon icon={faPlus} />}
      <div>{t(title)}</div>
      {type === 'selected' && <FontAwesomeIcon icon={faXmark} />}
    </motion.div>
  );
}
