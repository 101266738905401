// import { AddBlock } from 'src/components/Shared/AddBlock/AddBlock';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, IconSrc, IconSize } from 'src/components/Shared/Icon/Icon';
import { Link } from 'src/components/Shared/Link/Link';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { splitTextLines } from 'src/shared/utils';

export enum NotificationType {
  Info = 'info',
  Error = 'error',
}

export type NotificationProps = {
  type: NotificationType;
  message: string | ReactElement;
  title?: string | ReactElement;
  // onShow: () => any;
  timestamp: number;
};

export type NotificationsProps = {
  notifications: NotificationProps[];
};

export function Notification({
  type,
  timestamp,
  message,
  title,
}: NotificationProps) {
  const { t } = useTranslation();
  const { removeNotification } = useNotificationsContext();
  const titles = {
    [NotificationType.Error]: t('Error'),
    [NotificationType.Info]: t('Info'),
  };

  return (
    <div
      className="horny-bg_grey horny-shadow-gray rounded-4 p-6 text-white m-auto d-flex flex-column gap-2 justify-content-between horny-bg_grey_opac"
      style={{ width: 400 }}
    >
      <div className="fs-3 fw-bold d-flex justify-content-between">
        {title ? title : titles[type]}
        <Icon
          src={IconSrc.exit}
          size={IconSize.Small}
          className="cursor-pointer"
          onClick={() => removeNotification(timestamp)}
        />
      </div>
      <div>
        {' '}
        {typeof message === 'string' ? splitTextLines(message) : message}
      </div>

      <div className="text-center mt-3">
        <Link url="https://temptationgames.com/#block-ad19c8cf0b23468ab41bb66f7d6af393">
          {t('Contact support')}
        </Link>
      </div>
    </div>
  );
}

export function Notifications({ ns }: { ns: NotificationProps[] }) {
  const { removeNotification } = useNotificationsContext();

  // ns = [...ns, {
  //   type: NotificationType.Error,
  //   message: 'Unknown error occured',
  //   timestamp: Date.now()
  // }];

  if (!ns.length) {
    return null;
  }
  return (
    <div
      className="position-fixed  mw-main gap-1  z-50 w-100 text-center"
      style={{
        top: '25%',
        // left: '50%',
        // overflow: 'overlay',
        // maxWidth: '75%',
        // maxHeight: '50%',
      }}
    >
      {ns.map((n) => (
        <Notification {...n} />
      ))}
    </div>
    // {ns.length > 2 && (
    //   <div
    //     className="position-fixed text-center text-white m-auto pb-1 z-30"
    //     style={{ bottom: 0 }}
    //     onClick={() => {
    //       ns.forEach(({ timestamp }) => {
    //         removeNotification(timestamp);
    //       });
    //     }}
    //   >
    //     <u>Hide all</u>
    //   </div>
    // )}
  );
}
