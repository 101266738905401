import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import {
  ShowSubscriptionsReason,
  Subscriptions,
} from 'src/components/Subscription/Subscriptions';
import { useModalContext } from 'src/context/Modal.Context';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';

export function CommunityMediaUnavailable() {
  const { t } = useTranslation();
  const { addModal } = useModalContext();
  return (
    <motion.div
      className="h-100 w-100 d-flex align-items-center justify-content-center"
      animate="in"
      initial="initial"
      // exit="out"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
    >
      <div className="d-flex flex-column align-items-center justofy-content-center gap-4">
        <div className="fs-2 fw-bold w-75">{t('Premium required')}</div>
        <div className="w-75">
          {t(
            'Subscribe to Premium to join the Temptations.AI community and see shared media!'
          )}
        </div>
        <div>
          <HornyButton
            height={HornyButtonHeight.Tall}
            onClick={() =>
              addModal({
                children: (
                  <Subscriptions
                    showReason={ShowSubscriptionsReason.CommunityMedia}
                  />
                ),
              })
            }
          >
            <div>{t('Subscribe to Premium')}</div>
          </HornyButton>
        </div>
      </div>
    </motion.div>
  );
}
