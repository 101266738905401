import { faCamera, faQuestion } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { MessageTypeEnum } from 'src/@types/common';
import { useModalContext } from 'src/context/Modal.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { ImageGenerationModel } from 'src/services/API/CharacterAPI';
import { MediaType } from 'src/services/API/MediaAPI';
// import './index.css';
import { faBolt } from '@fortawesome/pro-solid-svg-icons';
import { NewPromptPicker } from 'src/components/CharacterGeneration/ConfigureAppearance/NewPromptPicker';
import 'src/components/Shared/tw-base.css';
import { cn } from 'src/shared/utils';
import { handleRequestPhotoProps } from '../../Chat';

export type UnlockMediaProps = {
  mediaType: MessageTypeEnum;
  BotId: string;
  IsBotAuthor: boolean;
  requestRandomMedia: (data: handleRequestPhotoProps) => any;
  model: ImageGenerationModel;
  mediaChoiseDisabled: boolean;
};

export function UnlockMedia({
  mediaType,
  requestRandomMedia,
  model,
  mediaChoiseDisabled,
}: UnlockMediaProps) {
  const { t } = useTranslation();

  const text = {
    [MessageTypeEnum.RandomImage]: 'photo',
    [MessageTypeEnum.RandomVideo]: 'video',
    [MessageTypeEnum.RandomAudio]: 'audio',
    [MessageTypeEnum.Normal]: '',
    [MessageTypeEnum.Error]: 'error',
  }[mediaType];
  const { prices } = useResourcesContext();
  const mediaMatch = {
    [MessageTypeEnum.RandomImage]: MediaType.Photo,
    [MessageTypeEnum.RandomVideo]: MediaType.Video,
    [MessageTypeEnum.RandomAudio]: MediaType.Audio,
    [MessageTypeEnum.Normal]: MediaType.Audio,
    [MessageTypeEnum.Error]: MediaType.Audio,
  }[mediaType];
  const priceMatch = {
    [MessageTypeEnum.RandomImage]: prices!.Media['Photo'],
    [MessageTypeEnum.RandomVideo]: prices!.Media['Video'],
    [MessageTypeEnum.RandomAudio]: prices!.Media['Audio'],
    [MessageTypeEnum.Normal]: 0,
    [MessageTypeEnum.Error]: 0,
  }[mediaType];

  const { addModal, removeModal } = useModalContext();

  const showModal = () => {
    const modal = addModal({
      children: (
        <NewPromptPicker
          description={t('Select a propmpt for your media!')}
          charGen={false}
          buttonText={`${t('Request')} ${t(mediaMatch as any)}`}
          multiplier={1}
          isGenerated={true}
          onPick={(params) => {
            requestRandomMedia(params);

            removeModal(modal);
          }}
          model={model}
        />
      ),
    });
  };

  return (
    <motion.div
      className={cn(
        'tw-w-fit tw-px-3 tw-py-8 tw-bg-gradient-to-b tw-from-horny-red-500 tw-to-horny-red-600 tw-rounded-md tw-flex tw-content-center tw-items-center tw-cursor-pointer tw-shadow-bezel tw-mt-2',
        mediaChoiseDisabled && 'tw-opacity-50 tw-pointer-events-none'
      )}
      whileTap={{ scale: 0.8 }}
      onClick={() => !mediaChoiseDisabled && showModal()}
    >
      <div className="tw-flex tw-flex-col tw-gap-2 tw-justify-center tw-items-center">
        <FontAwesomeIcon
          icon={
            mediaType === MessageTypeEnum.RandomImage ? faCamera : faQuestion
          }
          size="xl"
        />
        <div className="tw-text-center tw-w-full tw-text-base tw-leading-tight tw-font-bold">
          {t('Unlock')} {t(text as any)}
        </div>
        <div className="tw-flex tw-items-center tw-gap-2 tw-font-bold">
          <FontAwesomeIcon icon={faBolt} />
          <div>{priceMatch}</div>
        </div>
      </div>
    </motion.div>
  );
}
