import { useState, useRef } from 'react';
import { NotificationType } from 'src/components/Layout/Notification/Notification';
import { useNotificationsContext } from 'src/context/Notifications.Context';
import { getSupabaseClient } from 'src/services/supabase/supabase.client';
import {
  AuthErrorBlock,
  AuthorizationError,
  AuthorizationInput,
  AuthorizationStep,
  HornyAuthorizationProps,
  getAuthorizationInputError,
} from './HornyAuthorization';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { emailRegex } from 'src/config/utils';
import { FullSizeLoader } from 'src/components/Layout/FullSizeLoader/FullSizeLoader';
import { getIconUrl, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useTranslation } from 'react-i18next';

const supaBaseClient = getSupabaseClient();

export function ForgotPassword(props: HornyAuthorizationProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const emailInput = useRef<HTMLInputElement | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const { addNotification } = useNotificationsContext();
  const [notification, setNotification] = useState('');
  const [inputError, setInputError] = useState<AuthorizationError>();

  const checkInputs = () => {
    const emailValue = emailInput.current?.value;

    if (!emailRegex.test(emailValue!)) {
      setInputError({
        input: AuthorizationInput.Email,
        error: getAuthorizationInputError(t('Please provide a valid email')),
      });
      return false;
    }

    return true;
  };
  const requestPasswordRestoration = () => {
    if (isFetching || !checkInputs()) {
      return;
    } else {
      setIsFetching(true);
    }
    supaBaseClient.auth
      .resetPasswordForEmail(email, {
        redirectTo: window.location.origin + '/reset-password',
      })
      .then((res) => {
        if (res.error) {
          setNotification(res.error.message);
        } else {
          addNotification({
            type: NotificationType.Info,
            title: (
              <div className="d-flex gap-3 align-items-center">
                <img src={getIconUrl(IconSrc.Email)} style={{ width: 30 }} />
                {t('Reset your password')}
              </div>
            ),
            message: (
              <span>
                {t(
                  'Your password is ready to be reset! The link will be sent to your email'
                )}{' '}
                <a
                  className="text-white text-underline fw-bold"
                  href={'mailto:' + email}
                >
                  <u>{email}</u>
                </a>{' '}
                if it exists. Just click this link when you receive it and
                return to your account.
              </span>
            ),
            timestamp: Date.now(),
          });
        }
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <div className="w-100 d-flex flex-column gap-6 align-items-center justify-content-start my-20 mx-auto">
      {isFetching && <FullSizeLoader />}
      <div>
        <div className="fs-2 fw-bold">{t('Forgot your password?')}</div>
        {notification && <AuthErrorBlock errorMessage={notification} />}

        <div style={{ maxWidth: 400 }} className="no-underline-on-hover">
          {t(
            'Just type your email in the for below, click the button and we will send you the link to return to your account'
          )}
        </div>
      </div>
      <div className="w-100">
        <input
          ref={emailInput}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder={t('Enter your email')}
          autoComplete="email"
          className="w-100 horny-input horny-input_authorization  horny-bg-gradient_orange_light rounded-5 horny-shadow border-0 horny-text_medium text-left ps-4 horny-text_orange form-control"
        />
        <div className="text-center">
          {inputError &&
            inputError.input === AuthorizationInput.Email &&
            inputError.error}
        </div>
      </div>
      <HornyButton
        height={HornyButtonHeight.Tall}
        bg={HornyButtonBG.Orange}
        onClick={() => requestPasswordRestoration()}
        className="w-100 mw-100"
      >
        <div className="mx-auto">{t('Send reset password instructions')}</div>
      </HornyButton>

      {/* <button onClick={() => requestPasswordRestoration()}>restore</button> */}
      <div>
        <u
          className="cursor-pointer fw-bold"
          onClick={() => props.goTo(AuthorizationStep.SignInUp)}
        >
          {t('Sign in or create account')}
        </u>
      </div>
    </div>
  );
}
