export type ReportProps = {
  Id: string;
  onReport: () => any;
};

export const processReasons = (
  reasons: string[],
  reason: string,
  value: boolean
) => {
  if (value) {
    return [...reasons.filter((r) => r !== reason), reason];
  } else {
    return [...reasons.filter((r) => r !== reason)];
  }
};
