import { NudityLevel } from './MediaPrompts';

export enum CharacterTextParamsCategory {
    Occupation = 'occupation',
    Pesonality = 'personality',
    Kink = 'kink',
  }
  
  // export enum ImageGenerationModelType {
  //   Anime = 'Anime',
  //   Realistic = 'Realistic',
  //   Furry = 'Furry',
  //   Cartoon = 'Cartoon',
  //   AnimeXL = 'Anime XL'
  // }

  export type ImageGenerationModelType = string;
  
  
  export enum CharacterTextParamsSubtitle {
    Submissive = 'Submissive',
    Dominant = 'Dominant',
    Neutral = 'Neutral',
  }

 
  
  export type CharacterTextParamsBase = {
    ParamId: string;
  };
  
  export type CharacterTextParamCommon = CharacterTextParamsBase & {
    Title: string;
    Icon?: string;
  };
  
  export type CharacterPersonalityParams = CharacterTextParamCommon & {
    Category: CharacterTextParamsCategory.Pesonality;
    Subtitle: CharacterTextParamsSubtitle;
    Text: string;
  };
  
  export type CharacterNonPersonalityParams = CharacterTextParamCommon & {
    Category:
      | CharacterTextParamsCategory.Occupation
      | CharacterTextParamsCategory.Kink;
    Subtitle: null;
    Text: null;
  };
  
  export type CharacterTextParamsAPIModel =
    | CharacterPersonalityParams
    | CharacterNonPersonalityParams;
  
  export enum CharacterAppearanceControl {
    Slider = 'slider',
    Range = 'range',
  }
  export type CharacterAppearancePromptImage = Record<
    ImageGenerationModelType,
    string
  >;
  
  export type CharacterAppearancePrompt = {
    PromptValue: string;
    Title: string;
    Image: CharacterAppearancePromptImage;
  };
  
  export type CharacterAppearanceCategoryAPIModel = {
    CategoryValue: string;
    Control: CharacterAppearanceControl;
    Title: string;
    Prompts: CharacterAppearancePrompt[];
  };
  
  export enum CharacterGenerationStage {
    Personality = 'Personality',
    Story = 'Story',
    ImageGenerationModel = 'ImageGenerationModel',
    Appearance = 'Appearance',
    PhotoChoice = 'PhotoChoice',
  }
  
  export type CharacterGenerationPersonality = {
    Kinks: string[];
    Occupation: string;
    Name: string;
    Personality: string;
    Type: CharacterTextParamsSubtitle;
  };
  
  export type CharacterGenerationPlace = {
    Title: string;
    Prompt: string | null;
  };
  
  export type CharacterGenerationStory = {
    Place: CharacterGenerationPlace;
    Story: string;
    Messages: ExampleMessage[];
    UserRole: string;
  };
  
  export type ImageGenerationModel = ImageGenerationModelType;
  
  export type CharacterGenerationAppearance = Record<string, string>;
  
  export type CharaterGenerationModelAPIModel = {
    Title: ImageGenerationModelType;
    Available: boolean;
    Image: string;
  };
  
  export type PhotoToChoose = {
    Photo: string;
    Avatar: string;
  };
  
  export type CharacterGenerationPhotoChoice = {
    Photos: PhotoToChoose[];
    SelectedPhoto: string | null;
  };
  
  export type CharacterGenerationDraftAPIModel = {
    [CharacterGenerationStage.Personality]: CharacterGenerationPersonality | null;
    [CharacterGenerationStage.Story]: CharacterGenerationStory | null;
    [CharacterGenerationStage.ImageGenerationModel]: ImageGenerationModel | null;
    [CharacterGenerationStage.Appearance]: CharacterGenerationAppearance | null;
    [CharacterGenerationStage.PhotoChoice]: CharacterGenerationPhotoChoice | null;
  };
  
  export type GenerateRolesQueryParams = {
    Occupation: string;
    RolesCount: number;
    Gender: string;
    Personality: string;
  };
  
  export type GeneratePlacesQueryParams = {
    Occupation: string;
    PlacesCount: number;
    UserRole: string;
  };
  
  export type GenerateStoryQueryParams = {
    BotOccupation: string;
    UserRole: string;
    BotPresonality: string;
    Place: string;
    BotName: string;
    BotType: string;
  };
  
  export type GenerateMessagesQueryParams = {
    Story: string;
    BotType: CharacterTextParamsSubtitle;
    BotPresonality: string;
    Place: string;
    BotName: string;
    BotKinks: string;
    BotOccupation: string;
  };
  
  export type GenerateRolesResponse = {
    Roles: string[];
  };
  
  export type GeneratePlacesResponse = {
    Places: string[];
  };
  
  export type GenerateStoryResponse = {
    Story: string;
  };
  
  export enum ExampleMessageRole {
    User = 'user',
    Assistant = 'assistant',
  }
  
  export interface ExampleMessage {
    Role: ExampleMessageRole;
    Content: string;
    Name: string;
  }
  
  export type GenerateMessagesResponse = {
    ExampleMessages: ExampleMessage[][];
  };


  export type GenerateCharacterPhotosAPIModel = {
    PromptId: string;
    NudityLevel: NudityLevel;
  }