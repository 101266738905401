import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { MediaAPI, MediaType } from 'src/services/API/MediaAPI';
import { getMediaDisplay } from 'src/shared/utils';
import './index.css';
import { motion } from 'framer-motion';

export type ChatMediaPreviewProps = {
  PreviewUrl: string;
  MediaId: string;
  MediaType: MediaType;
};
export function ChatMediaPreview({
  PreviewUrl,
  MediaId,
  MediaType: type,
}: ChatMediaPreviewProps) {
  const { addModal } = useModalContext();

  const src = PreviewUrl + '?height=512';

  const { capture } = useAnalyticsContext();

  return (
    <motion.div
      whileTap={{ scale: 0.85 }}
      className="tw-relative tw-cursor-pointer"
      onClick={() =>
        MediaAPI.getSingleMedia({ MediaId }).then(({ data }) => {
          capture({
            event: 'page_open',
            data: {
              page_name: 'Media_fullscreen',
              source: 'media',
              bot_id: data.BotId,
            },
          });
          addModal({
            children: (
              <div className="tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center">
                {getMediaDisplay(data, type)(data)}
              </div>
            ),
            className: 'tw-bg-black tw-bg-opacity-75',
            bg: '',
          });
        })
      }
    >
      <img
        src={src}
        className="tw-rounded-md tw-w-48"
      />
    </motion.div>
  );
}
