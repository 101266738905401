import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnimParamInOutOpacityYNeg100,
  TransTweenAnticipate03,
} from 'src/shared/animations';
import { getImgUrl } from 'src/shared/helpers';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { TopBotsGlobal } from './Global/TopBotsGlobal';
import { TournamentList } from './Tournaments/TournamentList';

export enum TopTab {
  Global = 'Global',
  Tournaments = 'Tournaments',
}

export type TopProps = {
  active: boolean;
};

export function Top(props: TopProps) {
  const { t } = useTranslation();
  const [tab, setTab] = useState<TopTab>(TopTab.Tournaments);

  const tabComponents = {
    [TopTab.Global]: <TopBotsGlobal />,
    [TopTab.Tournaments]: <TournamentList />,
  };
  return (
    <motion.div
      animate={props.active ? 'in' : 'out'}
      initial="initial"
      variants={AnimParamInOutOpacityYNeg100}
      transition={TransTweenAnticipate03}
      className="horny-bg-gradient-gray-tab d-flex flex-column gap-3 h-100 position-relative" //horny-bg-gradient_grey
      onClick={() => {}}
    >
      <div className="d-flex gap-4 mt-20 justify-content-center fw-bold position-absolute w-100 z-50">
        <div
          onClick={() => setTab(TopTab.Tournaments)}
          style={{
            outline:
              tab === TopTab.Tournaments
                ? '1px solid rgba(255, 255, 255, 0.5)'
                : 'none',
          }}
          className={`d-flex gap-1  horny-text_very_tiny  cursor-pointer align-items-center py-2 px-3 ${tab === TopTab.Tournaments ? ' horny-border_100 max-w-max horny-bg-dark_half  d-flex align-items-center ' : ''}`}
        >
          <Icon src={IconSrc.Target} size={IconSize.XSmall} /> {t('Challenges')}
        </div>
        <div
          style={{
            outline:
              tab === TopTab.Global
                ? '1px solid rgba(255, 255, 255, 0.5)'
                : 'none',
          }}
          onClick={() => setTab(TopTab.Global)}
          className={`d-flex gap-1 horny-text_very_tiny cursor-pointer align-items-center py-2 px-3 ${tab === TopTab.Global ? ' horny-border_100 max-w-max horny-bg-dark_half  d-flex align-items-center ' : ''}`}
        >
          <Icon src={IconSrc.Top} size={IconSize.XSmall} />
          {t('Top characters')}
        </div>
      </div>
      <img
        className="horny-tab-background-image"
        src={getImgUrl('tabsbg/TopBgNew.png')}
        alt="background"
      />
      <div className="px-6 h-100 overflow-y-auto pt-10 horny-disable-scrollbar">
        <div className="pt-20"></div>
        {tabComponents[tab]}
      </div>
    </motion.div>
  );
}
