import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { TagSelector } from '../TagSelector/TagSelector';
import { CharacterTextParamCommon } from 'src/services/API/CharacterAPI';
import { useTranslation } from 'react-i18next';
//import { bo } from '@vidstack/react/dist/types/vidstack';
// import { HornyButton, HornyButtonBG, HornyButtonHeight } from 'src/components/Shared/HornyButton/HornyButton';

export type ValueSwitcherProps = {
  title: string;
  onSelectValue: (values: string[]) => any;
  tags: CharacterTextParamCommon[];
  description: string;
  onRegenerate?: () => any;
  onRenerateTitle?: string;
  preSelected?: string[];
  onManualChange?: (value: boolean) => void;
  selectText?: string;
  isShowManual?: boolean;
} & Partial<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

export function ValueSwitcher({
  title,
  onSelectValue: onSelect,
  tags,
  description,
  onRegenerate,
  onRenerateTitle,
  preSelected,
  className = '',
  onManualChange,
  selectText = '',
  isShowManual = true,
}: ValueSwitcherProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>(preSelected || []);
  const [isManual, setIsManual] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const switcherRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!preSelected) {
      setSelected([]);
    }
  }, [tags]);
  useEffect(() => {
    onSelect(selected);
  }, [selected, onSelect]);

  useEffect(() => {
    if (typeof onManualChange === 'function') {
      onManualChange(isManual);
    }
  }, [isManual]);

  return (
    <div className={`d-flex mb-2 flex-column gap-3 ${className}`}>
      <div className="d-flex gap-2 justify-content-between">
        <div className="fs-5 fw-bold">
          {title} {selected.length && selected[0] && selected.length}/1
        </div>
        {isShowManual && (
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              className="horny-toggle"
              label={t('Manual')}
              ref={switcherRef}
              onChange={(e) => {
                setIsManual(e.target.checked);
                setSelected([]);
              }}
            />
          </Form>
        )}
      </div>
      <div className="opacity-75 lh-sm text-start">{description}</div>
      <div>
        {isManual ? (
          <>
            <span className="opacity-75 lh-sm text-start">
              {t('Manual input')}:
            </span>
            <InputGroup className="horny-control horny-height_medium rounded-2 overflow-hidden  horny-shadow-just-shadow-red">
              <Form.Control
                ref={inputRef}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelected(value ? [value] : []);
                }}
                aria-describedby="basic-addon1"
                className="horny-bg-gradient_orange_light border-0 horny-text_medium text-left ps-4 horny-text_orange"
              />
            </InputGroup>
          </>
        ) : (
          !!tags.length && (
            <TagSelector
              tags={tags}
              tagType=""
              showFilter={false}
              limit={1}
              onSelect={(selectedTags) => setSelected(selectedTags)}
              selectText={selectText}
              selectedTags={selected}
              {...(onRegenerate
                ? {
                    onRegenerate: () => {
                      onRegenerate();
                      setSelected([]);
                    },
                  }
                : {})}
              //height={40}
            />
          )
        )}
      </div>
    </div>
  );
}
