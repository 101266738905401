import { HTMLAttributes } from 'react';
import {
  HornyButton,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
import { IconSrc } from 'src/components/Shared/Icon/Icon';
 
export type IntroBlockProps = {
  title: string;
  description: string;
  onButtonClick: () => any;
  buttonText?: string;
  note?: React.ReactNode;
  shadow?: string;
} &  Partial<
React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>;

export function IntroBlock({
  title,
  description,
  note,
  buttonText,
  onButtonClick,
  shadow = 'horny-shadow',
  children
}: IntroBlockProps) {
  return (
    <div className="d-flex flex-column gap-4 text-start">
      <div className="d-flex justify-content-between align-items-center">
        <span className="fs-1 fw-bold ">{title}</span>
        {buttonText && (
          <HornyButton
            className=""
            height={HornyButtonHeight.Short}
            onClick={onButtonClick}
            icon={IconSrc.Magic}
            text={buttonText}
            shadow={shadow}
          ></HornyButton>
        )}
      </div>
      {children}
      <div className="opacity-75 lh-sm">{description}</div>
      {note}
    </div>
  );
}
