import { BotTournament, TopBot } from 'src/services/API/BotAPI';
import { TopBotList } from '../Shared/TopBotList';
import { TournamentInfo, TournamentInfoLocation } from './TournamentInfo';
import { NoBots } from '../Global/NoBots';
export type TournamentProps = {
  topBots: TopBot[];
  tournament: BotTournament;
};
export function Tournament({ tournament, topBots }: TournamentProps) {
  return (
    <div className="h-100 overflow-y-auto horny-disable-scrollbar">
      <TournamentInfo
        {...tournament}
        location={TournamentInfoLocation.Tournament}
      />
      <div className="px-6">
        {topBots.length === 0 ? <NoBots /> : <TopBotList topBots={topBots} />}
      </div>
    </div>
  );
}
