import { useTranslation } from 'react-i18next';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from 'src/components/Shared/HornyButton/HornyButton';
// import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useResourcesContext } from 'src/context/ResourcesContext';
// import { SubscriptionPricesConfig } from 'src/services/API/ResourcesAPI';
// import { nutakuCreatePaymentOptions } from 'src/services/Nutaku/Nutaku';
// import { useNutakuPayment } from '../NutakuPayment';
// import { NutakuPrice } from '../Subscriptions';
// import { SubscriptionProviderFlowProps } from './SubscriptionProviderFlow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';

export type PriceButtonFlowProps = {
   onClick: () => any;
   isYearly: boolean;
   price: number;
   priceYear: number;
};
export function PriceButtonFlow({
  isYearly,
  price,
  onClick,
  priceYear,
}: PriceButtonFlowProps  ) {
  const { t } = useTranslation();

  const { prices } = useResourcesContext();
  // const [nutakuPrices, setNutakuPrices] = useState<
  //   { price: number; price_year: number } | undefined
  // >(() => undefined);

  // useEffect(() => {
  //   NutakuAPI.getNutakuPrice(subscriptionLevel).then(({ data }) =>
  //     setNutakuPrices(data)
  //   );
  // }, []);

  // const nutakuPaymentOptions: nutakuCreatePaymentOptions = {
  //   itemName: subscriptionLevel,
  //   itemId: isYearly
  //     ? subscriptionData.NutakuItemIdYear!
  //     : subscriptionData.NutakuItemId!,
  //   itemPrice: isYearly
  //     ? nutakuPrices.price_year!.toString()
  //     : nutakuPrices.price!.toString(),
  //   itemImage: subscriptionData.NutakuImageUrl!,
  //   itemDescription: subscriptionData.NutakuItemDescription!,
  // };

  // const { nutakuCreatePayment } = useNutakuPayment({
  //   NutakuPayment: nutakuPaymentOptions,
  //   Type: 'subscription',
  // });

  return (
    <div
      className={`tw-z-20 h-100 d-flex align-items-center justify-content-center flex-column`}
    >
      <HornyButton
        onClick={() => onClick()}
        bg={HornyButtonBG.Red}
        className="w-max-content mw-100"
        height={HornyButtonHeight.Medium}
      >
        {t('Month')}:
        <div></div>
        {/* <FontAwesomeIcon icon={faDollarSign} />          */}

        {/* <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} /> */}
        ${isYearly
          ? Math.floor((priceYear || 0) / 12)
          : price}
        {isYearly  && (
          <div className="position-relative">
            <div className="opacity-75">
              ${price}
            </div>
            <div
              className="bg-white position-absolute"
              style={{
                height: 1,
                left: 0,
                top: 8,
                width: '110%',
                transform: 'rotate(-27deg)',
              }}
            />
          </div>
        )}
      </HornyButton>
      {isYearly && (
        <div className="opacity-75 mt-2">
          {t('You will be charged')}{' '}
          <FontAwesomeIcon icon={faDollarSign} />         
           {/* <Icon src={IconSrc.NutakuCoin} size={IconSize.Small} /> */}
           {' '}
          {priceYear}
        </div>
      )}
    </div>
  );
}
