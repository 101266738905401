import {
  ChangeConnectionStatusRequest,
  ConnectionResponse,
  ConnectionsResponse as ConnectionsResponseNest,
  DeleteConnectionRequest,
  GetConnectionQueryParams,
  GetConnectionsQueryParams,
} from '@temptations-ai/types/modules/Connections';
import {
  MessageSenderEnum,
  MessageStatusEnum,
  MessageTypeEnum,
} from 'src/@types/common';
import { removeBraces } from 'src/shared/utils';
import { APIBase, PaginationCommonParams, PaginationResponse } from './APIBase';
import { BotRequestParams } from './BotAPI';
import { ImageGenerationModelType } from './CharacterAPI';
import { MediaPreviewAPIModel } from './MediaAPI';
import { NudityLevel, PlaceAPIModel } from './ResourcesAPI';
// import { GetConnectionsQueryParams } from '@temptations-ai/types';

export type ConnectionAPIModel = {
  BotId: string;
  Name: string;
  // "MessageCount": 0,
  UpdatedAt: string;
  AvatarUrl: string;
  CoverUrl: string;
  LikesCount: number;
  PhotoCount: number;
  AudioCount: number;
  VideoCount: number;
  Author: string;
  IsBotAuthor: boolean;
  Story: string;
  ImageGenerationModel: ImageGenerationModelType;
  Place: PlaceAPIModel;
};

export type ConnectionsResponse = {
  Connections: ConnectionAPIModel[];
};

export type ChatInfoAPIModel = {
  ChatInfo: {
    Id: string;
    BotId: string;
    MediaInProgress: number;
    UserResponseChoices: string[];
    IsResponsesReady: boolean; // Тут true если UserResponseChoices обновлены, false если еще генерятся
    MessageId: string; // Id сообщения, для которых генерились UserResponseChoices
    UpdatedAt: string; // Последнее обновление чата
  };
};

export enum ChatMessageAuthor {
  User = 'user',
  Bot = 'assistant',
  Server = 'notice',
}

export enum ChatMessageActionType {
  Replay = 'replay',
}

export type ChatMessageAction = {
  type: ChatMessageActionType;
  amount: number;
};

export enum ChatMessageStatus {
  preparing = 'preparing',
  ready = 'ready',
  pendingLink = 'pendingLink',
}

export enum MessageType {
  Normal = 'normal',
  RandomImage = 'randomImage',
  RandomVideo = 'randomVideo',
  RandomAudio = 'randomAudio',
  Error = 'error',
}

export type GetChatResponse = PaginationResponse<ChatMessagesResponse> &
  ChatInfoAPIModel & {
    Chat: ConnectionAPIModel;
  };

export type ChatMessageAPIModel = {
  Id: string;
  Timestamp: Date;
  MessageSender: MessageSenderEnum;
  Text: string | null;
  Media: null | MediaPreviewAPIModel[];
  MessageStatus: MessageStatusEnum;
  Prompt: null;
  LinkedMessageId: string | null;
  MessageType: MessageTypeEnum;
  SkipLLM: boolean;
};

export type RequestMediaParams = BotRequestParams & {
  // Place: string;
  PromptId: string;
  NudityLevel: NudityLevel;
  // Model: ImageGenerationModelType;
};
export type RegenerateMessageParams = BotRequestParams & {
  MessageId: string;
};

export type RequestTextMessageResponse = {
  Messages: ChatMessageAPIModel;
};

export type RequesPhotoResponse = {
  Messages: ChatMessageAPIModel[];
};

export type ChatPendingMessageAPIModel = {
  Message: ChatMessageAPIModel;
  LinkedMessage: ChatMessageAPIModel | null;
};

export type ChatMessagesResponse = {
  Messages: ChatMessageAPIModel[];
};

export type RequestMediaPrompt = {
  [category: number]: {
    [subCategory: number]: any;
  };
};

export type RequestMedia = {};

export type MessagesRequestParams = PaginationCommonParams & BotRequestParams;

export type TextMessageRequestParams = BotRequestParams & {
  Text: string;
};

export type GetPendingMessagesParams = BotRequestParams & {
  MessageId: string[];
};

export type GetPendingMessagesResponse = {
  MessagePairs: ChatPendingMessageAPIModel[];
};

export class ConnectionsAPI extends APIBase {
  static getConnectionsNest(params: GetConnectionsQueryParams) {
    return this.clientV2.get<ConnectionsResponseNest>('v1/connections', {
      params,
    });
  }

  static changeConnectionStatusNest(params: ChangeConnectionStatusRequest) {
    return this.clientV2.post<Boolean>(
      'v1/connections/connection/changestatus',
      params
    );
  }

  static deleteConnection(params: DeleteConnectionRequest) {
    return this.clientV2.delete<Boolean>('v1/connections/connection', {
      params,
    });
  }

  static getConnectionNest(params: GetConnectionQueryParams) {
    return this.clientV2.get<ConnectionResponse>('v1/connections/connection', {
      params,
    });
  }

  static getConnections() {
    return this.client.get<PaginationResponse<ConnectionsResponse>>(
      `getchats`,
      {
        params: {
          StartFrom: 0,
          PageSize: 1000,
        },
      }
    );
  }

  static getMessages(params: MessagesRequestParams) {
    return this.client.get<PaginationResponse<ChatMessagesResponse>>(
      `getmessages`,
      {
        params,
      }
    );
  }

  static getChat(params: MessagesRequestParams & BotRequestParams) {
    return this.client
      .get<GetChatResponse>(`getchat`, {
        params,
      })
      .then((res) => {
        res.data.Chat.ImageGenerationModel =
        res.data.Chat.ImageGenerationModel.replace(
            /[^a-zA-Z0-9 ]/g,
            ''
          ) as ImageGenerationModelType;
        res.data.Chat.Story = removeBraces(res.data.Chat.Story);
        res.data.Chat.Place = {
          Title: removeBraces(res.data.Chat.Place.Title),
          Prompt: removeBraces(res.data.Chat.Place.Prompt),
        };
        return res;
      });
  }

  static getPendingMessages(params: GetPendingMessagesParams) {
    // console.log({ params });
    return this.client.get<GetPendingMessagesResponse>(`refreshmessages`, {
      params: {
        ...params,
        MessageId: JSON.stringify(params.MessageId),
      },
    });
  }

  // static requestMedia(botId: number | string, ids: number[]) {
  //     return this.client.get<ChatMessagesResponse>(`${this.domain}/${botId}/messages/pending`, {
  //         params: {ids}
  //     });
  // }

  static requestText(data: TextMessageRequestParams) {
    return this.client.post<ChatMessagesResponse>(`addtextmessage`, data);
  }
  static regenerateText(data: RegenerateMessageParams) {
    return this.client.post<ChatMessagesResponse>(
      `regeneratetextmessage`,
      data
    );
  }

  static createChat(data: BotRequestParams) {
    return this.client.post<unknown>('createchat', data);
  }

  static requestPhoto(data: RequestMediaParams) {
    return this.client.post<RequesPhotoResponse>('requestimage', data);
  }

  static requestVideo(data: RequestMediaParams) {
    return this.client.post<RequesPhotoResponse>('requestvideo', data);
  }

  static requestAudio(data: RequestMediaParams) {
    return this.client.post<RequesPhotoResponse>('requestaudio', data);
  }

  static regeneratePhoto(data: RegenerateMessageParams) {
    return this.client.post<RequesPhotoResponse>('regenerateimage', data);
  }

  static getChatInfo(params: BotRequestParams) {
    return this.client.get<ChatInfoAPIModel>('getchatinfo', {
      params,
    });
  }

  static generateRandomImage(data: RegenerateMessageParams) {
    return this.client.post('generaterandomimage', data);
  }

  static clearMesages(data: BotRequestParams) {
    return this.client.delete('clearmessages', { data });
  }
}
