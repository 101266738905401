import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon, IconSize, IconSrc } from 'src/components/Shared/Icon/Icon';
import { useAnalyticsContext } from 'src/context/AnalyticsContext/AnalyticsContext';
import { useModalContext } from 'src/context/Modal.Context';
import { MediaAPI, MediaAPIModel } from 'src/services/API/MediaAPI';
import { getMediaDisplay } from 'src/shared/utils';

export function MediaLanding() {
  const [searchParams] = useSearchParams();
  const mediaSearchParam = searchParams.get('MediaId');
  if (!mediaSearchParam) {
    window.location.href = '/';
    return <div></div>;
  }

  const [media, setMedia] = useState<MediaAPIModel | null>(null);
  const { capture } = useAnalyticsContext();
  const { addModal } = useModalContext();

  const loadMedia = () => {
    MediaAPI.getSingleMedia({ MediaId: mediaSearchParam }).then(({ data }) => {
      capture({
        event: 'page_open',
        data: {
          page_name: 'Media_fullscreen',
          source: 'link',
          bot_id: data.BotId,
        },
      });
      const modal = addModal({
        children: getMediaDisplay(data, data.MediaType)(data),
        onClose: () => {
          window.location.href = '/?GirlId=' + data.BotId;
        },
      });
      setMedia(data);
    });
  };

  return (
    <div className="h-100 w-100 d-flex justify-content-center align-items-center">
      {media ? null : (
        <Icon
          src={IconSrc.Loader}
          size={IconSize.Big}
          className={media ? '' : 'horny-spin'}
          onLoad={() => {
            loadMedia();
          }}
        />
      )}
    </div>
  );
}
